/* RESPONSIBLE TEAM: team-frontend-tech */

import Service from '@ember/service';
import { action } from '@ember/object';
import type { SplitInstance } from 'embercom/modifiers/resizable';

export const LOCALSTORAGE_INBOX_PANEL_WIDTHS_KEY = 'inbox.panelWidths';

export default class InboxSectionSizes extends Service {
  private splitInstance?: SplitInstance;

  get resizeStorageKey() {
    return LOCALSTORAGE_INBOX_PANEL_WIDTHS_KEY;
  }

  @action
  loadSplitInstance(splitInstance: SplitInstance) {
    this.splitInstance = splitInstance;
  }

  @action
  clearSplitInstance() {
    this.splitInstance = undefined;
  }

  @action
  expandRHSBtoHalfScreen() {
    // allocate half the screen to child ticket creation view - inbox list - nav bar
    this.splitInstance?.setSizes([50, 50]);
  }

  @action
  revertRHSBToOriginalSize() {
    this.splitInstance?.resetToStoredLayout();
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxSectionSizes: InboxSectionSizes;
    'inbox-section-sizes': InboxSectionSizes;
  }
}
