/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';

export default class BrandService extends Service {
  @service declare appService: $TSFixMe;

  get isPaywallOverrideActive() {
    return this.appService.app.canByPassMultibrandPaywall ? false : undefined;
  }

  get isAppOnRightMultibrandPlan() {
    if (this.appService.app.canUseMultibrandBillingFeature) {
      return true;
    } else if (this.appService.app.canByPassMultibrandPaywall) {
      return true;
    }
    return false;
  }
}

declare module '@ember/service' {
  interface Registry {
    brandService: BrandService;
  }
}
