/* RESPONSIBLE TEAM: team-tickets-1 */
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import Service, { inject as service } from '@ember/service';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type Session from 'embercom/services/session';
import { enqueueTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type InboxApi from 'embercom/services/inbox-api';
import { type TaskInstance } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import { type Descriptor } from 'embercom/objects/inbox/types/descriptor';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { type AttributeSummary } from 'embercom/objects/inbox/types/attribute-summary';
import type { TicketDescriptor } from 'embercom/objects/inbox/ticket';

export default class ConditionalAttributesService extends Service {
  @service declare session: Session;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;

  conversation: ConversationRecord = {} as ConversationRecord;
  attributeValues: Record<string, AttributeSummary> = {};
  attributeDescriptors: Descriptor[] = [];
  listOptions: Record<string, string[]> = {};
  attributeUpdateTasksMap: Map<string, TaskInstance<void>> = new Map();
  unsetAttributeBackend = true;

  beforeAttributeUpdateHook?: (attribute: ConversationAttributeSummary) => void;

  /**
   * Initializes the service with the provided conversation, descriptors, and attributes.
   * When initializing the service for new tickets, the conversation record may not exist yet, so attributes must be explicitly passed.
   * If a conversation record exists, its attributes are used.
   * Optimistic updates are enabled by default but can be disabled in specific cases (e.g., creating a new ticket).
   *
   * @returns {this}
   */
  initialize({
    conversation,
    descriptors,
    attributes,
    unsetAttributeBackend = true,
  }: {
    conversation?: ConversationRecord;
    descriptors: Descriptor[];
    attributes?: TicketAttributeSummary[] | ConversationAttributeSummary[];
    unsetAttributeBackend?: boolean;
  }) {
    if (conversation) {
      // If conversation is provided, we use the attributes from the conversation record
      this.conversation = conversation;
      attributes = conversation.attributes;
    }

    this.attributeValues = this.attributesById(attributes);
    this.attributeDescriptors = descriptors || [];

    descriptors?.forEach((attribute) => this.updateConditionalOptions(attribute, false));

    this.unsetAttributeBackend = unsetAttributeBackend;

    return this;
  }

  isConditionalAttribute(attribute: AttributeSummary): boolean {
    let hasConversationAttrsFF = this.session.workspace.canUseConversationConditionalAttributesBeta;
    let hasTicketAttrsFF = this.session.workspace.canUseTicketsConditionalAttributes;

    let isTicketAttribute = !!(attribute.descriptor as TicketDescriptor).ticketTypeId;

    return (
      (hasConversationAttrsFF && !isTicketAttribute) || (hasTicketAttrsFF && isTicketAttribute)
    );
  }

  /**
   * Updates attribute in create / convert ticket forms and handle related changes
   *
   * @returns {void}
   *
   * @description
   * - Limit list options based on conditions
   * - Update visibility of dependent attributes
   */
  onChangeAttribute(attribute: TicketAttributeSummary) {
    this.recalculateDependentAttributesOptions(attribute);
    this.updateVisibility(attribute);
  }

  /**
   * Updates attribute and handles related changes
   *
   * @param {ConversationAttributeSummary} attribute
   * @returns {Promise<void>}
   *
   * @description
   * - Optimistically updates the attribute value
   * - Limit list options based on conditions
   * - Update attribute on the backend
   * - Rollback if the update fails
   * - Update visibility of dependent attributes
   */
  async updateAttribute(attribute: ConversationAttributeSummary) {
    let update = this.conversationUpdates.addUpdate(
      this.conversation.id,
      'conversation-attribute-update',
      { attribute, displayValue: this.getDisplayValue(attribute) },
    );

    if (this.isConditionalAttribute(attribute)) {
      this.beforeAttributeUpdateHook?.(attribute);
      this.recalculateDependentAttributesOptions(attribute);
    }

    taskFor(this.pushLatestUpdateToAttributeValue)
      .perform(this.conversation.id, attribute, update.part.clientAssignedUuid)
      .catch((error) => {
        if (error?.name === 'TaskCancelation') {
          // We're on a slow network and the previous update was replaced with another one
          // we can move on - it's not an error, but an expected behavior
          return;
        }
        update.rollback(this.conversation);
        let updates = this.conversationUpdates.updatesFor(this.conversation.id);
        this.conversationUpdates.rollbackUpdates(this.conversation.id, updates);

        this.snackbar.notifyError(
          this.intl.t(
            'inbox.conversation-details-sidebar.attributes.errors.updating-user-attribute',
            { attributeName: attribute.descriptor.name },
          ),
        );
      })
      .finally(() => {
        if (this.isConditionalAttribute(attribute)) {
          this.updateVisibility(attribute);
        }
      });
  }

  recalculateDependentAttributesOptions(attribute: AttributeSummary) {
    let dependentAttributes = this.getDependentAttributes(
      this.attributeDescriptorsMap[attribute.descriptor.id as number],
    );
    dependentAttributes.filter(this.hasListOptions.bind(this)).forEach((dependentAttribute) => {
      this.updateConditionalOptions(dependentAttribute, true);
    });
  }

  updateVisibility(attribute?: AttributeSummary): Descriptor[] {
    if (attribute && this.attributeDescriptorsMap[attribute.descriptor.id as number]) {
      return this.evaluateConditionsOnUpdate(
        this.attributeDescriptorsMap[attribute.descriptor.id as number],
      );
    }

    return this.visibleAttributes();
  }

  visibleAttributes(): Descriptor[] {
    let visibleAttributes: Descriptor[] = [];

    // Add non-dependent attributes and attributes with values
    this.attributeDescriptors
      .filter(
        (descriptor) =>
          !descriptor.isDependentAttribute || this.attributeValues[descriptor.id]?.value,
      )
      .forEach((attribute) => this.addToVisibleAttributes(attribute, visibleAttributes));

    // Add dependent attributes that match their controlling attribute conditions
    this.attributeDescriptors
      .filter((descriptor) => descriptor.isControllingAttribute)
      .forEach((controllingAttribute) => {
        let currentValue = this.attributeValues[controllingAttribute.id]?.value as string;

        controllingAttribute.matchedDependents(currentValue).forEach((condition) => {
          let dependentAttribute = this.attributeDescriptorsMap[condition.descriptorId];
          if (dependentAttribute) {
            this.addToVisibleAttributes(dependentAttribute, visibleAttributes);
          }
        });
      });

    return visibleAttributes;
  }

  private hasListOptions(descriptor: Descriptor): boolean {
    return Boolean(descriptor.listOptions) && descriptor.listOptions!.length > 0;
  }

  private evaluateConditionsOnUpdate(attribute: Descriptor): Descriptor[] {
    let visibleAttributes = this.visibleAttributes();
    this.processControllingAttribute(attribute, visibleAttributes);
    return visibleAttributes;
  }

  private processControllingAttribute(
    controllingAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let dependentAttributes = this.getDependentAttributes(controllingAttribute);

    dependentAttributes.forEach((dependentAttribute) => {
      this.processDependentAttribute(controllingAttribute, dependentAttribute, visibleAttributes);

      if (dependentAttribute.isDependentAttribute) {
        this.processControllingAttribute(dependentAttribute, visibleAttributes);
      }
    });
  }

  private getDependentAttributes(attribute: Descriptor): Descriptor[] {
    return attribute.dependentAttributeIds().map((id) => this.attributeDescriptorsMap[id]);
  }

  private processDependentAttribute(
    controllingAttribute: Descriptor,
    dependentAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let matchesController = controllingAttribute.shouldShowDependent(
      dependentAttribute,
      this.attributeValues[controllingAttribute.id]?.value as string,
    );
    let matchesAnotherController = this.matchesAnotherController(
      dependentAttribute,
      visibleAttributes,
    );

    if (matchesController || matchesAnotherController) {
      this.addToVisibleAttributes(dependentAttribute, visibleAttributes);
    } else {
      this.removeFromVisibleAttributes(dependentAttribute, visibleAttributes);
    }
  }

  private matchesAnotherController(
    dependentAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): boolean {
    let controllingAttributes = this.controllingAttributes(dependentAttribute)?.filter(
      (attribute: Descriptor) => visibleAttributes.some((attr) => attr.id === attribute.id),
    );

    return controllingAttributes.some((controller: Descriptor) => {
      return controller.shouldShowDependent(
        dependentAttribute,
        this.attributeValues[controller.id]?.value as string,
      );
    });
  }

  private addToVisibleAttributes(attribute: Descriptor, visibleAttributes: Descriptor[]): void {
    if (!visibleAttributes.some((attr) => attr.id === attribute.id)) {
      visibleAttributes.push(attribute);
    }
  }

  private removeFromVisibleAttributes(
    attribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let index = visibleAttributes.findIndex((attr) => attr.id === attribute.id);
    if (index !== -1) {
      visibleAttributes.splice(index, 1);
      this.unsetAttribute(attribute);
    }
  }

  private updateConditionalOptions(attribute: Descriptor, canUnsetAttributes = false): void {
    if (!this.hasListOptions(attribute)) {
      return;
    }
    if (!attribute.isDependentAttribute) {
      this.listOptions[attribute.id] = attribute.listOptions!.map((option) => option.id);
      return;
    }

    this.listOptions[attribute.id] = this.conditionalOptionIdsFor(attribute, !canUnsetAttributes);

    // in case attribute depends on multiple controllers, there might be a situation
    // where already selected value is not available anymore in the listOptions
    // in this case we should unset the attribute and let the user pick a new value
    // this should be triggered only by user interaction - hence canUnsetAttributes flag
    if (
      canUnsetAttributes &&
      this.attributeValues[attribute.id] &&
      this.attributeValues[attribute.id].value &&
      !this.listOptions[attribute.id].includes(this.attributeValues[attribute.id].value as string)
    ) {
      this.unsetAttribute(attribute);
    }
  }

  private conditionalOptionIdsFor(descriptor: Descriptor, includeCurrentValue = false): string[] {
    let defaultOptions = descriptor.listOptions!.map((option) => option.id);
    let result: string[] = descriptor.matchedControllersListOptions(this.attributeValues);
    result = result.length > 0 ? result : defaultOptions;

    // attributes set by automations do not respect conditionality and
    // should be included in the list of accessible options if they are set
    // and also if we're rendering the list for the first time we need to include historical value
    // even if it do not match the conditions
    if (
      (result.length === 0 || includeCurrentValue) &&
      this.attributeValues[descriptor.id]?.value &&
      !result.includes(this.attributeValues[descriptor.id]?.value as string)
    ) {
      result.push(this.attributeValues[descriptor.id]?.value as string);
    }

    return result;
  }

  private attributesById(attributes?: AttributeSummary[]): Record<string, AttributeSummary> {
    return (
      attributes?.reduce(
        (byIds, attribute) => {
          byIds[attribute.descriptor.id] = attribute;
          return byIds;
        },
        {} as Record<string, AttributeSummary>,
      ) ?? {}
    );
  }

  private unsetAttribute(attribute: Descriptor): void {
    let attr = this.attributeValues[attribute.id];
    if (attr?.value) {
      attr.value = undefined;
      if (this.unsetAttributeBackend) {
        this.updateAttribute?.(attr as ConversationAttributeSummary);
      }
    }
  }

  private getDisplayValue(attribute: ConversationAttributeSummary): string | undefined {
    let { type, id } = attribute.descriptor;

    if (attribute.value === null || ![DataType.Boolean, DataType.List].includes(type)) {
      return undefined;
    }

    if (attribute.descriptor.type === DataType.Boolean) {
      return this.intl.t(`inbox.conversation-attributes.boolean.${attribute?.value?.toString()}`);
    }

    let descriptor = this.attributeDescriptors.find((x) => x.id === id);
    return (
      descriptor?.listOptions?.find((option) => option.id === attribute.value)?.label || undefined
    );
  }

  @enqueueTask({ maxConcurrency: 10 })
  private *pushLatestUpdateToAttributeValue(
    conversationId: number,
    attribute: ConversationAttributeSummary,
    clientAssignedUuid: string | undefined,
  ) {
    let taskKey = `${conversationId}-${attribute.descriptor.id}`;
    if (this.attributeUpdateTasksMap.has(taskKey)) {
      let previousTask = this.attributeUpdateTasksMap.get(taskKey);
      if (previousTask && !previousTask.isDropped) {
        previousTask.cancel();
      }
    }

    let task = taskFor(this.persistAttribute).perform(
      conversationId,
      attribute,
      clientAssignedUuid,
    );
    this.attributeUpdateTasksMap.set(taskKey, task);

    yield task;

    this.attributeUpdateTasksMap.delete(taskKey);
  }

  @enqueueTask
  private *persistAttribute(
    conversationId: number,
    attribute: ConversationAttributeSummary,
    clientAssignedUuid: string | undefined,
  ) {
    let controller = new AbortController();
    let signal = controller.signal;
    try {
      yield this.inboxApi.updateAttribute(conversationId, attribute, clientAssignedUuid, {
        signal,
      });
    } finally {
      controller.abort();
    }
  }

  private controllingAttributes(attribute: Descriptor): Descriptor[] {
    return attribute
      .controllingAttributeIds()
      .map((id) => this.attributeDescriptorsMap[id])
      .filter(Boolean);
  }

  private get attributeDescriptorsMap(): Record<number, Descriptor> {
    return this.attributeDescriptors.reduce(
      (byIds, descriptor) => {
        byIds[descriptor.id as number] = descriptor;
        return byIds;
      },
      {} as Record<number, Descriptor>,
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    conditionalAttributesService: ConditionalAttributesService;
    'conditional-attributes-service': ConditionalAttributesService;
  }
}
