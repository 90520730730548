/* RESPONSIBLE TEAM: team-workflows */

import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class AttributeDescriptorSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    _description: 'description',
    childDescriptors: { embedded: 'always' },
    listOptions: { embedded: 'always' },
    workflow: { serialize: false },
  };

  serialize(snapshot: any, options: any) {
    let json = super.serialize(snapshot, options);
    json.children = snapshot.record.get('children');
    delete json.child_descriptors;
    return json;
  }
}
