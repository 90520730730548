/* RESPONSIBLE TEAM: team-proactive-support */
/* eslint-disable promise/prefer-await-to-then */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable promise/prefer-await-to-then */
import { getOwner } from '@ember/application';
import EmberObject, { action, computed } from '@ember/object';
import { alias, bool, equal, notEmpty, or, readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { task, taskGroup, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import {
  objectFeatures,
  objectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  OUTBOUND_EDITOR_ROUTES,
  OUTBOUND_LIST_ROUTES,
} from 'embercom/models/outbound/content-wrapper';
import { stats } from '../models/data/stats-system/stats-constants';
import { isEmpty, isPresent } from '@ember/utils';
import { get } from 'embercom/lib/ajax';

const PrivateAPI = EmberObject.extend({
  previewEmailAddresses: '',
  previewUser: null,

  notificationsService: service(),
  router: service(),
  store: service(),
  appService: service(),
  attributeService: service(),
  intercomEventService: service(),
  intercomConfirmService: service(),
  parentRuleset: undefined,
  activeRulesetLink: undefined,

  activeView: undefined,
  mode: undefined,
  returnPath: undefined,
  uploadingFile: undefined,
  lastAutoSaveHash: undefined,
  customActivate: undefined,
  onChangeFilter: undefined,
  onChangeMode: undefined,
  onChangeView: undefined,
  onChangeActiveRulesetLink: undefined,
  onActivate: undefined,
  onDeactivate: undefined,
  selectedVersion: undefined,
  versionSidebarOpen: false,
  duplicateModalOpen: false,
  autoSaveEnabled: true,

  isSeriesMode: readOnly('parentRuleset.isMemberOfSeries'),
  isEditMode: equal('mode', 'edit').readOnly(),
  isViewMode: equal('mode', 'view').readOnly(),
  isUploadingFile: equal('uploadingFile', true).readOnly(),
  rulesetHasVersions: or('parentRuleset.currentVersion', 'selectedVersion'),
  isViewingVersionSnapshot: notEmpty('selectedVersion'),
  range: null,
  selectedConversationId: null,
  seriesRulesetsWithTriggers: [],

  isWorkflowMode: computed('ruleset.rulesetLinks.[]', function () {
    return this.ruleset.rulesetLinks.any(
      (link) => link.object?.isCustomBot && link.object.canUseVisualBuilderEditor,
    );
  }),

  updateRange: action(function (range) {
    this.set('range', range);
    if (isPresent(range)) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'update_date_range',
        object: 'date_range_filter',
        place: objectNames[this.activeRulesetLink.objectType],
        range_start_date: range.start,
        range_end_date: range.end,
        range_time_zone: range.timezone,
        selected_range: range.selectedRange,
      });
    }
  }),

  updateConversationId: action(function (conversationId) {
    this.set('selectedConversationId', conversationId);
  }),

  ruleset: computed('parentRuleset', 'selectedVersion', function () {
    if (this.selectedVersion) {
      return this.selectedVersion.snapshot;
    } else {
      return this.parentRuleset;
    }
  }),

  parentRulesetId: readOnly('parentRuleset.id'),

  seriesTriggerAttributes: computed('seriesRulesetsWithTriggers', function () {
    return this.seriesRulesetsWithTriggers
      .map((ruleset) => {
        let triggerEvent = this.attributeService.eventAttributes.find(
          (event) => event.event_id === ruleset.rulesetTriggers.firstObject.eventId,
        );

        return (triggerEvent?.metadata || []).map((attribute) => {
          let data = {
            name: attribute.name,
            identifier: `${attribute.identifier}.ruleset.${ruleset.id}`,
            type: attribute.type,
            templatableIdentifier: `${attribute.templatableIdentifier}.ruleset.${ruleset.id}`,
            isUserEventMetadata: true,
            ruleset,
          };
          return this.store.createRecord('attribute', data);
        });
      })
      .flat();
  }),

  selectedEvent: computed('ruleset', 'ruleset.rulesetTriggers.[]', function () {
    let rulesetTrigger = this.ruleset?.rulesetTriggers?.firstObject;

    if (!rulesetTrigger) {
      return;
    }

    return this.attributeService.eventAttributes.find(
      (event) => event.event_id === rulesetTrigger.eventId,
    );
  }),

  trackAnalyticsEvent(action, object) {
    let analyticsEventData = {
      action,
      object,
      ruleset_id: this.ruleset.id,
      content_type: objectNames[this.activeRulesetLink.objectType],
    };
    if (this.activeRulesetLink.object?.isCustomBot) {
      analyticsEventData['custom_bot_target'] = this.activeRulesetLink.object.target;
    }
    if (this.activeRulesetLink.object?.targetChannels) {
      analyticsEventData['target_channels'] = this.activeRulesetLink.object.targetChannels;
    }
    this.intercomEventService.trackAnalyticsEvent(analyticsEventData);
  },

  fetchSeriesRulesetsWithTriggers: action(function () {
    if (this.appService.app?.canUseSeriesMetaData && this.ruleset) {
      this._searchForTriggerConditions(this.ruleset.node).then((rulesets) => {
        this.set('seriesRulesetsWithTriggers', rulesets);
      });
    }
  }),

  async _searchForTriggerConditions(node) {
    let nodeIdsWithTriggers = await this._retrieveNodes();
    return await Promise.all(
      node.ancestorNodes
        .filter((node) => nodeIdsWithTriggers.includes(parseInt(node.id, 10)))
        .map((node) => node.fetchRuleset()),
    );
  },

  async _retrieveNodes() {
    let app = this.appService.app;
    let params = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };
    let response = await get(
      `/ember/series/series/${this.ruleset.node?.series?.id}/node_ids_with_trigger`,
      params,
    );
    return response;
  },

  rulesetLinkForConfiguration: computed('activeRulesetLink', function () {
    if (this.activeRulesetLink) {
      if (this.activeRulesetLink.objectType === objectTypes.controlGroup) {
        return this.ruleset.rulesetLinks.find(
          (link) => link.objectType !== objectTypes.controlGroup,
        );
      } else {
        return this.activeRulesetLink;
      }
    }
  }).readOnly(),

  objectForConfiguration: readOnly('rulesetLinkForConfiguration.object'),

  editorConfiguration: computed('objectForConfiguration', function () {
    if (this.objectForConfiguration) {
      return this.objectForConfiguration.editorConfig(this.ruleset);
    }
  }).readOnly(),

  activeObjectId: computed('activeRulesetLink', function () {
    return Number(this.activeRulesetLink.objectId);
  }),

  _apiTask: task(function* (block, notificationOptions) {
    this.autoSave.cancelAll();
    this.notificationsService.clear();
    if (notificationOptions.confirmation) {
      let confirmationMessage = yield notificationOptions.confirmation();
      if (confirmationMessage) {
        let confirmed = yield this.intercomConfirmService.confirm(confirmationMessage);
        if (confirmed === false) {
          return {};
        }
      }
    }
    try {
      let response = yield block();
      if (notificationOptions.success) {
        this.notificationsService.notifyConfirmation(notificationOptions.success);
      }
      return { success: true, response };
    } catch (error) {
      let failureMessage = notificationOptions.failure || 'Something went wrong';
      this.notificationsService.notifyResponseError(error, { default: failureMessage });
      return { success: false, error };
    }
  }),

  apiTasks: taskGroup().drop(),

  autoSave: task(function* () {
    yield timeout(ENV.APP._5000MS);

    if (!this.autoSaveEnabled) {
      return;
    }

    if (this.objectForConfiguration.autoSaveDisabled) {
      return;
    }

    if (this.apiTasks.isRunning) {
      return;
    }

    if (isEmpty(this.ruleset)) {
      return;
    }

    if (this.ruleset.isLive || this.ruleset.isScheduled || this.ruleset.node?.series?.isLive) {
      return;
    }

    if (this.isUploadingFile) {
      return;
    }

    if (this.ruleset.hasUnsavedChanges && this.apiTasks.state === 'idle' && this.isEditMode) {
      try {
        let currentHash = this.ruleset.hash();
        if (currentHash === this.lastAutoSaveHash) {
          return;
        }
        this.lastAutoSaveHash = currentHash;
        yield this.ruleset.save();
      } catch (e) {
        console.error('Autosave failed', e);
      }
    }
  }).restartable(),

  saveRuleset: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.save;
    let result = yield this._apiTask.perform(() => {
      return this.ruleset.save();
    }, notificationOptions);
    this.trackAnalyticsEvent('clicked', 'save_and_close');
    this.updateAutoSaveEnabled(true);
    return result;
  }).group('apiTasks'),

  saveAndActivateRuleset: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.activate;
    yield this._apiTask.perform(async () => {
      if (this.customActivate) {
        await this.customActivate();
      } else {
        await this.ruleset.save();
        await this.ruleset.activate();
      }
      this.trackAnalyticsEvent('clicked', 'set_live');
      this.reloadAppAfterFirstAiLive();
      this.changeToViewMode();
      this.onActivate();
    }, notificationOptions);
    this.updateAutoSaveEnabled(true);
  }).group('apiTasks'),

  async reloadAppAfterFirstAiLive() {
    // reload the app after setting live a AI answer behavior and the trial isn't stated. Reloading the app will refresh the trial state
    if (
      this.activeRulesetLink.objectType === objectTypes.resolutionBotBehavior &&
      this.activeRulesetLink.object?.useAiAnswers &&
      (this.appService.app.aiAnswersState === 'trial_not_started' ||
        !this.appService.app.hasOptedInToFin)
    ) {
      await this.appService.app.reload();
    }
  },

  activateRulesetLink: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.activateRulesetLink;
    yield this._apiTask.perform(() => {
      return this.ruleset.activate(this.activeRulesetLink.id);
    }, notificationOptions);
  }).group('apiTasks'),

  deactivateRulesetLink: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.deactivateRulesetLink;
    yield this._apiTask.perform(() => {
      return this.ruleset.deactivate(this.activeRulesetLink.id);
    }, notificationOptions);
  }).group('apiTasks'),

  endRulesetLink: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.endRulesetLink;
    yield this._apiTask.perform(async () => {
      await this.activeRulesetLink.end();
      await this.ruleset.reload();
    }, notificationOptions);
  }).group('apiTasks'),

  deactivateRuleset: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.deactivate;
    yield this._apiTask.perform(() => {
      return this.ruleset.deactivate();
    }, notificationOptions);
    this.onDeactivate();
  }).group('apiTasks'),

  deleteRuleset: task(function* (options = { transitionRoute: true }) {
    let notificationOptions = this.editorConfiguration.notificationMessages.delete;
    let deletedRulesetID = this.ruleset.id;
    let deletedContentType = objectNames[this.activeRulesetLink.objectType];
    let result = yield this._apiTask.perform(() => {
      return this.ruleset.destroyRecord();
    }, notificationOptions);
    if (result.success) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'delete',
        ruleset_id: deletedRulesetID,
        content_type: deletedContentType,
      });
      if (options.transitionRoute) {
        let routeName = OUTBOUND_LIST_ROUTES[deletedContentType] || 'apps.app.outbound.all';
        this.router.transitionTo(routeName);
      }
    }
  }).group('apiTasks'),

  duplicateRuleset: task(function* (
    newObjectType = null,
    newObjectData = {},
    matchBehavior = null,
  ) {
    let notificationOptions = this.editorConfiguration.notificationMessages.duplicate;
    let result = yield this._apiTask.perform(() => {
      return this.ruleset.duplicate({
        new_object_type: newObjectType,
        new_object_data: newObjectData,
        match_behavior: matchBehavior,
      });
    }, notificationOptions);
    if (result.success) {
      this.trackAnalyticsEvent('clicked', 'duplicate');
      let routeName =
        OUTBOUND_EDITOR_ROUTES[objectNames[newObjectType]] || this.router.currentRouteName;
      this.router.transitionTo(routeName, result.response.id, {
        queryParams: { mode: 'edit' },
      });
    }
    this.set('duplicateModalOpen', false);
  }).group('apiTasks'),

  cancelRulesetChanges: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.cancel;
    yield this._apiTask.perform(() => {
      this.ruleset.rollbackAttributes();
    }, notificationOptions);
  }).group('apiTasks'),

  duplicateRulesetLink: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.duplicateRulesetLink;
    let result = yield this._apiTask.perform(() => {
      return this.rulesetLinkForConfiguration.duplicate();
    }, notificationOptions);
    if (result.success) {
      this.store.pushPayload({ [result.response.type]: result.response });
      let newRulesetLink = this.store.peekRecord(`${result.response.type}`, result.response.id);
      this.ruleset.rulesetLinks.pushObject(newRulesetLink);
      return newRulesetLink;
    }
  }).group('apiTasks'),

  createControlGroup: task(function* () {
    let result = yield this._apiTask.perform(async () => {
      let newRulesetLink = await this.store
        .createRecord('matching-system/ruleset-links/control-group', {
          rulesetId: this.ruleset.id,
        })
        .save();

      this.ruleset.rulesetLinks.pushObject(newRulesetLink);
      return newRulesetLink;
    }, {});
    if (result.success) {
      return result.response;
    }
  }).group('apiTasks'),

  deleteRulesetLink: task(function* () {
    let notificationOptions;
    if (this.activeRulesetLink.isControlGroup) {
      notificationOptions = this.editorConfiguration.notificationMessages.deleteControlGroup;
    } else {
      notificationOptions = this.editorConfiguration.notificationMessages.deleteRulesetLink;
    }
    yield this._apiTask.perform(() => {
      return this.activeRulesetLink.destroyRecord();
    }, notificationOptions);
  }).group('apiTasks'),

  scheduleRuleset: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.schedule;
    yield this._apiTask.perform(async () => {
      await this.ruleset.save();
      await this.ruleset.schedule();
      this.changeToViewMode();
    }, notificationOptions);
  }).group('apiTasks'),

  unscheduleRuleset: task(function* () {
    let notificationOptions = this.editorConfiguration.notificationMessages.unschedule;
    yield this._apiTask.perform(() => {
      return this.ruleset.unschedule();
    }, notificationOptions);
  }).group('apiTasks'),

  changeToViewMode: action(async function () {
    this.set('mode', 'view');
    if (this.onChangeMode) {
      this.onChangeMode('view');
    }
    this.changeActiveViewTo('content');
  }),

  uploadFileStarted: action(async function () {
    this.set('uploadingFile', true);
  }),

  uploadFileFinished: action(async function () {
    this.set('uploadingFile', false);
  }),

  selectVersion: action(function (version) {
    let activeRulesetLinkInSnapshot = version.snapshot.rulesetLinks.find(
      (rulesetLink) => this.activeRulesetLink?.databaseId === rulesetLink.databaseId,
    );

    this.setProperties({
      selectedVersion: version,
      activeRulesetLink: activeRulesetLinkInSnapshot || version.snapshot.rulesetLinks.firstObject,
    });
  }),

  clearVersion: action(function () {
    this.setProperties({
      selectedVersion: undefined,
      activeRulesetLink: this.parentRuleset.rulesetLinks.firstObject,
    });
  }),

  changeActiveViewTo: action(function (view) {
    if (!this.isEditMode) {
      this.set('activeView', view);
      if (this.onChangeView) {
        this.onChangeView(view);
      }
      this.changeActiveFilterTo(stats.receipt);
    } else {
      throw new Error('Attempted to change active view while in edit mode');
    }
  }),

  changeActiveFilterTo: action(function (filter) {
    if (!this.isEditMode) {
      this.set('activeFilter', filter);
      if (this.onChangeFilter) {
        this.onChangeFilter(filter);
      }
    } else {
      throw new Error('Attempted to change active filter while in edit mode');
    }
  }),

  updateAutoSaveEnabled: action(function (newValue) {
    this.autoSaveEnabled = newValue;
  }),
});

export default Service.extend({
  init() {
    this._super(...arguments);
    let container = getOwner(this);
    this.set('_privateAPI', PrivateAPI.create({ container }));
    this.set = () => {
      throw new Error(`'set' is disabled. The content editor service is read only`);
    };
    this.setProperties = () => {
      throw new Error(`'setProperties' is disabled. The content editor service is read only`);
    };
  },

  isOpenInFinStandalone: false,

  previewUser: readOnly('_privateAPI.previewUser'),
  previewEmailAddresses: alias('_privateAPI.previewEmailAddresses'),

  isEditMode: readOnly('_privateAPI.isEditMode'),
  isSeriesMode: readOnly('_privateAPI.isSeriesMode'),
  isViewMode: readOnly('_privateAPI.isViewMode'),
  isWorkflowMode: readOnly('_privateAPI.isWorkflowMode'),
  isUploadingFile: readOnly('_privateAPI.isUploadingFile'),
  selectedVersion: readOnly('_privateAPI.selectedVersion'),
  activeObjectId: readOnly('_privateAPI.activeObjectId'),
  versionSidebarOpen: readOnly('_privateAPI.versionSidebarOpen'),
  duplicateModalOpen: readOnly('_privateAPI.duplicateModalOpen'),
  hasPreviouslyOpenedCurrentPanel: bool('_privateAPI.hasPreviouslyOpenedCurrentPanel'),

  ruleset: readOnly('_privateAPI.ruleset'),
  parentRulesetId: readOnly('_privateAPI.parentRulesetId'),
  selectedEvent: readOnly('_privateAPI.selectedEvent'),
  seriesTriggerAttributes: readOnly('_privateAPI.seriesTriggerAttributes'),
  activeFilter: readOnly('_privateAPI.activeFilter'),
  activeRulesetLink: readOnly('_privateAPI.activeRulesetLink'),
  activeView: readOnly('_privateAPI.activeView'),
  activeObject: readOnly('_privateAPI.activeRulesetLink.object'),
  returnPath: readOnly('_privateAPI.returnPath'),
  isViewingVersionSnapshot: readOnly('_privateAPI.isViewingVersionSnapshot'),
  parentRulesetLinks: readOnly('_privateAPI.parentRuleset.rulesetLinks'),
  activeRulesetLinkIndex: computed('ruleset.rulesetLinks.[]', 'activeRulesetLink', function () {
    return this.ruleset.rulesetLinks.indexOf(this.activeRulesetLink);
  }),

  activeHumanReadableObjectName: readOnly('activeRulesetLink.humanReadableObjectName'),

  async withModelChange(block, options = {}) {
    this.log(`withModelChange – location [${options.location}]`);
    await block();
  },

  configRulesetLink: readOnly('_privateAPI.rulesetLinkForConfiguration'),
  editorConfiguration: readOnly('_privateAPI.editorConfiguration'),
  initiallyOpenSectionName: readOnly(
    'editorConfiguration.editMode.editorPanelConfigs.firstObject.name',
  ),

  saveAndActivateRuleset: readOnly('_privateAPI.saveAndActivateRuleset'),
  activateRulesetLink: readOnly('_privateAPI.activateRulesetLink'),
  cancelRulesetChanges: readOnly('_privateAPI.cancelRulesetChanges'),
  deactivateRuleset: readOnly('_privateAPI.deactivateRuleset'),
  deactivateRulesetLink: readOnly('_privateAPI.deactivateRulesetLink'),
  deleteRuleset: readOnly('_privateAPI.deleteRuleset'),
  deleteRulesetLink: readOnly('_privateAPI.deleteRulesetLink'),
  duplicateRuleset: readOnly('_privateAPI.duplicateRuleset'),
  duplicateRulesetLink: readOnly('_privateAPI.duplicateRulesetLink'),
  endRulesetLink: readOnly('_privateAPI.endRulesetLink'),
  saveRuleset: readOnly('_privateAPI.saveRuleset'),
  scheduleRuleset: readOnly('_privateAPI.scheduleRuleset'),
  unscheduleRuleset: readOnly('_privateAPI.unscheduleRuleset'),
  rulesetHasVersions: readOnly('_privateAPI.rulesetHasVersions'),

  onChangeFilter: readOnly('_privateAPI.onChangeFilter'),
  onChangeMode: readOnly('_privateAPI.onChangeMode'),
  onChangeView: readOnly('_privateAPI.onChangeView'),
  onChangeActiveRulesetLink: readOnly('_privateAPI.onChangeActiveRulesetLink'),
  range: readOnly('_privateAPI.range'),
  selectedConversationId: readOnly('_privateAPI.selectedConversationId'),

  register(params = {}) {
    this.log(`Registered ruleset id:[${params.ruleset.id}]`);
    if (this.ruleset) {
      throw new Error('Only one Ruleset may be registered at a time in the content editor service');
    }
    this._privateAPI.setProperties({
      parentRuleset: params.ruleset,
      activeRulesetLink: params.rulesetLink || params.ruleset.rulesetLinks.firstObject,
      activeFilter: params.filter,
      activeView: params.view,
      mode: params.mode,
      returnPath: params.returnPath,
      customActivate: params.customActivate,
      onChangeFilter: params.onChangeFilter || (() => {}),
      onChangeMode: params.onChangeMode || (() => {}),
      onChangeView: params.onChangeView || (() => {}),
      onChangeActiveRulesetLink: params.onChangeActiveRulesetLink || (() => {}),
      onActivate: params.onActivate || (() => {}),
      onDeactivate: params.onDeactivate || (() => {}),
    });

    this._privateAPI.fetchSeriesRulesetsWithTriggers();

    if (params.mode === 'edit') {
      this.sectionDidOpen(this.initiallyOpenSectionName);
    }
  },

  unregister(ruleset) {
    if (this._privateAPI.parentRuleset === ruleset) {
      this._privateAPI.autoSave.cancelAll();
      this._privateAPI.setProperties({
        parentRuleset: undefined,
        activeRulesetLink: undefined,
        activeFilter: undefined,
        activeView: undefined,
        mode: undefined,
        returnPath: undefined,
        customActivate: undefined,
        onChangeFilter: undefined,
        onChangeMode: undefined,
        onChangeView: undefined,
        onChangeActiveRulesetLink: undefined,
        onActivate: undefined,
        onDeactivate: undefined,
        selectedVersion: undefined,
        versionSidebarOpen: false,
        selectedConversationId: null,
        autoSaveEnabled: true,
      });
      if (isPresent(this._privateAPI.range)) {
        this._privateAPI.updateRange(undefined);
      }
    } else {
      throw new Error('Attempted to unregister a Ruleset which was not set');
    }
  },

  changeToEditMode: action(async function () {
    this.ruleset.beforeEdit();
    this.log('Changing to edit mode');
    this._privateAPI.trackAnalyticsEvent('clicked', 'edit');
    this.changeActiveViewTo('content');
    this._privateAPI.set('mode', 'edit');
    if (this._privateAPI.onChangeMode) {
      this.onChangeMode('edit');
    }
  }),

  hideVersionSidebar: action(async function () {
    this._privateAPI.set('versionSidebarOpen', false);
    this._privateAPI.clearVersion();
  }),

  showVersionSidebar: action(async function () {
    this._privateAPI.set('versionSidebarOpen', true);
  }),

  hideDuplicateModal: action(async function () {
    this._privateAPI.set('duplicateModalOpen', false);
  }),

  showDuplicateModal: action(async function () {
    this._privateAPI.set('duplicateModalOpen', true);
  }),

  changeToViewMode: action(async function () {
    this.log('Changing to view mode');
    this._privateAPI.changeToViewMode();
  }),

  uploadFileStarted: action(async function () {
    this._privateAPI.uploadFileStarted();
  }),

  uploadFileFinished: action(async function () {
    this._privateAPI.uploadFileFinished();
  }),

  selectVersion: action(async function (version) {
    this._privateAPI.selectVersion(version);
  }),

  showAllVersions: action(async function () {
    this._privateAPI.clearVersion();
  }),

  changeActiveViewTo: action(async function (view) {
    this.log(`Changing activeView to: [${view}]`);
    this._privateAPI.changeActiveViewTo(view);
  }),

  changeActiveFilterTo: action(async function (filter) {
    this.log(`Changing activeFilter to: [${filter}]`);
    this._privateAPI.changeActiveFilterTo(filter);
  }),

  deleteTest: action(async function () {
    await this.deleteRulesetLink.perform();
    this.changeActiveRulesetLink(this.ruleset.rulesetLinks.firstObject);
  }),

  changeActiveRulesetLink: action(function (rulesetLink) {
    this.log(`Changing activeRulesetLink : [${rulesetLink.id}]`);
    if (
      !this.activeRulesetLink.isControlGroup &&
      rulesetLink.isControlGroup &&
      !['content', 'receipts', 'goals'].includes(this.activeView)
    ) {
      this.changeActiveViewTo('content');
    }
    this._privateAPI.set('activeRulesetLink', rulesetLink);
    if (this._privateAPI.onChangeActiveRulesetLink) {
      this.onChangeActiveRulesetLink(rulesetLink.id);
    }
  }),

  createNewTest: action(async function () {
    let newRulesetLink = await this.duplicateRulesetLink.perform();
    this._privateAPI.trackAnalyticsEvent('clicked', `content_editor_ab_test`);
    this.changeActiveRulesetLink(newRulesetLink);
  }),

  createNewControlGroup: action(async function () {
    let newRulesetLink = await this._privateAPI.createControlGroup.perform();
    this.log(`Changing activeRulesetLink : [${newRulesetLink.id}]`);
    this._privateAPI.trackAnalyticsEvent('clicked', `content_editor_control_group`);
    this.changeActiveRulesetLink(newRulesetLink);
  }),

  sectionDidOpen: action(function (panelName) {
    let clientData = this.ruleset.clientData;
    if (clientData.get('openedPanels').includes(panelName)) {
      this._privateAPI.set('hasPreviouslyOpenedCurrentPanel', true);
    } else {
      this._privateAPI.set('hasPreviouslyOpenedCurrentPanel', false);
      this.withModelChange(
        () => {
          clientData.get('openedPanels').pushObject(panelName);
        },
        { location: 'edit-mode.recordPanelOpen' },
      );
    }
    this._privateAPI.trackAnalyticsEvent('opened', `content_editor_${panelName}`);
  }),

  sectionDidClose: action(function (panelName) {
    this._privateAPI.trackAnalyticsEvent('closed', `content_editor_${panelName}`);
  }),

  setPreviewUser(user) {
    this._privateAPI.set('previewUser', user);
  },

  log(message) {
    if (ENV.environment !== 'production' || window.LOG_CONTENT_EDITOR_SERVICE) {
      console.info(`📝[Content Editor Service] ${message}`);
    }
  },

  /**
   * Checks if the workspace has activated countries for sms.
   * This is only done when the current view is associated with SMS.
   * If not, we return true as we it doesn't matter whether there are activated countries or not.
   */
  hasActivatedCountriesForSms() {
    if (this._privateAPI.editorConfiguration?.requiredFeature !== objectFeatures[objectTypes.sms]) {
      return true;
    }
    for (let predicate of this._privateAPI.ruleset?.implicitPredicates) {
      if (predicate.attribute === 'phone_country') {
        return predicate.value.length > 0;
      }
    }
  },

  updateRange(range) {
    this._privateAPI.updateRange(range);
  },

  updateConversationId(conversationId) {
    this._privateAPI.updateConversationId(conversationId);
  },

  updateAutoSaveEnabled(newValue) {
    this._privateAPI.updateAutoSaveEnabled(newValue);
  },
});
