/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
export default Service.extend({
  collapsed: false,
  toggle() {
    this.toggleProperty('collapsed');
  },
  expand() {
    this.set('collapsed', false);
  },
});
