/* RESPONSIBLE TEAM: team-data-foundations */

import Service from '@ember/service';
import type IntercomAttribute from 'embercom/models/attribute';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';

export default class AttributesConfigService extends Service {
  /* eslint-disable @intercom/intercom/no-bare-strings */
  validDefaultAttributePaths: Record<'company' | 'contact', Map<string, string>> = {
    company: new Map([['Company ID', 'company.remote_company_id']]),
    contact: new Map([
      ['User ID', 'user_id'],
      ['Email', 'email'],
      ['Company', 'company.remote_company_id'],
    ]),
  };
  /* eslint-enable @intercom/intercom/no-bare-strings */

  disabledAttributes: Record<'company' | 'contact', Map<string, boolean>> = {
    company: new Map([['company.last_request_at', true]]),
    contact: new Map(),
  };

  hiddenAttributes: Record<'contact', Map<string, boolean>> = {
    contact: new Map([
      ['first_name', true],
      ['last_name', true],
    ]),
  };

  allowedAttributes: Record<'contact' | 'company' | 'ticket', Set<string>> = {
    contact: new Set([
      'name',
      'email',
      'phone',
      'external_id',
      'remote_created_at',
      'last_request_at',
      'unsubscribed_from_emails',
    ]),
    company: new Set([
      'company.name',
      'company.remote_company_id',
      'company.remote_created_at',
      'company.monthly_spend',
      'company.size',
      'company.industry',
      'company.website',
    ]),
    ticket: new Set(),
  };

  matchDisabledAttribute(objectType: string, attr: IntercomAttribute) {
    switch (objectType) {
      case 'company':
        return this.disabledAttributes.company.has(attr.identifier);
      case 'contact':
        return this.disabledAttributes.contact.has(attr.identifier);
      default:
        return false;
    }
  }

  matchHiddenAttribute(objectType: string, attr: IntercomAttribute) {
    switch (objectType) {
      case 'contact':
        return this.hiddenAttributes.contact.has(attr.identifier);
      default:
        return false;
    }
  }

  matchAllowedAttribute(objectType: string, attr: IntercomAttribute) {
    switch (objectType) {
      case 'contact':
        return this.allowedAttributes.contact.has(attr.identifier);
      case 'company':
        return this.allowedAttributes.company.has(attr.identifier);
      case 'ticket':
        return true;
      default:
        return false;
    }
  }

  matchSpecialMapping(
    objectType: string,
    attr: IntercomAttribute,
    mapping: AttributeMapping,
  ): boolean {
    return (
      !mapping.editable && this.getSpecialMappingIdentifier(objectType, mapping) === attr.identifier
    );
  }

  private getSpecialMappingIdentifier(
    objectType: string,
    mapping: AttributeMapping,
  ): string | undefined {
    switch (objectType) {
      case 'company':
        return this.validDefaultAttributePaths.company.get(mapping.destinationAttributePath);
      case 'contact':
        return this.validDefaultAttributePaths.contact.get(mapping.destinationAttributePath);
      default:
        return undefined;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    attributesConfigService: AttributesConfigService;
    'data/attributes-config-service': AttributesConfigService;
  }
}
