/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import TicketCustomState, {
  type TicketCustomStateWireFormat,
} from 'embercom/objects/inbox/ticket-custom-state';
import { type ConversationState } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import type Session from './session';
import type IntlService from 'ember-intl/services/intl';
import { captureException } from '@sentry/browser';
import { postRequest, putRequest, request } from 'embercom/lib/inbox/requests';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import { groupBy } from 'underscore';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type PALETTE from '@intercom/pulse/lib/palette';

export enum SystemState {
  Submitted = '0',
  InProgress = '32',
  WaitingOnCustomer = '64',
  Resolved = '96',
}

const systemStateMap: { [key: string]: string | undefined } = {
  '0': 'submitted',
  '32': 'in_progress',
  '64': 'waiting_on_customer',
  '96': 'resolved',
};

export function systemStateIdToString(value: string): string | undefined {
  return systemStateMap[value];
}

export interface SystemStateIconDetails {
  icon: InterfaceIconName;
  iconColor: keyof typeof PALETTE;
  backgroundColor: string;
  cssClass: string;
}

type IconMapping = {
  [key in TicketSystemState]: SystemStateIconDetails;
};

const ticketSystemStateSortKey = {
  [TicketSystemState.Submitted]: 1,
  [TicketSystemState.InProgress]: 2,
  [TicketSystemState.WaitingOnCustomer]: 3,
  [TicketSystemState.Resolved]: 4,
};

export default class TicketStateService extends Service {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked customStates: TicketCustomState[] = [];
  @tracked ticketTypes: TicketType[] = [];

  static SystemStateToIconMapping: IconMapping = {
    [TicketSystemState.Submitted]: {
      icon: 'submitted',
      iconColor: 'beta-fill',
      backgroundColor: 'beta-container',
      cssClass: 'ticket-state__submitted-pill',
    },
    [TicketSystemState.InProgress]: {
      icon: 'in-progress',
      iconColor: 'accent-fill',
      backgroundColor: 'accent-container',
      cssClass: 'ticket-state__in-progress-pill',
    },
    [TicketSystemState.WaitingOnCustomer]: {
      icon: 'waiting',
      iconColor: 'error-fill',
      backgroundColor: 'error-container',
      cssClass: 'ticket-state__waiting-on-customer-pill',
    },
    [TicketSystemState.Resolved]: {
      icon: 'resolved',
      iconColor: 'success-fill',
      backgroundColor: 'success-container',
      cssClass: 'ticket-state__resolved-pill',
    },
  };

  async setup() {
    await this.reloadTicketCustomStates();

    this.ticketTypes = await this.session.workspace.fetchTicketTypes();
  }

  async reloadTicketCustomStates() {
    this.customStates = await this.listTicketCustomStates();

    // Get custom states for the system state ordered by id
    // Ordering by ID garantees that the first state is the oldest one
    // Which is the one that should be used as the default state
    this.customStates = this.customStates.sort((a, b) => a.id - b.id);
  }

  get liveTicketCustomStates() {
    return this.customStates.rejectBy('archived');
  }

  get archivedTicketCustomStates() {
    return this.customStates.filterBy('archived');
  }

  getTranslatedSystemState(systemState: string): string {
    let translationKeyMap = new Map<SystemState, string>([
      [SystemState.InProgress, 'inbox.ticket-state.in_progress'],
      [SystemState.Resolved, 'inbox.ticket-state.resolved'],
      [SystemState.Submitted, 'inbox.ticket-state.submitted'],
      [SystemState.WaitingOnCustomer, 'inbox.ticket-state.waiting_on_customer'],
    ]);

    return this.intl.t(translationKeyMap.get(systemState as SystemState) || '');
  }

  getAllSystemStateIcons(): SystemStateIconDetails[] {
    return Object.values(TicketStateService.SystemStateToIconMapping);
  }

  getIconForSystemState(systemState: TicketSystemState): SystemStateIconDetails {
    return TicketStateService.SystemStateToIconMapping[systemState];
  }

  getTicketTypesForCustomState(customState: TicketCustomState): TicketType[] {
    if (!customState.ticketTypeIds) {
      return [];
    }

    return this.ticketTypes.filter((type) => customState.ticketTypeIds.includes(type.id));
  }

  getLiveCustomStatesForTicketType(ticketType: TicketType): TicketCustomState[] {
    return this.getTicketCustomStatesForTicketType(ticketType, this.liveTicketCustomStates);
  }

  getArchivedCustomStatesForTicketType(ticketType: TicketType): TicketCustomState[] {
    return this.getTicketCustomStatesForTicketType(ticketType, this.archivedTicketCustomStates);
  }

  ticketStateBelongsToTicketType(ticketType: TicketType, customStateId: number): boolean {
    let states = this.getLiveCustomStatesForTicketType(ticketType);
    return states.any((ticketCustomState) => {
      return ticketCustomState.id === customStateId;
    });
  }

  canCloseTicketWithState(
    conversation: Conversation | ConversationTableEntry | ConversationRecord | undefined,
    state: ConversationState | TicketSystemState | number | undefined,
  ) {
    return (
      conversation &&
      conversation.ticketType &&
      Number.isFinite(Number(state)) &&
      this.ticketStateBelongsToTicketType(conversation.ticketType, state as unknown as number)
    );
  }

  private getTicketCustomStatesForTicketType(
    ticketType: TicketType,
    customStates: TicketCustomState[],
  ): TicketCustomState[] {
    return customStates
      .filter((state: TicketCustomState) => ticketType.ticketCustomStateIds.includes(state.id))
      .sort((a, b) =>
        this.bySystemStateAndTicketTypeCompareFunction(ticketType.ticketCustomStateIds, a, b),
      );
  }

  bySystemStateAndTicketTypeCompareFunction(
    ticketCustomStateIds: number[],
    a: TicketCustomState,
    b: TicketCustomState,
  ): number {
    let aSystemState = ticketSystemStateSortKey[a.systemState];
    let bSystemState = ticketSystemStateSortKey[b.systemState];

    if (aSystemState < bSystemState) {
      return -1;
    } else if (aSystemState > bSystemState) {
      return 1;
    }

    let indexA = ticketCustomStateIds.indexOf(a.id);
    let indexB = ticketCustomStateIds.indexOf(b.id);

    return indexA - indexB;
  }

  getGroupedCustomStatesForDropdown(
    customStates: TicketCustomState[],
    additionalComponentAttrs = {},
  ) {
    let groupLabelsArr: { [key: string]: string } = {
      in_progress: this.intl.t('inbox.ticket-state.in_progress'),
      resolved: this.intl.t('inbox.ticket-state.resolved'),
      submitted: this.intl.t('inbox.ticket-state.submitted'),
      waiting_on_customer: this.intl.t('inbox.ticket-state.waiting_on_customer'),
    };

    return Object.entries(
      groupBy(customStates, (state: TicketCustomState) => state.systemState),
    ).map(([systemState, states]) => ({
      heading: groupLabelsArr[systemState],
      items: states.map((state: any) => ({
        text: state.adminLabel,
        value: state.id,
        component: 'settings/ticket-states/ticket-state-dropdown-item',
        componentAttrs: {
          state,
          ...additionalComponentAttrs,
        },
      })),
    }));
  }

  getTicketCustomStateById(id?: number): TicketCustomState {
    if (!id) {
      console.warn('Empty custom state ID. Returning fallback state');
      return this.customStates[0];
    }

    let customStates = this.customStates.find((state) => `${state.id}` === `${id}`);

    if (!customStates) {
      console.warn(`Custom state with id ${id} not found. Returning fallback state`);
      return this.customStates[0];
    }
    return customStates;
  }

  getFirstTicketCustomStatesForSystemState(systemState: TicketSystemState): TicketCustomState {
    let customStatesForSystemState = this.getLiveTicketCustomStatesForSystemState(systemState);
    return customStatesForSystemState[0];
  }

  getLiveTicketCustomStatesForSystemState(systemState: TicketSystemState): TicketCustomState[] {
    return this.liveTicketCustomStates.filter((cs) => cs.systemState === systemState);
  }

  async listTicketCustomStates(): Promise<TicketCustomState[]> {
    try {
      let response = await request(
        `/ember/inbox/ticket_custom_states?app_id=${this.session.workspace.id}`,
      );
      let json = (await response.json()) as TicketCustomStateWireFormat[];
      return json.map(TicketCustomState.deserialize);
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  async createTicketCustomState(
    systemState: TicketSystemState,
    adminLabel: string,
    userLabel: string,
  ): Promise<TicketCustomState> {
    try {
      let response = await postRequest(
        `/ember/inbox/ticket_custom_states?app_id=${this.session.workspace.id}`,
        {
          system_state: systemState,
          admin_label: adminLabel,
          user_label: userLabel,
        },
      );
      let json = (await response.json()) as TicketCustomStateWireFormat;
      let newRecord = TicketCustomState.deserialize(json);
      this.customStates.push(newRecord);
      this.customStates = [...this.customStates]; // Re-create the array so components that depends on it knows they should re-render
      return newRecord;
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  async updateTicketCustomState(
    id: number,
    adminLabel: string,
    userLabel: string,
  ): Promise<TicketCustomState> {
    try {
      let response = await putRequest(
        `/ember/inbox/ticket_custom_states/${id}?app_id=${this.session.workspace.id}`,
        {
          admin_label: adminLabel,
          user_label: userLabel,
        },
      );
      let json = (await response.json()) as TicketCustomStateWireFormat;
      let updatedRecord = TicketCustomState.deserialize(json);
      let index = this.customStates.findIndex((state) => state.id === id);
      this.customStates[index] = { ...this.customStates[index], ...updatedRecord }; // Since updating ticket-states can't influence on ticket-types, we need to merge the info received
      this.customStates = [...this.customStates]; // Re-create the array so components that depends on it knows they should re-render
      return updatedRecord;
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  async archiveTicketCustomState(ticketCustomStateId: number) {
    try {
      let response = await putRequest(
        `/ember/inbox/ticket_custom_states/${ticketCustomStateId}/archive?app_id=${this.session.workspace.id}`,
      );
      return await response.json();
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  async unarchiveTicketCustomState(ticketCustomStateId: number) {
    try {
      let response = await putRequest(
        `/ember/inbox/ticket_custom_states/${ticketCustomStateId}/unarchive?app_id=${this.session.workspace.id}`,
      );
      return await response.json();
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  async getTicketCustomStateDependentObjects(ticketCustomStateId: number): Promise<{
    ticket_custom_state_id: number;
    dependent_ticket_types: [];
  }> {
    try {
      let response = await request(
        `/ember/inbox/ticket_custom_states/${ticketCustomStateId}/dependent_objects?app_id=${this.session.workspace.id}`,
      );
      let json = (await response.json()) as {
        ticket_custom_state_id: number;
        dependent_ticket_types: [];
      };
      return json;
    } catch (e) {
      captureException(e);
      throw e;
    }
  }

  // we're transitioning away from EmberData,
  // but we still have some places where we use it
  // so we need to manually update ticket-types in this service, to keep them in sync with store
  // this method should be used whenever ember-data setProperties is used on any ticketType
  updateTicketType(ticketTypeId: string | number, attributes: Partial<TicketType>) {
    let normalizedTicketTypeId =
      typeof ticketTypeId === 'string' ? parseInt(ticketTypeId, 10) : ticketTypeId;

    let index = this.ticketTypes.findIndex((type) => type.id === normalizedTicketTypeId);
    if (index < 0) {
      return;
    }

    this.ticketTypes[index] = Object.assign(this.ticketTypes[index], attributes);
    this.ticketTypes = [...this.ticketTypes]; // Re-create the array so components that depends on it knows they should re-render
  }
}
