/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import {
  filter,
  filterBy,
  mapBy,
  notEmpty,
  readOnly,
  reads,
  setDiff,
  union,
  uniq,
} from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import { rejectBy, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import AttributeWithSettings from 'embercom/lib/attribute-with-settings';
import { getAssignmentRulesMessageAttributes } from 'embercom/models/data/assignment-rules-message-attributes';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import AttributeDescriptor from 'embercom/models/objects/attribute-descriptor';
import Attribute from 'embercom/models/attribute';
import {
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  RESERVED_OBJECT_TYPE_IDENTIFIERS,
  STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP,
  USER_OBJECT_TYPE_IDENTIFIER,
} from 'embercom/models/custom-objects/constants/object-types';

const EXTRA_USER_ATTRIBUTES = ['Current page URL'];
const CONVERSATION_ATTRIBUTE_DESCRIPTOR_MAPPED_TYPES = {
  decimal: 'float',
  list: 'conversation_attribute_list',
};
const createAttributeModels = (propertyKey) =>
  computed(function () {
    return this.get(propertyKey).map((data) => this.store.createRecord('attribute', data));
  });

export default Service.extend({
  store: service(),
  appService: service(),
  modelDataCacheService: service(),
  customObjectsService: service(),
  intl: service(),
  inboxVersion: service(),

  init() {
    this._super(...arguments);
    // Standard attributes not listed here can't be updated by end user:
    // https://github.com/intercom/intercom/blob/0bf6cda71218de195114b9d80ecf101ea21de8bf/app/commands/users/update_attribute.rb#L26-L38
    this.collectableStandardAttributes = [
      'name',
      'email',
      'phone',
      'company.name',
      'company.size',
      'company.website',
      'company.industry',
    ];
  },

  companyAttributeNamesToDisplay: [
    'id',
    'company.remote_company_id',
    'company.user_count',
    'company.plan_id',
    'company.remote_created_at',
    'company.size',
    'company.monthly_spend',
    'company.last_request_at',
    'company.session_count',
    'company.industry',
    'company.website',
  ],
  blockListedCompanyAttributeNames: [
    'company.plan.name',
    'company.tag_ids',
    'company.manual_tag_ids',
  ],
  blockListedAttributeNames: [
    'anonymous',
    'name',
    'geoip_data.continent_code',
    'geoip_data.country_name',
    'geoip_data.country_code',
    'geoip_data.region_name',
    'geoip_data.city_name',
    'geoip_data.timezone',
    'social_accounts.facebook.accounts.0.url',
    'social_accounts.twitter.accounts.0.url',
    'social_accounts.linkedin.accounts.0.url',
    'social_accounts.klout.accounts.0.kscore',
    'social_accounts.job_title',
    'company.tag_ids',
    'manual_tag_ids',
    'account_id',
    'owner_name',
    'opted_in_subscription_type_ids',
    'opted_out_subscription_type_ids',
    'companies',
  ],
  blockListedStatsSystemGoalAttributeNames: ['tag_ids'],
  abmTemplatableAttributes: ['owner_name', 'account_name'],

  userIdentifyingAndNonEditableAttributes: [
    'email',
    'phone',
    'user_id',
    'custom_data.last_seen_ip',
    'custom_data.last_visited_url',
    'custom_data.last_seen_user_agent',
    'custom_data.intercom_referrer',
    'custom_data.browser_locale',
    'custom_data.messenger_locale',
  ],

  showCompanyAttributes: reads('app.companiesActive'),
  attributeNames: mapBy('displayableAttributes', 'identifier'),
  eventAttributes: filterBy('attributes', 'type', 'user_event'),
  contentEventAttributes: filterBy('attributes', 'type', 'content_event'),
  eventNames: mapBy('eventAttributes', 'identifier'),
  hasEvents: notEmpty('eventNames'),
  qualificationAttributeIdentifiers: mapBy('app.qualification_attributes', 'identifier'),
  attributeSettings: reads('app.attribute_settings'),
  attributes: rejectBy('app.attributes', 'isRelationshipDataType', true),
  userRelationshipAttributes: filterBy('app.attributes', 'isRelationshipDataType', true),
  userAttributesOnly: rejectBy('attributes', 'isCompany', true),
  companyAttributesOnly: filterBy('attributes', 'isCompany', true),
  nonArchivedAttributes: rejectBy('attributes', 'archived', true),
  filterableAttributes: filterBy('attributes', 'filterable', true),
  extraCompanyAttributes: filterBy('allCompanyAttributes', 'isCustomData', true),
  allUserAttributes: filterBy('displayableAttributes', 'isUser', true),
  allCompanyAttributes: filterBy('displayableAttributes', 'isCompany', true),
  allCompanyAttributeNames: mapBy('allCompanyAttributes', 'identifier'),
  displayableCompanyAttributes: filter('displayableAttributes', function (attribute) {
    return (
      attribute.isCompany && !this.blockListedCompanyAttributeNames.includes(attribute.identifier)
    );
  }),
  displayedCompanyAttributes: filterBy('displayableAttributes', 'isDisplayedOnCompanies', true),
  // This is the list of attributes that will be visible on the user list
  displayedUserAttributes: filterBy('displayableAttributes', 'isDisplayedOnUsers', true),
  attributesForGoals: uniq('messageGoalAttributes'),
  attributesForExit: readOnly('filterableAttributesWithoutRoleOrType'),
  attributesForEntry: readOnly('filterableAttributesWithoutRoleOrType'),
  filterableCompanyAttributesForMessaging: filterBy(
    'filterableAttributesWithoutRoleOrType',
    'isCompany',
  ),
  filterableUserAttributesWithoutContentTypes: computed(
    'filterableUserAttributesForMessaging',
    function () {
      return this.filterableUserAttributesForMessaging.reject(
        (filterableAttribute) => filterableAttribute.get('type') === 'content_event',
      );
    },
  ),

  filterableUserAttributesForMessaging: filterBy('filterableAttributesWithoutRoleOrType', 'isUser'),
  filterableVisitorAttributesForMessaging: filterBy('filterableAttributes', 'visitor_triggered'),
  filterableCompanyAttributesMaybeWithPlansSegmentsAndTags: filter(
    'filterableCompanyAttributesForMessaging',
    function (attribute) {
      return !(
        this._shouldRejectAttributeIfNoSegmentsOrTags(attribute, 'app.editableCompanySegments') ||
        this._shouldRejectAttributeIfNoCollection(attribute, 'plan', 'app.plans')
      );
    },
  ),
  filterableUserAttributesMaybeWithSegmentsAndTags: filter(
    'filterableUserAttributesWithoutContentTypes',
    function (attribute) {
      return !this._shouldRejectAttributeIfNoSegmentsOrTags(attribute, 'app.editableUserSegments');
    },
  ),
  filterableUserAttributesForInboxRules: computed('filterableAttributesWithoutRole', function () {
    return this.filterableAttributesWithoutRole
      .filterBy('isUser', true)
      .rejectBy('isUserEvent', true);
  }),
  filterableCompanyAttributesForInboxRules: filterBy(
    'filterableAttributesWithoutRole',
    'isCompany',
    true,
  ),

  assignmentRulesMessageAttributesAsAttributes: createAttributeModels(
    '_assignmentRulesMessageAttributesData',
  ),
  channelAttributes: computed('assignmentRulesMessageAttributesAsAttributes', function () {
    return this.assignmentRulesMessageAttributesAsAttributes.filter((a) => a.isChannel);
  }),
  messageAttributes: computed('assignmentRulesMessageAttributesAsAttributes', function () {
    return this.assignmentRulesMessageAttributesAsAttributes.reject((a) => a.isChannel);
  }),
  workflowConditionAttributes: computed(
    'nonArchivedConversationCustomAttributes',
    'ticketTypes.@each.archived',
    function () {
      let conversationAttributes = [
        ...this.assignmentRulesMessageAttributesAsAttributes,
        ...this.assigneeAttributes,
        ...this.outboundSupportConversationAttributes,
        ...this.ticketAttributes,
        ...this.nonArchivedConversationCustomAttributes,
        ...this.ticketCustomStateAttribute,
      ];

      let workflowAttributes = [
        {
          heading: 'Availability',
          attributes: this.availabilityAttributes,
        },
        {
          heading: 'Conversation data',
          attributes: conversationAttributes,
        },
        {
          heading: 'People data',
          attributes: this.filterableUserAttributesForInboxRules,
        },
        {
          heading: 'Company data',
          attributes: this.filterableCompanyAttributesForInboxRules,
        },
      ];
      workflowAttributes = workflowAttributes.concat(this.ticketAttributesByType);

      return workflowAttributes;
    },
  ),
  copilotConversationMatchingAttributes: computed(
    'conversationAttributes',
    'filterableCompanyAttributesForMessaging',
    'visitorMessagingAttributesNoPageTargetting',
    function () {
      let attributes = this.visitorMessagingAttributesNoPageTargetting;
      attributes = attributes.concat(this.conversationAttributes);

      if (this.app?.companiesActive) {
        attributes = attributes.concat(this.filterableCompanyAttributesForMessaging);
      }

      return attributes;
    },
  ),
  filteredCopilotConversationAttributes: computed(
    'copilotConversationMatchingAttributes.[]',
    function () {
      return this.copilotConversationMatchingAttributes.filter(
        ({ type, category, isAiAgent }) =>
          type !== 'datetime' && type !== 'integer' && category !== 'ticket' && !isAiAgent,
      );
    },
  ),
  audienceConversationAttributes: computed(
    'conversationAttributes',
    'brandNameAttribute',
    function () {
      let conversationAttributes = this.app?.canUseFinStandalone
        ? this.conversationAttributes
        : this.conversationAttributes.concat(this.brandNameAttribute);

      let attributes = [
        {
          heading: 'Conversation data',
          attributes: conversationAttributes,
        },
      ];
      return attributes;
    },
  ),
  ticketAttributesByType: computed(
    'intl.locale',
    'nonArchivedTicketTypeAttributes',
    'ticketTypes.@each.archived',
    function () {
      if (this.app?.canUseFinStandalone) {
        return [];
      }

      let ticketAttributeGroupList = [];
      let ticketTypesWithDescriptors = this.ticketTypes.reject(({ archived, descriptors }) => {
        return (
          archived ||
          descriptors.length === 0 ||
          descriptors.every(({ archived, dataType }) => archived === true || dataType === 'files')
        );
      });

      ticketTypesWithDescriptors.sortBy('name').forEach(({ name, category, descriptors }) => {
        let validDescriptors = descriptors.filter(
          ({ archived, dataType, isBuiltIn }) =>
            archived === false && dataType !== 'files' && !isBuiltIn,
        );
        let attributes = validDescriptors.map((descriptor) => {
          let attribute = this.nonArchivedTicketTypeAttributes.findBy(
            'identifier',
            `conversation.custom_attribute.${descriptor.id}`,
          );
          if (descriptor?.isBuiltIn) {
            attribute.name = this.intl.t(
              `inbox.ticket-attributes.default-attributes.${descriptor.name}`,
            );
          }
          return attribute;
        });

        category = this.intl.t(`settings.ticket-data.categories.${category}`);

        if (attributes.length > 0) {
          ticketAttributeGroupList.push({
            heading: name,
            category,
            attributes,
          });
        }
      });

      return ticketAttributeGroupList;
    },
  ),
  crmTicketAttributesByType: computed(
    'nonArchivedTicketTypeAttributes',
    'ticketTypes.@each.archived',
    function () {
      let ticketAttributesByType = {};
      let nonArchivedTicketTypes = this.ticketTypes.reject(({ archived }) => archived);

      nonArchivedTicketTypes.forEach(({ id, descriptors }) => {
        let validDescriptors = descriptors.filter(
          ({ archived, dataType, isBuiltIn }) => archived === false && !isBuiltIn,
        );
        let attributes = validDescriptors.map((descriptor) => {
          let attribute = this.nonArchivedTicketTypeAttributes.findBy(
            'identifier',
            `conversation.custom_attribute.${descriptor.id}`,
          );

          attribute.humanFriendlyName = attribute.name;
          attribute.identifier = `ticket_attributes.${descriptor.id}`;
          attribute.required = descriptor.requiredToCreate;
          attribute.type = descriptor.dataType;

          return attribute;
        });

        ticketAttributesByType[id] = attributes;
      });

      return ticketAttributesByType;
    },
  ),

  visualBotBuilderConditionalAttributes: computed(
    'assigneeAttributes',
    'availabilityAttributes',
    'botAutoMessageAttributes',
    'capacityAttributes',
    'outboundInitiatorConversationAttributes',
    'participantConversationAttributes',
    'customObjectAttributeGroups',
    'nonArchivedConversationCustomAttributesWithoutFile',
    'conversationStateAttributes',
    'conversationTopicAttributes',
    'ticketTypes.@each.archived',
    'conversationTagAttributes',
    'ticketCustomStateAttribute',
    'customActionTicketAttributes',
    function () {
      return this.botAutoMessageAttributes
        .reject((attribute) => attribute.get('isTimeOnPage'))
        .reject((attribute) => attribute.get('isSocialAccount'))
        .rejectBy('identifier', 'role')
        .concat(
          this.nonArchivedConversationCustomAttributesWithoutFile.reject((attribute) =>
            attribute.get('hideFromAutomation'),
          ),
        )
        .concat(this.assigneeAttributes)
        .concat(this.availabilityAttributes)
        .concat(this.capacityAttributes)
        .concat(this.outboundInitiatorConversationAttributes)
        .concat(this.participantConversationAttributes)
        .concat(this.conversationStateAttributes)
        .concat(
          this.app?.hasConversationalInsightsBillingFeature ? this.conversationTopicAttributes : [],
        )
        .concat(this.ticketAttributes)
        .concat(this.ticketCustomStateAttribute)
        .concat(this.customActionTicketAttributes)
        .concat(this.systemDefinedConversationAttributesWithoutCategory)
        .concat(this.systemDefinedTicketAttributes)
        .concat(this.conversationTagAttributes)
        .concat(this.app?.canUseFinUsageLimitPredicate ? this.finUsageAttributes : []);
    },
  ),

  visualBotBuilderConditionalAttributesGroupList: computed(
    'visualBotBuilderConditionalAttributes',
    'customObjectAttributeGroups',
    function () {
      let attributes = this.visualBotBuilderConditionalAttributes;
      let attributeGroups = this.attributesToGroupList(attributes);
      return attributeGroups
        .concat(this.customObjectAttributeGroups)
        .concat(this.ticketAttributesByType);
    },
  ),
  visualBotBuilderSetUserDataAttributes: computed('userAttributesOnly', function () {
    return this.userAttributesOnly
      .filterBy('isCustomData')
      .rejectBy('archived')
      .rejectBy('isRelationshipDataType')
      .rejectBy('type', 'date');
  }),
  visualBotBuilderSetConversationDataAttributes: computed(
    'nonArchivedNonSystemDefinedConversationCustomAttributes',
    function () {
      return this.nonArchivedNonSystemDefinedConversationCustomAttributes.rejectBy('type', 'files');
    },
  ),

  workflowFilterAttributes: createAttributeModels('_workflowFilterAttributes'),
  contentServiceDateFilterAttributes: createAttributeModels('_contentServiceDateFilterAttributes'),
  availabilityAttributes: createAttributeModels('_availabilityAttributes'),
  capacityAttributes: createAttributeModels('_capacityAttributes'),
  finUsageAttributes: createAttributeModels('_finUsageAttributes'),
  outboundSupportConversationAttributes: createAttributeModels(
    '_outboundSupportConversationAttributes',
  ),
  outboundInitiatorConversationAttributes: createAttributeModels(
    '_outboundInitiatorConversationAttributes',
  ),
  conversationStateAttributes: createAttributeModels('_conversationStateAttributes'),
  conversationTopicAttributes: createAttributeModels('_conversationTopicAttributes'),
  conversationTagAttributes: createAttributeModels('_conversationTagAttributes'),
  participantConversationAttributes: createAttributeModels('_participantConversationAttributes'),
  ticketAttributes: createAttributeModels('_ticketAttributes'),
  ticketStateAttribute: createAttributeModels('_ticketStateAttribute'),
  ticketCustomStateAttribute: createAttributeModels('_ticketCustomStateAttribute'),
  optionalConversationTagAttribute: createAttributeModels('_optionalConversationTagAttribute'),
  clientVamAttributes: createAttributeModels('_clientVamAttributesData'),
  eventMetadataAttributes: createAttributeModels('_eventMetadataAttributes'),
  newVamPredicates: createAttributeModels('_newVamPredicatesData'),
  newAbmPredicates: createAttributeModels('_newAbmPredicatesData'),
  tourClientPredicates: createAttributeModels('_tourClientPredicatesData'),
  createdByAttribute: createAttributeModels('_createdByAttribute'),
  brandNameAttribute: createAttributeModels('_brandNameAttribute'),
  standaloneZendeskBrandAttribute: createAttributeModels('_standaloneZendeskBrandAttribute'),

  _assignmentRulesMessageAttributesData: computed('app.features.[]', function () {
    return this.app?.canUseFinStandalone
      ? []
      : getAssignmentRulesMessageAttributes(this.intl).filter(
          (attribute) =>
            (attribute.available_with_feature === undefined ||
              attribute.available_with_feature.any((feature) =>
                this.app?.canUseFeature(feature),
              )) &&
            (attribute.not_available_with_feature === undefined ||
              attribute.not_available_with_feature.any(
                (feature) => !this.app?.canUseFeature(feature),
              )),
        );
  }),

  _eventMetadataAttributes: computed('eventAttributes', function () {
    return this.eventAttributes
      .reduce(
        (metadataArray, userAttribute) => metadataArray.concat(userAttribute.metadata.toArray()),
        [],
      )
      .map((metadata) => {
        return {
          name: metadata.humanFriendlyName,
          identifier: metadata.identifier,
          type: metadata.type,
          templatableIdentifier: metadata.templatableIdentifier,
          isUserEventMetadata: true,
        };
      });
  }),

  _workflowFilterAttributes: computed('intl.locale', 'app', function () {
    return [
      {
        name: this.intl.t('settings.inbox-rules.index.filter.attribute.assign-to.name'),
        identifier: 'rules_filters.assign_to',
        type: 'rules_filters_assign_to',
        icon: 'assignment',
        description: this.intl.t(
          'settings.inbox-rules.index.filter.attribute.assign-to.description',
        ),
      },
      {
        name: this.intl.t('settings.inbox-rules.index.filter.attribute.tag.name'),
        identifier: 'conversation.tag_ids',
        type: 'rules_filters_manual_tag',
        icon: 'tag',
        description: this.intl.t('settings.inbox-rules.index.filter.attribute.tag.description'),
      },
      {
        name: this.intl.t('settings.inbox-rules.index.filter.attribute.mark-as-priority.name'),
        identifier: 'rules_filters.mark_priority',
        type: 'rules_filters_mark_priority',
        icon: 'star',
        description: this.intl.t(
          'settings.inbox-rules.index.filter.attribute.mark-as-priority.description',
        ),
      },
      {
        name: this.intl.t('settings.inbox-rules.index.filter.attribute.apply-sla.name'),
        identifier: 'rules_filters.apply_conversation_sla',
        type: 'rules_filters_apply_conversation_sla',
        icon: 'sla',
        description: this.intl.t(
          'settings.inbox-rules.index.filter.attribute.apply-sla.description',
        ),
      },
    ];
  }),

  _availabilityAttributes: [
    {
      name: 'Office hours',
      identifier: 'office_hours.schedule',
      type: 'office_hours_schedule',
      icon: 'clock',
      description: 'During or outside of a chosen office hours schedule',
    },
  ],

  _outboundSupportConversationAttributes: [
    {
      name: 'Started via Inbox',
      identifier: 'message.outbound_from_inbox',
      type: 'boolean',
      icon: 'compose',
      description: 'Conversation was started by a teammate in Inbox',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
    },
  ],

  _outboundInitiatorConversationAttributes: computed(function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Conversation starter',
            identifier: 'conversation.conversation_started_by',
            type: 'conversation_started_by',
            icon: 'new-direct-message',
          },
          {
            name: 'Created via API',
            identifier: 'conversation.created_via_api',
            type: 'boolean',
            icon: 'custom-data',
            description: 'Conversations created using Intercom’s API',
          },
        ];
  }),

  _conversationStateAttributes: computed(function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Conversation state',
            identifier: 'conversation.conversation_state',
            type: 'conversation_state',
            icon: 'active',
            description: 'The state of the conversation (open, closed or snoozed)',
          },
        ];
  }),

  _conversationTopicAttributes: computed(function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Topic',
            identifier: 'conversation.topics',
            type: 'conversation_topic',
            icon: 'insights',
            description: 'Conversation topics',
          },
        ];
  }),

  _conversationTagAttributes: [
    {
      name: 'Conversation tag',
      identifier: 'conversation.tag_ids',
      type: 'manual_tag',
      icon: 'tag',
      description: "A group a conversation belongs to, based on a tag you've applied to them",
    },
  ],
  _participantConversationAttributes: computed(
    'appService.app.canUseCSATRequestedForPredicate',
    'intl.locale',
    function () {
      if (this.app?.canUseFinStandalone) {
        return [];
      }

      let attributes = [
        {
          name: 'Is group conversation',
          identifier: 'conversation.is_group_conversation',
          type: 'boolean',
          icon: 'multiple-people',
        },
        {
          name: 'Teammate has replied',
          identifier: 'conversation.admin_has_replied',
          type: 'boolean',
          icon: 'reply',
        },
        {
          name: 'Last customer reply',
          identifier: 'conversation.user_last_replied_at',
          type: 'date',
          icon: 'clock',
        },
        {
          name: 'Last teammate reply',
          identifier: 'conversation.admin_last_replied_at',
          type: 'date',
          icon: 'clock',
        },
        {
          name: 'Total typed characters',
          identifier: 'conversation.total_visible_typed_characters',
          type: 'integer',
          description:
            'Total characters in all teammate and customer messages (but not bot messages or notes)',
          icon: 'count',
        },
        {
          name: 'Conversation rating requested',
          identifier: 'conversation.rating_requested',
          type: 'boolean',
          description:
            'A conversation rating request has been sent to the customer (but not necessarily responded to)',
          icon: 'lwr-happy',
        },
      ];

      if (this.appService.app?.canUseCSATRequestedForPredicate) {
        attributes.push({
          name: this.intl.t(
            'common.predicates.participant-conversation-attributes.csat-rating-requested-for-agent.attribute-name',
          ),
          identifier: 'conversation.rating_requested_for_actors',
          type: 'rating_requested_for_actor',
          description: this.intl.t(
            'common.predicates.participant-conversation-attributes.csat-rating-requested-for-agent.description',
          ),
          icon: 'lwr-happy',
        });
      }

      return attributes;
    },
  ),

  _ticketAttributes: computed(function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Ticket type',
            identifier: 'conversation.ticket_type_id',
            type: 'ticket_type_id',
            icon: 'ticket',
            description: 'The type of ticket',
            category: 'ticket',
          },
        ];
  }),

  _ticketStateAttribute: computed('intl.locale', function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Ticket state category',
            identifier: 'conversation.ticket_state',
            type: 'ticket_state',
            icon: 'ticket',
            description: this.intl.t('settings.ticket-data.ticket-state-category-unsupported'),
            category: 'ticket',
          },
        ];
  }),

  _ticketCustomStateAttribute: computed(function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Ticket state',
            identifier: 'conversation.custom_state_id',
            type: 'custom_state_id',
            icon: 'ticket',
            description: 'State of the ticket',
            category: 'ticket',
          },
        ];
  }),

  _optionalConversationTagAttribute: computed('_conversationTagAttributes', function () {
    return [
      {
        ...this._conversationTagAttributes[0],
        type: 'optional_manual_tag',
      },
    ];
  }),

  _createdByAttribute: [
    {
      name: 'Creator',
      identifier: 'conversation.custom_attribute.created_by_teammate_id',
      type: 'admin_id',
      icon: 'person',
      description: 'Created by',
      adminType: 'teammate',
    },
  ],

  _capacityAttributes: computed('intl.locale', function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: this.intl.t('common.predicates.team-capacity.attribute-name'),
            identifier: 'team_capacity',
            type: 'team_capacity',
            icon: 'team',
            description: this.intl.t('common.predicates.team-capacity.description'),
            adminType: 'team',
          },
        ];
  }),

  _finUsageAttributes: computed('intl.locale', function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: this.intl.t('common.predicates.ai-agent.limit-reached.attribute-name'),
            identifier: 'ai_agent.limit_reached',
            type: 'boolean',
            icon: 'fin',
            description: this.intl.t('common.predicates.ai-agent.limit-reached.description'),
          },
        ];
  }),

  assigneeAttributes: computed('app.canAssignToTeamAndTeammate', function () {
    let attributes = [];

    if (this.app?.canAssignToTeamAndTeammate && !this.app?.canUseFinStandalone) {
      attributes.push({
        name: 'Teammate assigned',
        identifier: 'conversation.admin_assignee_id',
        type: 'admin_assignee_id',
        icon: 'person',
        description: 'The teammate to whom the conversation is assigned',
        adminType: 'teammate',
        category: null,
      });

      attributes.push({
        name: 'Team assigned',
        identifier: 'conversation.team_assignee_id',
        type: 'team_assignee_id',
        icon: 'team',
        description: 'The team to whom the conversation is assigned',
        adminType: 'team',
        category: null,
      });
    }
    return attributes;
  }),

  nonCustomConversationAttributes: computed(
    'app.canUseInboxSlas',
    'app.canAssignToTeamAndTeammate',
    'app.hasConversationalInsightsBillingFeature',
    'inboxVersion.version',
    function () {
      let attributes = [];

      if (this.app?.canUseFinStandalone) {
        return attributes;
      }

      if (this.app?.canAssignToTeamAndTeammate) {
        attributes.push({
          name: 'Teammate assigned',
          identifier: 'conversation.admin_assignee_id',
          type: 'admin_assignee_id',
          icon: 'person',
          description: 'The teammate to whom the conversation is assigned',
          adminType: 'teammate',
          crm_readable: true,
        });

        attributes.push({
          name: 'Team assigned',
          identifier: 'conversation.team_assignee_id',
          type: 'team_assignee_id',
          icon: 'team',
          description: 'The team to whom the conversation is assigned',
          adminType: 'team',
          crm_readable: true,
        });
      } else {
        attributes.push({
          name: 'Assignee',
          identifier: 'conversation.assignee_id',
          type: 'owner_id',
          icon: 'person',
          description: 'The teammate or team to whom the conversation is assigned',
          crm_readable: true,
        });
      }

      attributes.push({
        name: 'Conversation tag',
        identifier: 'conversation.tag_ids',
        type: 'manual_tag',
        icon: 'tag',
        description: "A group a conversation belongs to, based on a tag you've applied to them",
        crm_readable: true,
      });

      attributes.push({
        name: 'Priority',
        identifier: 'conversation.priority',
        type: 'priority',
        icon: 'star',
        description: 'Whether or not the conversation has been marked as priority',
        crm_readable: true,
      });

      attributes.push({
        name: 'SLA due in',
        identifier: 'conversation.next_breach_time',
        type: 'datetime',
        icon: 'sla',
        description: 'The time remaining until an SLA is due',
        direction: 'future',
        crm_readable: true,
      });

      attributes.push({
        name: 'Waiting for teammate reply',
        identifier: 'conversation.waiting_since',
        type: 'datetime',
        icon: 'clock',
        description: 'The time a person has been waiting for a teammate reply',
        direction: 'past',
        crm_readable: true,
      });

      attributes.push({
        name: 'Created',
        identifier: 'conversation.conversation_started_at',
        type: 'datetime',
        icon: 'clock',
        description:
          'The time since the conversation started. Conversations start when a customer sends an inbound message or responds to an outbound message',
        direction: 'past',
        suffix: 'ago',
        crm_readable: true,
      });

      attributes.push({
        name: 'Current channel',
        identifier: 'conversation.current_channel',
        type: 'channel',
        icon: 'multiplatform',
        description: 'The channel the customer is currently using',
      });

      attributes.push({
        name: 'Initial channel',
        identifier: 'conversation.initial_channel',
        type: 'channel',
        icon: 'multiplatform',
        description: 'The channel the conversation started on',
      });

      if (this.inboxVersion.isInbox2) {
        attributes.push({
          name: 'Fin AI Agent involved',
          identifier: 'conversation.fin_involved',
          type: 'boolean',
          icon: 'ai',
        });

        attributes.push({
          name: 'Fin AI Agent answers enabled',
          identifier: 'conversation.fin_ai_answers_enabled',
          type: 'boolean',
          icon: 'ai',
        });
      }

      if (this.inboxVersion.isInbox2) {
        attributes = attributes.concat(this._ticketAttributes);
      }

      attributes = attributes.concat(this._ticketStateAttribute);
      attributes = attributes.concat(this.systemDefinedConversationAttributesWithoutCategory);
      attributes = attributes.concat(this.systemDefinedTicketAttributes);
      attributes = attributes.concat(this._createdByAttribute);

      return attributes;
    },
  ),
  systemDefinedConversationAttributesWithoutCategory: filterBy(
    '_nonArchivedConversationCustomAttributes',
    'category',
    null,
  ),
  systemDefinedTicketAttributes: filterBy(
    '_nonArchivedConversationCustomAttributes',
    'category',
    'ticket',
  ),
  nonArchivedConversationCustomAttributes: filterBy(
    '_nonArchivedConversationCustomAttributes',
    'category',
    'conversation',
  ),
  _nonArchivedConversationCustomAttributes: filterBy(
    'conversationCustomAttributes',
    'archived',
    false,
  ),
  nonArchivedNonSystemDefinedConversationCustomAttributes: rejectBy(
    'nonArchivedConversationCustomAttributes',
    'systemDefined',
    true,
  ),
  nonArchivedEditableConversationCustomAttributes: filterBy(
    'nonArchivedConversationCustomAttributes',
    'editable',
    true,
  ),
  archivedConversationCustomAttributes: filterBy('conversationCustomAttributes', 'archived', true),
  conversationCustomAttributes: computed(
    'conversationAttributeDescriptors',
    'conversationAttributeDescriptors.@each.id',
    'conversationAttributeDescriptors.@each.isTicketDescriptor',
    function () {
      // Hide ticket type descriptors until we start conversation re-using conversation attribute values
      return this.conversationAttributeDescriptors
        .rejectBy('isTicketDescriptor', true)
        .rejectBy('isRelationshipDataType', true)
        .rejectBy('isCreatedByDescriptor', true)
        .map(this._conversationAttributeDescriptorToAttribute.bind(this));
    },
  ),
  nonArchivedConversationFileCustomAttributesWithS3Key: computed(
    'conversationAttributeDescriptors',
    'conversationAttributeDescriptors.@each.id',
    'conversationAttributeDescriptors.@each.isTicketDescriptor',
    function () {
      return this.conversationAttributeDescriptors
        .filterBy('isFileDataType', true)
        .rejectBy('isTicketDescriptor', true)
        .rejectBy('archived', true)
        .map(this._conversationFileAttributeDescriptorToAttributeWithS3KeyIdentifier.bind(this));
    },
  ),

  webhookReceivedAttributeDescriptors: computed(function () {
    return this.store.peekAll('workflow-connector/attribute-descriptor');
  }),

  webhookReceivedAttributes: computed(
    'webhookReceivedAttributeDescriptors',
    'webhookReceivedAttributeDescriptors.@each.id',
    function () {
      return this.webhookReceivedAttributeDescriptors.map(
        this._webhookReceivedAttributeDescriptorToAttribute.bind(this),
      );
    },
  ),

  _webhookReceivedAttributeDescriptorToAttribute(descriptor) {
    let { name, description, attributeIdentifier } = descriptor;

    let attribute = this.store.createRecord('attribute', {
      id: attributeIdentifier,
      name,
      identifier: attributeIdentifier,
      type: this._constractPredicateDataType(descriptor.dataType),
      icon: 'transfer',
      description,
      templatableIdentifier: attributeIdentifier,
    });

    return attribute;
  },
  conversationReferenceAttributes: computed(
    'conversationAttributeDescriptors',
    'conversationAttributeDescriptors.@each.isTicketDescriptor',
    function () {
      // Hide ticket type descriptors until we start conversation re-using conversation attribute values
      return this.conversationAttributeDescriptors
        .rejectBy('isTicketDescriptor', true)
        .filterBy('isRelationshipDataType', true)
        .map(this._conversationAttributeDescriptorToAttribute.bind(this));
    },
  ),
  userAndConversationRelationshipAttributes: union(
    'userRelationshipAttributes',
    'conversationReferenceAttributes',
  ),
  customActionConversationAttributes: [
    {
      name: 'Workspace ID',
      identifier: 'app.id',
      type: 'integer',
      icon: 'conversation',
      description: 'Workspace ID',
      displayable: true,
      templatable_identifier: 'app.id',
      noFallback: true,
    },
    {
      name: 'Contact ID',
      identifier: 'user.id',
      type: 'integer',
      icon: 'conversation',
      description: 'Contact Id',
      displayable: true,
      templatable_identifier: 'user.id',
      noFallback: true,
    },
    {
      name: 'Conversation ID',
      identifier: 'conversation.id',
      type: 'integer',
      icon: 'conversation',
      description: 'Conversation Id',
      displayable: true,
      templatable_identifier: 'conversation.id',
      noFallback: true,
    },
    {
      name: 'Conversation URL',
      identifier: 'conversation.url',
      type: 'string',
      icon: 'conversation',
      description: 'Conversation URL',
      displayable: true,
      templatable_identifier: 'conversation.url',
      noFallback: true,
    },
    {
      name: 'Conversation Part ID',
      identifier: 'conversation_part.id',
      type: 'integer',
      icon: 'conversation',
      description: 'Conversation Part ID',
      displayable: true,
      templatable_identifier: 'conversation_part.id',
      noFallback: true,
    },
    {
      name: 'Conversation Part Body',
      identifier: 'conversation_part.body',
      type: 'string',
      icon: 'conversation',
      description: 'Conversation Part Body',
      displayable: true,
      templatable_identifier: 'conversation_part.body',
      noFallback: true,
    },
    {
      name: 'First Message ID',
      identifier: 'initial_part.id',
      type: 'integer',
      icon: 'conversation',
      description: 'First Message ID',
      displayable: true,
      templatable_identifier: 'initial_part.id',
      noFallback: true,
    },
    {
      name: 'First Message Body',
      identifier: 'initial_part.body',
      type: 'string',
      icon: 'conversation',
      description: 'First Message Body',
      displayable: true,
      templatable_identifier: 'initial_part.body',
      noFallback: true,
    },
    {
      name: 'Last Message ID',
      identifier: 'last_conversation_part.id',
      type: 'integer',
      icon: 'conversation',
      description: 'Last Message ID',
      displayable: true,
      templatable_identifier: 'last_conversation_part.id',
      noFallback: true,
    },
    {
      name: 'Last Message Body',
      identifier: 'last_conversation_part.body',
      type: 'string',
      icon: 'conversation',
      description: 'Last Message Body',
      displayable: true,
      templatable_identifier: 'last_conversation_part.body',
      noFallback: true,
    },
    {
      name: 'Message Body',
      identifier: 'message.body',
      type: 'string',
      icon: 'conversation',
      description: 'Message Body',
      displayable: true,
      templatable_identifier: 'message.body',
      noFallback: true,
    },
    {
      name: 'Message Author Email',
      identifier: 'message.author.email',
      type: 'string',
      icon: 'conversation',
      description: 'Message Author Email',
      displayable: true,
      templatable_identifier: 'message.author.email',
      noFallback: true,
    },
    {
      name: 'Assignee ID',
      identifier: 'conversation.assignee_id',
      type: 'owner_id',
      icon: 'person',
      description: 'The teammate or team to whom the conversation is assigned',
      displayable: true,
      templatable_identifier: 'conversation.assignee_id',
      noFallback: true,
    },
  ],
  customActionTicketAttributes: computed('intl.locale', function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Ticket Title',
            identifier: 'ticket.title',
            type: 'string',
            icon: 'ticket',
            description: 'The title of the ticket',
            displayable: true,
            templatable_identifier: 'ticket.title',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: 'Ticket Description',
            identifier: 'ticket.description',
            type: 'string',
            icon: 'ticket',
            description: 'The description of the ticket',
            displayable: true,
            templatable_identifier: 'ticket.description',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: 'Ticket Assignee ID',
            identifier: 'ticket.assignee.id',
            type: 'owner_id',
            icon: 'person',
            description: 'The teammate or team to whom the ticket is assigned',
            displayable: true,
            templatable_identifier: 'ticket.assignee.id',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: 'Ticket Assignee Firstname',
            identifier: 'ticket.assignee.first_name',
            type: 'string',
            icon: 'person',
            description: 'The name of the teammate or team to whom the ticket is assigned',
            displayable: true,
            templatable_identifier: 'ticket.assignee.first_name',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: 'Ticket Type ID',
            identifier: 'ticket.ticket_type_id',
            type: 'integer',
            icon: 'ticket',
            description: 'The ID of the ticket type',
            displayable: true,
            templatable_identifier: 'ticket.ticket_type_id',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: 'Ticket Linked Conversation ID',
            identifier: 'ticket.conversation_id',
            type: 'integer',
            icon: 'ticket',
            description: 'The ID of the conversation the ticket was created from',
            displayable: true,
            templatable_identifier: 'ticket.conversation_id',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-external-label',
            ),
            identifier: 'ticket.current_status.title',
            type: 'string',
            icon: 'ticket',
            description: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-external-label-description',
            ),
            displayable: true,
            templatable_identifier: 'ticket.current_status.title',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: this.intl.t('services.attribute-service.ticket-attributes.ticket-state-id'),
            identifier: 'ticket.current_status.ticket_custom_state_id',
            type: 'integer',
            icon: 'ticket',
            description: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-id-description',
            ),
            displayable: true,
            templatable_identifier: 'ticket.current_status.ticket_custom_state_id',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: this.intl.t('services.attribute-service.ticket-attributes.ticket-state-category'),
            identifier: 'ticket.current_status.type',
            type: 'string',
            icon: 'ticket',
            description: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-category-description',
            ),
            displayable: true,
            templatable_identifier: 'ticket.current_status.type',
            noFallback: true,
            category: 'ticket',
          },
          {
            name: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-internal-label',
            ),
            identifier: 'ticket.current_status.internal_title',
            type: 'string',
            icon: 'ticket',
            description: this.intl.t(
              'services.attribute-service.ticket-attributes.ticket-state-internal-label-description',
            ),
            displayable: true,
            templatable_identifier: 'ticket.current_status.internal_title',
            noFallback: true,
            category: 'ticket',
          },
        ];
  }),
  _customActionTicketLinkedConversationAttributes: [
    {
      name: 'Ticket Conversation Transcript',
      identifier: 'ticket.transcript',
      type: 'string',
      icon: 'ticket',
      description: 'The conversation transcript of the ticket',
      displayable: true,
      templatable_identifier: 'ticket.transcript',
      noFallback: true,
      featureFlag: 'ticket-conversation-transcript',
    },
  ],
  conversationAttributesForCustomAction: computed(
    'nonArchivedConversationCustomAttributes',
    'customActionConversationAttributes',
    function () {
      return [
        ...this.nonArchivedConversationCustomAttributes,
        ...this.customActionConversationAttributes,
      ].reject((attribute) => attribute.type === 'files');
    },
  ),
  ticketAttributesForCustomAction: computed(
    'app.features.[]',
    'customActionTicketAttributes',
    '_customActionTicketLinkedConversationAttributes',
    function () {
      let filteredLinkedConversationAttributes =
        this._customActionTicketLinkedConversationAttributes.filter((attribute) => {
          return attribute.featureFlag ? this.app?.canUseFeature(attribute.featureFlag) : true;
        });

      return this.customActionTicketAttributes.concat(filteredLinkedConversationAttributes);
    },
  ),
  conversationAttributes: computed(
    'nonCustomConversationAttributes',
    'conversationCustomAttributes',
    function () {
      return this.nonCustomConversationAttributes.concat(this.conversationCustomAttributes);
    },
  ),
  inboxViewsAttributes: computed('nonCustomConversationAttributes', function () {
    return this.nonCustomConversationAttributes.map((attribute) => {
      if (attribute.identifier === 'conversation.ticket_state') {
        return this._ticketCustomStateAttribute[0];
      } else if (attribute.identifier === 'conversation.tag_ids') {
        return this._optionalConversationTagAttribute[0];
      } else {
        return attribute;
      }
    });
  }),
  ticketTypeAttributes: computed(
    'conversationAttributeDescriptors',
    'conversationAttributeDescriptors.@each.isTicketDescriptor',
    function () {
      return this.conversationAttributeDescriptors
        .filterBy('isTicketDescriptor')
        .map((desc) => this._conversationAttributeDescriptorToAttribute(desc));
    },
  ),
  archivedTicketTypeAttributes: filterBy('ticketTypeAttributes', 'archived', true),
  nonArchivedTicketTypeAttributes: filterBy('ticketTypeAttributes', 'archived', false),
  ticketTypes: computed(function () {
    return this.store.peekAll('inbox/ticket-type');
  }),
  nonArchivedTicketTypes: filterBy('ticketTypes', 'archived', false),

  _crmConversationAttributes: computed('conversationAttributes', function () {
    let attributes = [
      {
        name: 'State',
        identifier: 'conversation.state',
        type: 'string',
        archived: false,
        editable: false,
        crm_readable: true,
      },
      {
        name: 'Title',
        identifier: 'conversation.title',
        type: 'string',
        archived: false,
        editable: false,
        crm_readable: true,
      },
      {
        name: 'Read',
        identifier: 'conversation.read',
        type: 'boolean',
        archived: false,
        editable: false,
        crm_readable: true,
      },
    ];

    this.conversationAttributes.forEach((conversationAttribute) => {
      if (conversationAttribute.type !== 'files') {
        attributes.push({
          name: conversationAttribute.name,
          identifier: conversationAttribute.identifier.includes('conversation.custom_attribute')
            ? `conversation.custom_attributes.${conversationAttribute.name}`
            : conversationAttribute.identifier,
          type: conversationAttribute.type,
          archived: false,
          editable: false,
          crm_readable: true,
        });
      }
    });

    return attributes;
  }),
  crmConversationAttributes: createAttributeModels('_crmConversationAttributes'),

  _constractPredicateDataType(originalDataType) {
    let mappedTypes = Object.keys(CONVERSATION_ATTRIBUTE_DESCRIPTOR_MAPPED_TYPES);

    let predicateDataType;
    if (mappedTypes.includes(originalDataType)) {
      predicateDataType = CONVERSATION_ATTRIBUTE_DESCRIPTOR_MAPPED_TYPES[originalDataType];
    } else {
      predicateDataType = originalDataType;
    }
    return predicateDataType;
  },

  _conversationAttributeDescriptorToAttribute(descriptor) {
    let {
      name,
      description,
      id,
      templatable,
      archived,
      listOptions,
      relationship,
      reference,
      systemDefined,
      editable,
      hideFromAutomation,
      hideFromReporting,
      icon,
      category,
      entityTypes,
    } = descriptor;

    let identifier = `conversation.custom_attribute.${id}`;

    let attribute = this.store.createRecord('attribute', {
      name,
      identifier,
      type: this._constractPredicateDataType(descriptor.dataType),
      icon: icon || 'transfer',
      description,
      archived,
      templatableIdentifier: identifier,
      templatable,
      relationship,
      reference,
      cda_id: id,
      systemDefined,
      editable,
      hideFromAutomation,
      hideFromReporting,
      category,
      entityTypes,
    });

    if (descriptor.dataType === 'list' && listOptions) {
      listOptions.forEach(({ id, label, archived }) =>
        attribute.options.createRecord({
          identifier: id,
          value: label,
          archived,
        }),
      );
    }

    return attribute;
  },
  _conversationFileAttributeDescriptorToAttributeWithS3KeyIdentifier(descriptor) {
    // Used to create a set of attributes for Conversation File Custom Attributes.
    // Currently, this is only creating attributes with an S3 key identifier to
    // enable templating of the S3 key of the file upload but can be extended
    // to add other identifier suffixes e.g. file_name in future

    let {
      name,
      description,
      id,
      templatable,
      archived,
      relationship,
      reference,
      systemDefined,
      editable,
      hideFromAutomation,
      hideFromReporting,
      icon,
      category,
      entityTypes,
    } = descriptor;

    let identifier = `conversation.custom_attribute.${id}.s3_key`;

    let attribute = this.store.createRecord('attribute', {
      name,
      identifier,
      type: descriptor.dataType,
      icon: icon || 'transfer',
      description,
      archived,
      templatableIdentifier: identifier,
      templatable,
      relationship,
      reference,
      cda_id: id,
      systemDefined,
      editable,
      hideFromAutomation,
      hideFromReporting,
      category,
      entityTypes,
    });

    return attribute;
  },
  crmAttributes: computed('attributes.[]', function () {
    return this.attributes.filter((attribute) => attribute.crm_readable || attribute.crm_writable);
  }),
  crmUserAttributes: rejectBy('crmAttributes', 'isCompany', true),
  crmCompanyAttributes: filterBy('crmAttributes', 'isCompany', true),

  articlesReportingAttributes: computed('intl.locale', 'app', function () {
    return [
      {
        name: this.intl.t('reporting.articles.engagement.viewers'),
        identifier: 'articles.reporting.view.count',
        type: 'integer',
        description: this.intl.t('reporting.articles.engagement.filters.viewers.description'),
        icon: 'visible',
      },
      {
        name: this.intl.t('reporting.articles.engagement.filters.positive-reactions.name'),
        identifier: 'articles.reporting.view.positive_reactions',
        type: 'integer',
        description: this.intl.t(
          'reporting.articles.engagement.filters.positive-reactions.description',
        ),
        icon: 'lwr-happy',
      },
      {
        name: this.intl.t('reporting.articles.engagement.filters.negative-reactions.name'),
        identifier: 'articles.reporting.view.negative_reactions',
        type: 'integer',
        description: this.intl.t(
          'reporting.articles.engagement.filters.negative-reactions.description',
        ),
        icon: 'lwr-sad',
      },
      {
        name: this.intl.t('reporting.articles.engagement.filters.neutral-reactions.name'),
        identifier: 'articles.reporting.view.neutral_reactions',
        type: 'integer',
        description: this.intl.t(
          'reporting.articles.engagement.filters.neutral-reactions.description',
        ),
        icon: 'lwr-neutral',
      },
      {
        name: this.intl.t('reporting.articles.engagement.filters.conversations.name'),
        identifier: 'articles.reporting.view.conversations',
        type: 'integer',
        description: this.intl.t('reporting.articles.engagement.conversations-tooltip-number'),
        icon: 'conversation',
      },
    ];
  }),
  allAttributes: uniq(
    'attributes',
    'visitorMessagingAttributes',
    'assignmentRulesMessageAttributesAsAttributes',
    'eventMetadataAttributes',
    'tourClientPredicates',
    'conversationAttributes',
    'availabilityAttributes',
    'workflowFilterAttributes',
    'outboundSupportConversationAttributes',
    'outboundInitiatorConversationAttributes',
    'participantConversationAttributes',
    'ticketAttributes',
    'ticketStateAttributes',
    'customObjectsAttributeDescriptors',
    'ticketTypeAttributes',
    'articlesReportingAttributes',
    'capacityAttributes',
    'customActionConversationAttributes',
    'customActionTicketAttributes',
    'conversationStateAttributes',
    'conversationTopicAttributes',
    'contentServiceDateFilterAttributes',
    'webhookReceivedAttributes',
    'createdByAttribute',
    'inboxViewsAttributes',
    'finUsageAttributes',
    'brandNameAttribute',
    'standaloneZendeskBrandAttribute',
  ),

  _tourGoalClientPredicatesData: computed('intl.locale', function () {
    return [
      {
        name: 'Page visited',
        goalName: 'Page URL visited',
        icon: 'link',
        identifier: 'client_attributes.last_visited_url',
        type: 'string',
        description: 'The specific page a user last viewed on your app or site',
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
    ];
  }),

  _tourClientPredicatesData: computed('intl.locale', function () {
    return [
      {
        name: this.intl.t('services.attribute-service.last-visited-url-name'),
        goalName: this.intl.t('services.attribute-service.last-visited-url-goal-name'),
        icon: 'link',
        identifier: 'client_attributes.last_visited_url',
        type: 'string',
        description: this.intl.t('services.attribute-service.last-visited-url-description'),
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
    ];
  }),

  _clientVamAttributesData: computed('intl.locale', function () {
    return this.app?.canUseFinStandalone
      ? []
      : [
          {
            name: 'Current page URL',
            identifier: 'last_visited_url',
            type: 'string',
            description: 'The specific page a visitor last viewed on your app or site',
            filterable: true,
            sortable: false,
            usable_in_message_goal: false,
            displayable: true,
          },
          {
            name: this.intl.t('services.attribute-service.time-on-current-page-name'),
            identifier: 'client_attributes.time_on_page',
            type: 'duration_integer',
            description: this.intl.t('services.attribute-service.time-on-current-page-description'),
            filterable: true,
            sortable: false,
            usable_in_message_goal: false,
            displayable: true,
          },
          {
            name: 'Single page views',
            identifier: 'url_visits',
            type: 'url_visit',
            description: 'The number of times someone has viewed a single page on your app or site',
            filterable: true,
            sortable: false,
            usable_in_message_goal: false,
            displayable: true,
          },
          {
            name: this.intl.t('services.attribute-service.total-visits-name'),
            identifier: 'visit_count',
            type: 'integer',
            description: this.intl.t('services.attribute-service.total-visits-description'),
            filterable: true,
            sortable: true,
            usable_in_message_goal: true,
            displayable: true,
          },
        ];
  }),
  _newVamPredicatesData: [
    {
      name: 'Total page views',
      identifier: 'total_url_visits',
      type: 'integer',
      description:
        'The total number of pages someone has viewed on your app or site, including multiple views of any page',
      filterable: true,
      sortable: true,
      usable_in_message_goal: false,
      displayable: true,
    },
    {
      name: 'Over-messaging protection',
      identifier: 'over_messaging_protection',
      type: 'over_messaging_protection',
      description: "Exclude visitors who've received or sent a message in the past day",
      icon: 'flag',
      filterable: true,
      sortable: true,
      usable_in_message_goal: false,
      displayable: true,
    },
  ],
  _newAbmPredicatesData: computed('intl.locale', function () {
    return [
      {
        name: 'Owner',
        identifier: 'owner_id',
        type: 'owner_id',
        description: 'The teammate that owns a lead or user in Intercom',
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
      {
        name: 'Account',
        identifier: 'account_id',
        type: 'account_id',
        description: this.intl.t('reporting.articles.engagement.viewers'),
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
    ];
  }),
  _brandNameAttribute: [
    {
      name: 'Brand',
      identifier: 'brand.name',
      type: 'brand_name',
      icon: 'company',
    },
  ],
  _standaloneZendeskBrandAttribute: [
    {
      name: 'Zendesk brand',
      identifier: 'conversation.custom_attribute.standalone_zendesk_brand',
      description: 'Zendesk brand',
      type: 'string',
      icon: 'transfer',
      displayable: true,
      filterable: true,
    },
  ],
  filterableUserAttributes: computed('filterableAttributesWithoutRoleOrType', function () {
    return this.filterableAttributesWithoutRoleOrType.rejectBy('isUser', false);
  }),
  botAutoMessageCollectableAttributes: computed(
    'nonArchivedAttributes',
    'nonArchivedConversationCustomAttributes',
    'app.canUseSMS',
    'app.canUseCollectableDatetimeAttributes',
    function () {
      if (this.get('app.canUseSMS')) {
        this.collectableStandardAttributes.push('sms_consent');
      }
      let qualificationAttributeIdentifiers = this.qualificationAttributeIdentifiers;
      return this.nonArchivedAttributes
        .rejectBy('type', 'date')
        .rejectBy('isUserEvent', true)
        .filter((attribute) => {
          let identifier = attribute.get('identifier');
          return (
            attribute.isCustomData ||
            attribute.isCompanyCustomData ||
            qualificationAttributeIdentifiers.includes(identifier) ||
            this.collectableStandardAttributes.includes(identifier)
          );
        })
        .concat(
          this.nonArchivedConversationCustomAttributes
            .reject(
              (attribute) =>
                attribute.get('type') === 'datetime' &&
                !this.get('app.canUseCollectableDatetimeAttributes'),
            )
            .filter((attribute) => !attribute.get('systemDefined')),
        );
    },
  ),
  botAutoMessageCollectableAttributeGroupList: computed(
    'botAutoMessageCollectableAttributes',
    function () {
      return this.attributesToGroupList(this.botAutoMessageCollectableAttributes);
    },
  ),
  textOnlyBotAutoMessageCollectableAttributes: computed(
    'botAutoMessageCollectableAttributes',
    function () {
      return this.botAutoMessageCollectableAttributes.filter((attr) => {
        let isNotList = attr.get('options.length') === 0;
        let isString = attr.get('type') === 'string';
        let isBlocked = ['email', 'phone', 'company.website'].includes(attr.get('identifier'));
        return isNotList && isString && !isBlocked;
      });
    },
  ),
  textOnlyBotAutoMessageCollectableAttributesGroupList: computed(
    'textOnlyBotAutoMessageCollectableAttributes',
    function () {
      return this.attributesToGroupList(this.textOnlyBotAutoMessageCollectableAttributes);
    },
  ),
  zendeskSunshineConversationCollectableAttributes: computed(
    'botAutoMessageCollectableAttributes',
    function () {
      return this.botAutoMessageCollectableAttributes.filter((attr) => {
        let isString = attr.get('type') === 'string';
        let isList = attr.get('options.length') > 0;
        let isCustomNumber =
          attr.get('type') === 'integer' && (attr.isCustomData || attr.isConversation);
        let isSupportedAttribute = ['email'].includes(attr.get('identifier'));
        return isString || isList || isCustomNumber || isSupportedAttribute;
      });
    },
  ),
  zendeskSunshineConversationCollectableAttributeGroupList: computed(
    'zendeskSunshineConversationCollectableAttributes',
    function () {
      return this.attributesToGroupList(this.zendeskSunshineConversationCollectableAttributes);
    },
  ),
  botAutoMessageAttributes: uniq(
    'attributes',
    'visitorMessagingAttributes',
    'assignmentRulesMessageAttributesAsAttributes',
  ),
  customBotFollowupActionAttributes: computed(
    'botAutoMessageAttributes',
    'nonArchivedConversationCustomAttributes',
    function () {
      return this.botAutoMessageAttributes
        .reject((attribute) => attribute.get('isTimeOnPage'))
        .rejectBy('identifier', 'message.content')
        .rejectBy('identifier', 'role')
        .concat(this.nonArchivedConversationCustomAttributes);
    },
  ),
  customBotFollowupActionAttributesGroupList: computed(
    'customBotFollowupActionAttributes',
    function () {
      return this.attributesToGroupList(this.customBotFollowupActionAttributes);
    },
  ),

  surveyUpdatableAttributeGroupList: computed('nonArchivedAttributes', function () {
    let attributes = this.nonArchivedAttributes
      .rejectBy('isUserEvent', true)
      .rejectBy('isCompany', true)
      .filter((attribute) => {
        let identifier = attribute.get('identifier');
        return (
          attribute.isCustomData ||
          this.qualificationAttributeIdentifiers.includes(identifier) ||
          this.collectableStandardAttributes.includes(identifier)
        );
      });
    return [{ heading: 'People data', attributes }];
  }),
  surveyActionUrlComposerAttributes: rejectBy('app.allowedAttributes', 'isUserEvent', true),
  checklistsActionUrlComposerAttributes: rejectBy('app.allowedAttributes', 'isUserEvent', true),
  visitorMessagingAttributesNoPageTargetting: computed(
    'visitorMessagingAttributes.@each.{isTimeOnPage,isPageTarget}',
    function () {
      return this.visitorMessagingAttributes.reject((a) => a.isTimeOnPage || a.isPageTarget);
    },
  ),
  visitorMessagingAttributes: computed(
    'filterableVisitorAttributesForMessaging',
    'clientVamAttributes',
    'app.hasAccountMarketingBillingFeature',
    'app.canUseNewVamPredicates',
    'newVamPredicates',
    'newAbmPredicates',
    function () {
      let vamAttributes = this.filterableVisitorAttributesForMessaging.concat(
        this.clientVamAttributes,
      );
      if (this.get('app.canUseNewVamPredicates')) {
        vamAttributes = vamAttributes.concat(this.newVamPredicates);
      }
      if (this.get('app.hasAccountMarketingBillingFeature')) {
        vamAttributes = vamAttributes.concat(this.newAbmPredicates);
      }
      vamAttributes = vamAttributes.filter((attribute) => attribute.get('tracked'));
      return vamAttributes.uniqBy('identifier');
    },
  ),

  messengerConditionalDisplayAttributes: computed(
    'clientVamAttributes',
    'filterableVisitorAttributesForMessaging',
    function () {
      return this.clientVamAttributes
        .concat(this.filterableVisitorAttributesForMessaging)
        .reject((attribute) => attribute.get('isTimeOnPage'));
    },
  ),

  additionalAttributesForLeadsAndVisitors: computed('clientVamAttributes', function () {
    return this.clientVamAttributes.reject((attribute) => attribute.get('isTimeOnPage'));
  }),

  vamEditorAttributes: computed(
    'attributes',
    'filterableVisitorAttributesForMessaging',
    'abmTemplatableAttributes',
    'app.hasAccountMarketingBillingFeature',
    function () {
      if (!this.attributes) {
        return [];
      }
      let editorAttributes = this.attributes
        .filterBy('isApp')
        .concat(this.filterableVisitorAttributesForMessaging);

      if (this.get('app.hasAccountMarketingBillingFeature')) {
        editorAttributes = editorAttributes.concat(
          this.attributes.filter((attribute) =>
            this.abmTemplatableAttributes.includes(attribute.identifier),
          ),
        );
      }

      return editorAttributes;
    },
  ),

  greetingComposerAttributes: rejectBy('userAttributesOnly', 'isUserEvent', true),
  tourUrlComposerAttributes: rejectBy('app.allowedAttributes', 'isUserEvent', true),
  bannerActionUrlComposerAttributes: rejectBy('app.allowedAttributes', 'isUserEvent', true),
  userEditorAttributes: ternaryToProperty(
    'showCompanyAttributes',
    'attributes',
    'userAttributesOnly',
  ),
  userEditorAndMetadataAttributes: uniq('userEditorAttributes', 'eventMetadataAttributes'),
  appAllowedAndConversationAttributes: uniq(
    'app.allowedAttributes',
    'nonArchivedConversationCustomAttributes',
  ),
  nonArchivedConversationCustomAttributesWithoutFile: computed(
    'nonArchivedConversationCustomAttributes',
    function () {
      return this.nonArchivedConversationCustomAttributes.reject(
        (attribute) => attribute.get('type') === 'files',
      );
    },
  ),
  nonArchivedConversationCustomAttributesWithFileS3Key: union(
    'nonArchivedConversationCustomAttributesWithoutFile',
    'nonArchivedConversationFileCustomAttributesWithS3Key',
  ),
  userEditorMetadataAndConversationAttributes: uniq(
    'userEditorAttributes',
    'eventMetadataAttributes',
    'nonArchivedConversationCustomAttributesWithoutFile',
  ),

  _contentServiceDateFilterAttributes: computed('intl.locale', function () {
    return [
      {
        name: this.intl.t('services.attribute-service.sent-at-name'),
        identifier: 'ruleset.first_went_live_at',
        type: 'date',
        icon: 'calendar',
        description: this.intl.t('services.attribute-service.sent-at-description'),
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
      {
        name: this.intl.t('services.attribute-service.created-at-name'),
        identifier: 'ruleset.created_at',
        type: 'date',
        icon: 'calendar',
        description: this.intl.t('services.attribute-service.created-at-description'),
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
      {
        name: this.intl.t('services.attribute-service.updated-at-name'),
        identifier: 'ruleset.updated_at',
        type: 'date',
        icon: 'calendar',
        description: this.intl.t('services.attribute-service.updated-at-description'),
        filterable: true,
        sortable: false,
        usable_in_message_goal: false,
        displayable: true,
      },
    ];
  }),
  _finContentLibraryDateFilterAttributes: computed(
    'intl.locale',
    '_contentServiceDateFilterAttributes',
    function () {
      this._contentServiceDateFilterAttributes.forEach((attribute) => {
        if (attribute.identifier === 'ruleset.updated_at') {
          attribute.name = this.intl.t('ai-content-library.list.columns.last-updated-at');
        }
      });
      return this._contentServiceDateFilterAttributes.reject(
        (attribute) => attribute.identifier === 'ruleset.first_went_live_at',
      );
    },
  ),

  attributeMap: computed('displayableAttributes', function () {
    let attributes = {};

    this.displayableAttributes.forEach((displayableAttribute) => {
      let name = displayableAttribute.attribute.get('identifier');
      attributes[name] = displayableAttribute;
    });

    return attributes;
  }),

  app: computed(function () {
    return getOwner(this).lookup('route:apps/app').get('app');
  }),

  // TODO: SS - Used for rules where the anonymous attribute can be selected, remove this when 'Role' is fully supported
  filterableAttributesWithoutRole: computed('filterableAttributes.[]', function () {
    return this.filterableAttributes.reject((filterableAttribute) =>
      ['role'].includes(filterableAttribute.get('identifier')),
    );
  }),

  filterableAttributesWithoutRoleOrType: computed('filterableAttributes.[]', function () {
    return this.filterableAttributes.reject((filterableAttribute) =>
      ['anonymous', 'role'].includes(filterableAttribute.get('identifier')),
    );
  }),

  displayableAttributes: computed(
    'attributes.length',
    'attributeSettings.length',
    'attributes.@each.archived',
    function () {
      return this.attributes.reduce((displayableAttributes, attribute) => {
        if (
          attribute.get('displayable') &&
          attribute.get('identifier') !== 'role' &&
          !attribute.get('archived')
        ) {
          displayableAttributes.push(this.convertToAttributeWithSettings(attribute));
        }
        return displayableAttributes;
      }, []);
    },
  ),

  userRelationshipDisplayableAttributes: computed(
    'userRelationshipAttributes.length',
    'attributeSettings.length',
    'userRelationshipAttributes.@each.archived',
    function () {
      return this.userRelationshipAttributes.reduce((displayableAttributes, attribute) => {
        if (attribute.get('displayable') && !attribute.get('archived')) {
          displayableAttributes.push(this.convertToAttributeWithSettings(attribute));
        }
        return displayableAttributes;
      }, []);
    },
  ),

  attributesWithSettings(attributes) {
    return attributes.map((attribute) => this.convertToAttributeWithSettings(attribute));
  },

  convertToAttributeWithSettings(attribute) {
    let attributeSettings = this.attributeSettings;
    return AttributeWithSettings.create({
      attribute,
      attributeSettings: attributeSettings.filterBy('attribute', attribute.get('identifier')),
    });
  },

  visibleCompanyAttributes: computed('visibleCompanyAttributeNames.[]', function () {
    let attributes = this._without(
      this.visibleCompanyAttributeNames,
      this.blockListedCompanyAttributeNames,
    );
    return this._collectAttributes(attributes);
  }),

  hiddenCompanyAttributes: computed('hiddenCompanyAttributeNames.[]', function () {
    let attributes = this._without(
      this.hiddenCompanyAttributeNames,
      this.blockListedCompanyAttributeNames,
    );
    return this._collectAttributes(attributes);
  }),

  visibleCompanyAttributeNames: computed(
    'allCompanyAttributeNames.[]',
    'app.currentAdmin.visible_company_attribute_ids.[]',
    function () {
      return this.allCompanyAttributeNames.filter((attribute) => {
        return this.get('app.currentAdmin.visible_company_attribute_ids').includes(attribute);
      });
    },
  ),

  hiddenCompanyAttributeNames: setDiff('allCompanyAttributeNames', 'visibleCompanyAttributeNames'),

  visibleAttributeNames: computed(
    'attributeNames.[]',
    'blockListedAttributeNames.[]',
    'app.currentAdmin.visible_attribute_ids.[]',
    function () {
      let visibleAttributes = this.get('app.currentAdmin.visible_attribute_ids');
      return this._without(this.attributeNames, this.blockListedAttributeNames).filter(
        (attribute) => {
          return visibleAttributes.includes(attribute);
        },
      );
    },
  ),

  hiddenAttributeNames: computed(
    'blockListedAttributeNames.[]',
    'visibleAttributeNames',
    'attributeNames',
    function () {
      let filter = this._concat('blockListedAttributeNames', 'visibleAttributeNames');
      return this._without(this.attributeNames, filter);
    },
  ),

  visibleAttributes: computed('visibleAttributeNames', function () {
    let attributes = this._collectAttributes(this.visibleAttributeNames);
    return this._extractUserAttributes(attributes);
  }),

  hiddenAttributes: computed('hiddenAttributeNames', function () {
    let attributes = this._collectAttributes(this.hiddenAttributeNames);
    return this._extractUserAttributes(attributes);
  }),

  visibleEventNames: computed(
    'eventNames.[]',
    'app.currentAdmin.visible_user_event_ids.[]',
    function () {
      return this.eventNames.filter((event) => {
        return this.get('app.currentAdmin.visible_user_event_ids').includes(event);
      });
    },
  ),
  hiddenEventNames: setDiff('eventNames', 'visibleEventNames'),

  inboxSidebarVisibleAttributeNames: computed(
    'attributeNames.[]',
    'blockListedAttributeNames.[]',
    'app.currentAdmin.visible_attribute_ids.[]',
    function () {
      let visibleAttributes = this.get('app.currentAdmin.visible_attribute_ids');
      return this._without(this.attributeNames, this.blockListedAttributeNames).filter(
        (attribute) => {
          return visibleAttributes.includes(attribute);
        },
      );
    },
  ),

  // This is the list of attributes that will be displayed above the fold in the user details card in the inbox sidebar
  inboxSidebarVisibleAttributes: computed('inboxSidebarVisibleAttributeNames.[]', function () {
    let attributes = this._collectAttributes(this.inboxSidebarVisibleAttributeNames);
    return this._extractUserAttributes(attributes);
  }),

  // This is the list of attributes that will be displayed under show more in the user details card in the inbox sidebar
  inboxSidebarHiddenAttributes: computed(
    'blockListedAttributeNames.[]',
    'inboxSidebarVisibleAttributeNames.[]',
    'attributeNames.[]',
    function () {
      let filter = this.blockListedAttributeNames.concat(this.inboxSidebarVisibleAttributeNames);
      let attributes = this._collectAttributes(this._without(this.attributeNames, filter));
      return this._extractUserAttributes(attributes);
    },
  ),

  _mapAttributesToFilters(filterableAttributes) {
    return filterableAttributes.map((attribute) => {
      return {
        attribute,
        attributeSetting: this.attributeSettings.findBy('attribute', attribute.get('identifier')),
      };
    });
  },
  entryFilters: computed('attributesForEntry', 'attributeSettings', function () {
    return this._mapAttributesToFilters(this.attributesForEntry);
  }),
  exitFilters: computed('attributesForExit', 'attributeSettings', function () {
    return this._mapAttributesToFilters(this.attributesForExit);
  }),
  goalFilters: computed('attributesForGoals', 'attributeSettings', function () {
    return this._mapAttributesToFilters(this.attributesForGoals);
  }),
  messageGoalAttributes: filterBy('attributes', 'usable_in_message_goal', true),
  messageGoalFilters: computed('messageGoalAttributes', 'attributeSettings', function () {
    return this._mapAttributesToFilters(this.messageGoalAttributes);
  }),

  messageGoalUserAttributes: filterBy('messageGoalAttributes', 'isUser', true),
  messageGoalCompanyAttributes: filterBy('messageGoalAttributes', 'isCompany', true),

  companyAttributes: filter('allCompanyAttributes', function (displayableAttribute) {
    return this.companyAttributeNamesToDisplay.includes(
      displayableAttribute.attribute.get('identifier'),
    );
  }),

  statsSystemGoalUserAttributes: filter('messageGoalUserAttributes', function (attribute) {
    return !this.blockListedStatsSystemGoalAttributeNames.includes(attribute.identifier);
  }),
  statsSystemGoalCompanyAttributes: filter('messageGoalCompanyAttributes', function (attribute) {
    return !this.blockListedStatsSystemGoalAttributeNames.includes(attribute.identifier);
  }),
  statsSystemGoalAttributes: uniq('statsSystemGoalUserAttributes', '_tourGoalClientPredicatesData'),

  findByIdentifiers(identifiers) {
    return identifiers.map((identifier) => this.findByIdentifier(identifier));
  },

  findByIdentifier(identifier) {
    return this.allAttributes.findBy('identifier', identifier);
  },

  _extractUserAttributes(attributes) {
    return attributes.filter((displayableAttribute) =>
      displayableAttribute.attribute.get('isUser'),
    );
  },

  _collectAttributes(attributeNames) {
    return attributeNames.map((attributeName) => this.attributeMap[attributeName]);
  },

  _without(elements, filter) {
    return elements.reject((element) => filter.includes(element));
  },

  _concat() {
    let args = Array.prototype.slice.call(arguments);
    return args.reduce((result, array) => {
      let parsedArray = Array.isArray(array) ? array : this.get(array);
      return result.concat(parsedArray);
    }, []);
  },

  _shouldRejectAttributeIfNoSegmentsOrTags(attribute, segmentCollection) {
    return (
      this._shouldRejectAttributeIfNoCollection(attribute, 'tag', segmentCollection) ||
      this._shouldRejectAttributeIfNoCollection(attribute, 'manual_tag', 'app.tags')
    );
  },

  _shouldRejectAttributeIfNoCollection(attribute, type, collectionProperty) {
    return attribute.get('type') === type && isEmpty(this.get(collectionProperty));
  },

  userAttributes: rejectBy(
    'filterableUserAttributesMaybeWithSegmentsAndTags',
    'isUntrackedCda',
    true,
  ),

  attributeGroupListForAnonymous: computed('messengerConditionalDisplayAttributes.[]', function () {
    return this.attributesToGroupList(
      this.messengerConditionalDisplayAttributes.concat(this.capacityAttributes),
    );
  }),

  attributeGroupListForUsers: computed(
    'userAttributes.[]',
    'filterableCompanyAttributesMaybeWithPlansSegmentsAndTags.[]',
    'messengerConditionalDisplayAttributes.[]',
    function () {
      let attributes = this.messengerConditionalDisplayAttributes
        .filter((el) => {
          return EXTRA_USER_ATTRIBUTES.includes(el.get('name'));
        })
        .concat(this.userAttributes)
        .concat(this.capacityAttributes)
        .concat(this.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags);

      return this.attributesToGroupList(attributes);
    },
  ),

  attributeGroupListForContentEvents: computed('contentEventAttributes', function () {
    let groups = [];
    Object.keys(this.app.content_events_config).forEach((contentType) => {
      let config = this.app.content_events_config[contentType];
      let attributes = this._attributesForContentType(contentType, config.events);
      if (attributes.length > 0) {
        groups.push({
          heading: config.title,
          attributes,
        });
      }
    });
    return groups;
  }),
  _attributesForContentType(contentType, events) {
    let attributes = Object.keys(events).map((event) => `${contentType}_${event}`);
    if (this.contentEventAttributes.length > 0) {
      return attributes
        .map((attribute) => this.contentEventAttributes.findBy('identifier', attribute))
        .filter(Boolean);
    } else {
      return [];
    }
  },

  contentEventAttributesFor(objectType) {
    let objectName = objectNames[objectType];
    return this.contentEventAttributes.filter((attribute) =>
      attribute.identifier.startsWith(`${objectName}_`),
    );
  },

  conversationAttributeDescriptors: computed(function () {
    return this.store.peekAll('conversation-attributes/descriptor');
  }),

  customObjectsAttributeDescriptors: computed('customObjectAttributeGroups', function () {
    let attributeDescriptors = [];

    this.customObjectAttributeGroups.forEach((attributeGroup) => {
      attributeDescriptors.pushObjects(attributeGroup.attributes);
    });

    return attributeDescriptors;
  }),

  customObjectAttributeGroups: computed(function () {
    let attributeGroups = [];
    let references = this.store
      .peekAll('objects/reference')
      .filterBy('referencesOne')
      .filter((reference) =>
        RESERVED_OBJECT_TYPE_IDENTIFIERS.includes(reference.objectTypeIdentifier),
      );

    references.forEach((reference) => {
      let referenceAttribute = this.findAttributeForObjectType(
        reference.objectTypeIdentifier,
        reference.attributeDescriptorId,
      );
      let referencedObjectType = referenceAttribute.referencedObjectType;

      if (!referenceAttribute.archived && referencedObjectType) {
        let group = {
          heading: `${
            STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP[reference.objectTypeIdentifier]
          } > ${referenceAttribute.name} (${referencedObjectType.name})`,
          attributes: [],
        };

        referencedObjectType.attributeDescriptors
          .rejectBy('isRelationshipDataType')
          .rejectBy('isStandardAttribute')
          .forEach((attributeDescriptor) => {
            let identifier;
            if (isPresent(referenceAttribute.relationship)) {
              identifier = `${referenceAttribute.objectTypeIdentifier}.related_objects.${referenceAttribute.relatedAttribute.id}.custom_objects.${attributeDescriptor.objectTypeIdentifier}.${attributeDescriptor.id}`;
            } else {
              identifier = `${referenceAttribute.identifier}.${attributeDescriptor.identifier}`;
            }

            group.attributes.pushObject(
              this.store.createRecord('attribute', {
                name: attributeDescriptor.name,
                identifier,
                type: attributeDescriptor.predicateDataType,
                icon: 'transfer',
                description: attributeDescriptor.description,
                archived: false,
                templatable: true,
                templatableIdentifier: identifier,
              }),
            );
          });

        if (group.attributes.length > 0) {
          attributeGroups.pushObject(group);
        }
      }
    });
    return attributeGroups.uniqBy('heading');
  }),

  buildDescriptorsFromTypes(descriptors, indexedTypes, path) {
    let builtDescriptors = [];
    descriptors.forEach((descriptor) => {
      let descriptorPath = [...path, descriptor];
      if (descriptor.dataType === 'domain_object_relationship') {
        let type = indexedTypes[descriptor.domainObjectTypeId.toString()];
        builtDescriptors = builtDescriptors.concat(
          this.buildDescriptorsFromTypes(type.descriptors, indexedTypes, descriptorPath),
        );
      } else if (descriptor.dataType !== 'user_relationship') {
        builtDescriptors.push({
          id: descriptorPath.map((x) => x.id).join('.'),
          name: descriptorPath.map((x) => humanize([x.name])).join(' › '),
          description: descriptor.description,
          dataType: descriptor.dataType,
          listOptions: descriptor.listOptions,
          archived: descriptor.archived,
          identifier: descriptor.identifier,
          templatableIdentifier: descriptor.identifier,
          templatable: descriptor.templatable,
        });
      }
    });
    return builtDescriptors;
  },

  findAttributeForObjectType(objectTypeIdentifier, attributeId) {
    if (objectTypeIdentifier === USER_OBJECT_TYPE_IDENTIFIER) {
      return Attribute.peekOrFindByCdaId(
        this.store,
        this.modelDataCacheService,
        this.appService.app,
        attributeId,
      );
    } else if (objectTypeIdentifier === CONVERSATION_OBJECT_TYPE_IDENTIFIER) {
      return ConversationAttributeDescriptor.peekOrFindById(attributeId);
    } else if (
      this.customObjectsService.customObjectTypes.mapBy('identifier').includes(objectTypeIdentifier)
    ) {
      return AttributeDescriptor.peekOrFindById(attributeId);
    }
  },

  attributesToGroupList(attributes) {
    let generalAttributeGroup = { attributes: [] };
    let peopleAttributeGroup = { heading: 'Person data', attributes: [] };
    let companyAttributeGroup = { heading: 'Company data', attributes: [] };
    let conversationAttributeGroup = { heading: 'Conversation data', attributes: [] };
    let messageAttributeGroup = { heading: 'Message data', attributes: [] };
    let availabilityAttributeGroup = { heading: 'Availability', attributes: [] };
    let ticketAttributeGroup = { heading: 'Ticket data', attributes: [] };
    let aiAgentAttributeGroup = { heading: 'AI Agent data', attributes: [] };

    attributes.forEach(function (attribute) {
      if (attribute.category === 'ticket') {
        ticketAttributeGroup.attributes.push(attribute);
      } else if (attribute.isMessage) {
        messageAttributeGroup.attributes.push(attribute);
      } else if (attribute.category === null) {
        generalAttributeGroup.attributes.push(attribute);
      } else if (attribute.isConversation) {
        conversationAttributeGroup.attributes.push(attribute);
      } else if (['office_hours.schedule', 'team_capacity'].includes(attribute.identifier)) {
        availabilityAttributeGroup.attributes.push(attribute);
      } else if (attribute.isCompany) {
        companyAttributeGroup.attributes.push(attribute);
      } else if (attribute.isAiAgent) {
        aiAgentAttributeGroup.attributes.push(attribute);
      } else {
        peopleAttributeGroup.attributes.push(attribute);
      }
    });

    let attributeGroups = [generalAttributeGroup, peopleAttributeGroup, companyAttributeGroup];

    if (isPresent(messageAttributeGroup.attributes)) {
      attributeGroups.push(messageAttributeGroup);
    }
    if (isPresent(conversationAttributeGroup.attributes)) {
      attributeGroups.push(conversationAttributeGroup);
    }
    if (isPresent(availabilityAttributeGroup.attributes)) {
      attributeGroups.push(availabilityAttributeGroup);
    }
    if (isPresent(ticketAttributeGroup.attributes)) {
      attributeGroups.push(ticketAttributeGroup);
    }
    if (isPresent(aiAgentAttributeGroup.attributes)) {
      attributeGroups.push(aiAgentAttributeGroup);
    }

    attributeGroups.forEach((group) => group.attributes.sortBy('name'));

    return attributeGroups;
  },
});
