/* RESPONSIBLE TEAM: team-help-desk-experience */

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { request } from 'embercom/lib/inbox/requests';
import type Session from './session';
import SearchableDocument from 'embercom/objects/inbox/searchable-document';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import AdminSummary, {
  type AdminSummaryWireFormat,
  UNASSIGNED_ID,
} from 'embercom/objects/inbox/admin-summary';
import TeamSummary, { type TeamSummaryWireFormat } from 'embercom/objects/inbox/team-summary';
import type FuzzySearch from './fuzzy-search';
import { prepareStringForFuzzySearch } from './fuzzy-search';
import { AdminSearchContexts } from 'embercom/objects/inbox/macro';
import type IntlService from 'embercom/services/intl';

export default class Inbox2AssigneeSearch extends Service {
  @service declare session: Session;
  @service declare fuzzySearch: FuzzySearch;
  @service declare intl: IntlService;

  @tracked private admins: Array<AdminSummary> = [];
  @tracked private teams: Array<TeamSummary> = [];

  get botAdmin(): AdminSummary {
    let bot = this.admins.find((admin) => admin.isOperator);
    if (!bot) {
      // We should always find the Bot admin, but we need to return an AdminSummary for type safety.
      return new AdminSummary(-1, this.intl.t('inbox.operator'), null);
    }
    return bot;
  }

  get searchableAdmins(): Array<SearchableDocument> {
    return this.admins.map(
      (admin) =>
        new SearchableDocument(
          admin.id,
          SearchableType.Admin,
          new Date(),
          new Date(),
          admin,
          admin.restrictedContexts,
          undefined,
          prepareStringForFuzzySearch(admin.name),
        ),
    );
  }

  get searchableTeams(): Array<SearchableDocument> {
    return this.teams.map(
      (team) =>
        new SearchableDocument(
          team.id,
          SearchableType.Team,
          new Date(),
          new Date(),
          team,
          undefined,
          undefined,
          prepareStringForFuzzySearch(team.name),
        ),
    );
  }

  filterByContext(searchableDocuments: SearchableDocument[], context: AdminSearchContexts) {
    return searchableDocuments.filter((document) => !document.restrictedContexts.includes(context));
  }

  filterAdmins(query: string, context: AdminSearchContexts, limit?: number) {
    let searchableDocuments = this.filterByContext(this.searchableAdmins, context);

    return this.executeSearch(query, searchableDocuments, limit);
  }

  filterTeams(query: string, limit?: number) {
    return this.executeSearch(query, this.searchableTeams, limit);
  }

  filterAdminsAndTeams(query: string, context: AdminSearchContexts, limit?: number) {
    let searchableDocuments = this.filterByContext(this.searchableAdmins, context).concat(
      this.searchableTeams,
    );

    searchableDocuments = searchableDocuments.filter((doc) => doc.data.name !== 'Unassigned');

    return this.executeSearch(query, searchableDocuments, limit);
  }

  findAdminById(id: AdminSummary['id']) {
    return this.admins.findBy('id', id);
  }

  findAdminByEmail(email: AdminSummary['email']) {
    return this.admins.findBy('email', email);
  }

  private executeSearch(query: string, documents: SearchableDocument[], limit?: number) {
    if (!query) {
      let limitedDocs = limit ? documents.slice(0, limit) : documents;
      return {
        total: limitedDocs.length,
        results: limitedDocs,
      };
    }

    let results = this.fuzzySearch.search(query, documents, 'data.name', {
      threshold: 0.5,
      highlight: false,
      limit,
    });

    return {
      total: results.length,
      results,
    };
  }

  async loadAllAdminsAndTeams() {
    let response = await request(`/ember/inbox/assignees?app_id=${this.session.workspace.id}`);
    let json = (await response.json()) as {
      admins: {
        total: number;
        results: AdminSummaryWireFormat[];
      };
      teams: {
        total: number;
        results: TeamSummaryWireFormat[];
      };
    };

    this.admins = [
      new AdminSummary(
        UNASSIGNED_ID,
        this.intl.t('inbox.inbox-list.unassigned'),
        '',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        [AdminSearchContexts.Mentions],
      ),
      ...json.admins.results.map(AdminSummary.deserialize),
    ];
    this.teams = [
      new TeamSummary(UNASSIGNED_ID, this.intl.t('inbox.inbox-list.unassigned'), undefined),
      ...json.teams.results.map(TeamSummary.deserialize),
    ];
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2AssigneeSearch: Inbox2AssigneeSearch;
    'inbox2-assignee-search': Inbox2AssigneeSearch;
  }
}
