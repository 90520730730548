/* RESPONSIBLE TEAM: team-tickets-1 */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { isEmpty, isPresent } from '@ember/utils';
import { omit } from 'underscore';

export default class PermissionsHierarchyService extends Service {
  @service appService;
  @service store;
  permissionsHierarchy = {};
  sections = {};
  seatPermissionMap = {};

  @task({ drop: true })
  *ensureDataIsLoaded() {
    if (isEmpty(Object.keys(this.permissionsHierarchy))) {
      let response = yield ajax({
        url: '/ember/permissions/permissions_hierarchy',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
      this.permissionsHierarchy = this._extractPermissions(response);
      this.sections = this._extractSections();
      this.seatPermissionMap = this._extractSeatPermissionMap();
    }
  }

  _extractSections() {
    return omit(this.permissionsHierarchy, function (permissionPart) {
      return permissionPart['type'] !== 'section';
    });
  }

  _extractPermissions(response) {
    this.permissionsHierarchy = omit(response, function (permissionPart) {
      return permissionPart['read_only'];
    });

    Object.entries(this.permissionsHierarchy).forEach(([key, permissionPart]) => {
      if (this.appService.app.hasOptedInForProductIa && key === 'outbound') {
        this.permissionsHierarchy[key]['title'] = 'ia-outbound-section-title';
      }
      if (permissionPart['type'] !== 'section' && isPresent(permissionPart['children_ids'])) {
        let permissionKeys = permissionPart['children_ids'];
        permissionKeys.forEach((permissionKey) => {
          this.permissionsHierarchy[permissionKey]['parent_id'] = key;
        });
      }
    });

    return this.permissionsHierarchy;
  }

  _extractSeatPermissionMap() {
    Object.entries(this.permissionsHierarchy).forEach(([key, permissionPart]) => {
      if (permissionPart['type'] !== 'section') {
        let seatTypes = permissionPart['seat_types'];
        this.seatPermissionMap[`can_${key}`] = seatTypes;
      }
    });
    return this.seatPermissionMap;
  }
}
