/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import {
  meetsAndroidSdkRequirements,
  meetsIosSdkRequirements,
} from 'embercom/lib/mobile/sdk-version-check';

const MIN_ANDROID_SDK_VERSION = '15.8.0';
const MIN_IOS_SDK_VERSION = '17.0.1';

function _targetingUnsupportedSdkVersion(rulesetLink) {
  return (
    // targeting unsupported ios sdk version
    (rulesetLink.preferredDevices.any((device) => device === preferredDevices.ios) &&
      !meetsIosSdkRequirements(rulesetLink.app, MIN_IOS_SDK_VERSION)) ||
    // targeting unsupported android sdk version
    (rulesetLink.preferredDevices.any((device) => device === preferredDevices.android) &&
      !meetsAndroidSdkRequirements(rulesetLink.app, MIN_ANDROID_SDK_VERSION))
  );
}

const ConnectionPointsInboundBotMobileConstraints = BaseValidator.extend({
  intl: service(),
  appService: service(),
  contentEditorService: service(),

  validate(value, options, model) {
    let rulesetLink = this.contentEditorService.activeRulesetLink;
    // Only validate if the ruleset link is inbound bot
    if (!rulesetLink || !rulesetLink.object.isInbound) {
      return true;
    }

    // Only validate if the ruleset link preferred devices include mobile
    let { ios, android } = preferredDevices;
    if (!rulesetLink.preferredDevices.any((device) => [ios, android].includes(device))) {
      return true;
    }

    // Only validate if the app is targeting an unsupported sdk version
    if (
      this.appService.app?.canUseInboundBotsSansReplyButtons &&
      !_targetingUnsupportedSdkVersion(rulesetLink)
    ) {
      return true;
    }

    let { replyButtons } = model;
    if (!replyButtons || !replyButtons.length) {
      return this.appService.app?.canUseInboundBotsSansReplyButtons
        ? this.intl.t(
            'operator.workflows.visual-builder.validations.inbound-bot-mobile-constraints.legacy-sdk-no-reply-button',
            {
              iosSdkVersion: MIN_IOS_SDK_VERSION,
              androidSdkVersion: MIN_ANDROID_SDK_VERSION,
            },
          )
        : this.intl.t(
            'operator.workflows.visual-builder.validations.inbound-bot-mobile-constraints.no-reply-button',
          );
    }

    return true;
  },
});

ConnectionPointsInboundBotMobileConstraints.reopenClass({
  getDependentsFor() {
    return ['model.replyButtons.@each.label', 'model.outwardConnectionPoints.@each.isConnected'];
  },
});

export default ConnectionPointsInboundBotMobileConstraints;
