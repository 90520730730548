/* RESPONSIBLE TEAM: team-data-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    createdBy: { key: 'created_by_id' },
    archivedBy: { key: 'archived_by_id' },
    stats: {
      serialize: false,
      deserialize: 'records',
    },
  },
});
