/* RESPONSIBLE TEAM: team-actions */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    apiVersion: {
      deserialize: 'records',
      serialize: 'records',
    },
  },
});
