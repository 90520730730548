/* RESPONSIBLE TEAM: team-data-foundations */
import type Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { STANDARD_OBJECT_TYPES } from 'embercom/models/custom-objects/constants/object-types';

export default class CustomObjects extends Service {
  @service declare store: Store;

  findCustomObjectTypeByIdentifier(identifier: $TSFixMe) {
    return this.customObjectTypes.findBy('identifier', identifier);
  }

  findObjectTypeByIdentifier(identifier: $TSFixMe) {
    return this.objectTypes.findBy('identifier', identifier);
  }

  get objectTypes(): $TSFixMe[] {
    return this.standardObjectTypes.concat(this.customObjectTypes.toArray());
  }

  get standardObjectTypes() {
    return STANDARD_OBJECT_TYPES;
  }

  get customObjectTypes() {
    return this.store.peekAll('custom-objects/object-type');
  }

  objectTypeDropdownItems(excludedObjectTypeIdentifiers: $TSFixMe[] = []) {
    return this.objectTypes
      .rejectBy('archived')
      .reject((objectType) => excludedObjectTypeIdentifiers.includes(objectType.identifier))
      .map((objectType) => {
        return {
          text: objectType.name,
          value: objectType.identifier,
        };
      });
  }
}
