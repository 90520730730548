/* RESPONSIBLE TEAM: team-data-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    tag: { key: 'tag_id' },
    admin: { key: 'admin_id' },
  },
});
