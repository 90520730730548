/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { actionOptions } from 'embercom/models/banners/banner';

const BannerButtonTextLength = BaseValidator.extend({
  intl: service(),

  maxLength: 25,

  validate(value, options, model) {
    let localizedBannerContent = model.localizedBannerContents.firstObject;
    if (model.action === actionOptions.open_a_url) {
      if (isBlank(localizedBannerContent.actionTitle)) {
        return this.intl.t('outbound.banners.validations.link-text');
      } else if (localizedBannerContent.actionTitle.length > this.maxLength) {
        return this.intl.t('outbound.banners.validations.link-text-too-long');
      }
    } else if (model.action === actionOptions.open_url_button) {
      if (isBlank(localizedBannerContent.actionTitle)) {
        return this.intl.t('outbound.banners.validations.button-text');
      } else if (localizedBannerContent.actionTitle.length > this.maxLength) {
        return this.intl.t('outbound.banners.validations.button-text-too-long');
      }
    }
    return true;
  },
});

BannerButtonTextLength.reopenClass({
  getDependentsFor() {
    return ['model.action', 'model.localizedBannerContents.firstObject.actionTitle'];
  },
});

export default BannerButtonTextLength;
