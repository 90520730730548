/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import type KnowledgeHubEditorService from './knowledge-hub-editor-service';
import type IntercomConfirmService from './intercom-confirm-service';
import type IntlService from 'ember-intl/services/intl';

export const DRAWER_EDITOR_QUERY_PARAMS = [
  'activeContentId',
  'activeContentType',
  'editorMode',
  'folderId',
  'collectionId',
  'contentLocale',
];

export default class KnowledgeHubDrawerEditorService extends Service {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @action
  async closeDrawer() {
    if (!this.hasDirtyActiveContent()) {
      this.removeQueryParams();
      return;
    }

    let confirmationResult = await this.confirmClose();
    if (confirmationResult === true) {
      await this.saveChanges();
      this.removeQueryParams();
    } else if (confirmationResult.canceled) {
      this.undoChanges();
      this.removeQueryParams();
    } else if (confirmationResult.closed) {
      return;
    }
  }

  @action
  openViewDrawer({
    activeContentId,
    activeContentType,
  }: {
    activeContentId: number | string;
    activeContentType: string;
  }) {
    this.router.transitionTo({
      queryParams: {
        activeContentId,
        activeContentType,
        editorMode: EditorMode.VIEW,
      },
    });
  }

  @action
  openEditDrawer({
    activeContentId,
    activeContentType,
  }: {
    activeContentId: number | string;
    activeContentType: string;
  }) {
    this.router.transitionTo({
      queryParams: {
        activeContentId,
        activeContentType,
        editorMode: EditorMode.EDIT,
      },
    });
  }

  @action
  createNewContent({
    activeContentType,
    collectionId,
    contentLocale,
    folderId,
    folder_id,
  }: {
    activeContentType: string;
    collectionId?: string;
    contentLocale?: string;
    folderId?: string;
    folder_id?: string;
  }) {
    folderId = folderId ?? folder_id;

    this.router.transitionTo({
      queryParams: {
        editorMode: EditorMode.NEW,
        activeContentType,
        ...(collectionId ? { collectionId } : {}),
        ...(contentLocale ? { contentLocale } : {}),
        ...(folderId ? { folderId } : {}),
      },
    });
  }

  private hasDirtyActiveContent() {
    return this.knowledgeHubEditorService.activeContentModel.hasUnsavedChanges;
  }

  private confirmClose() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('knowledge-hub.content-editor.drawer-editor.confirmation-dialog.title'),
      body: this.intl.t('knowledge-hub.content-editor.drawer-editor.confirmation-dialog.body'),
      confirmButtonText: this.intl.t(
        'knowledge-hub.content-editor.drawer-editor.confirmation-dialog.confirmButtonText',
      ),
      cancelButtonText: this.intl.t(
        'knowledge-hub.content-editor.drawer-editor.confirmation-dialog.cancelButtonText',
      ),
      distinguishCancelFromClose: true,
    });
  }

  private undoChanges() {
    this.knowledgeHubEditorService.cancelEditActiveContent({ redirectToView: false });
  }

  private async saveChanges() {
    await this.knowledgeHubEditorService.saveActiveContent({ redirectToView: false });
  }

  private removeQueryParams() {
    this.router.transitionTo({
      queryParams: {
        activeContentId: null,
        activeContentType: null,
        editorMode: null,
        collectionId: null,
        contentLocale: null,
        folderId: null,
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
    'knowledge-hub-drawer-editor-service': KnowledgeHubDrawerEditorService;
  }
}
