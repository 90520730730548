/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type WorkflowConnector from 'embercom/models/operator/visual-builder/step/attribute-collector';
import { hasFeature } from 'embercom/helpers/has-feature';

export default class WorkflowStepInputParameterMapping extends BaseValidator {
  @service declare intl: $TSFixMe;
  @service declare appService: $TSFixMe;

  validate(_stepType: any, _options: ValidationOptions, model: WorkflowConnector | any) {
    let insertableAction = model.action;

    if (!hasFeature('answerbot-fin-actions-input-parameter-mapping', this.appService)) {
      return true; // Skip this validator if user can't use actions input mapping
    }

    if (!insertableAction) {
      return true; // Skip this validator until action is specified
    }

    if (!insertableAction.hasRequestParameters) {
      return true; // Skip this validator until action has request parameters
    }

    if (insertableAction.usage !== 'fin') {
      return true; // Skip this validator fin it's not a fin action
    }

    if (insertableAction.requestParameters.length > model.actionInputParameterMappings.length) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.workflow-connector.missing-input-mapping',
      );
    } else {
      return true;
    }
  }

  static getDependentsFor() {
    return [
      'model.action',
      'model.actionInputParameterMappings',
      'model.actionInputParameterMappings.length',
    ];
  }
}
