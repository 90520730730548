/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type CommandKService from './command-k';
import type InboxApi from './inbox-api';

import type InboxState from './inbox-state';

export default class ConversationOptions extends Service {
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;
  @service declare notificationsService: any;
  @service declare commandK: CommandKService;

  @action trackOpenConversation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'conversation_list',
      object: 'conversation',
    });
  }

  @action trackSnoozeConversation(duration: DurationObject) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      section: 'conversation_list',
      object: 'conversation',
      snoozed_until: duration.type,
    });
  }

  @action trackPriorityChange(priority: boolean) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'priority_change',
      section: 'conversation_list',
      object: 'conversation',
      priority,
    });
  }

  @action trackRemoveSla() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'remove_sla',
      section: 'conversation_list',
      object: 'conversation',
    });
  }

  @action trackMarkUnread() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mark_unread',
      section: 'conversation_list',
      object: 'conversation',
    });
  }

  async openConversation(conversation: ConversationRecord) {
    await this.inboxState.openConversation(conversation);
    this.trackOpenConversation();
  }

  async removeSla(conversation: ConversationRecord) {
    if (!conversation.id) {
      return;
    }

    try {
      await this.inboxApi.removeSla(conversation.id);
      this.trackRemoveSla();
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      throw new Error('Cannot remove SLA from conversation');
    }
  }

  async togglePriority(conversation: ConversationRecord, priority: boolean) {
    await this.inboxState.changePriority(conversation, priority);
    this.trackPriorityChange(priority);
  }

  async snoozeConversation(conversation: ConversationRecord) {
    this.commandK.registerAndShow({
      actionID: 'snooze',
      onSelect: async (value: DurationObject) => {
        await this.inboxState.snoozeConversation(conversation, value);
        this.trackSnoozeConversation(value);
      },
    });
  }

  async markUnread(conversation: ConversationRecord) {
    this.inboxState.markUnread(conversation);
    this.trackMarkUnread();
  }

  async markMentionsAsRead(conversation: ConversationRecord) {
    this.inboxState.markMentionsAsRead(conversation);
  }
}

declare module '@ember/service' {
  interface Registry {
    conversationOptions: ConversationOptions;
    'inbox2-conversation-options-service': ConversationOptions;
  }
}
