/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Service, { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import {
  findSolution,
  plansFromSolution,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
} from 'embercom/lib/billing';

import { allSubsets } from 'embercom/lib/all-subsets';

type PricePromiseParams = {
  includeTiers: number;
  planIds: Array<string>;
  pricingModel: string;
  agentCount: number;
  overrideCanSeePrice: boolean;
  couponCode: string;
  source: string;
  includePricingByBillingPeriod: boolean;
  includePlanCombinationValidation: boolean;
  useBillingCycleMetricVariations: boolean;
  useShiftedCycleMetricVariations: boolean;
  coreSeatCount: number;
};

type UsageParameterOverrides = Record<string, number>;

export default class PriceFetcherService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  async getPricePromise(
    {
      includeTiers,
      planIds,
      pricingModel,
      agentCount,
      overrideCanSeePrice,
      couponCode,
      source,
      includePricingByBillingPeriod,
      includePlanCombinationValidation,
      useBillingCycleMetricVariations,
      useShiftedCycleMetricVariations,
      coreSeatCount,
    }: Partial<PricePromiseParams>,
    usageParameterOverrides: UsageParameterOverrides | undefined = undefined,
  ) {
    let query: Record<string, string | number | boolean | undefined | Array<string>> = {
      plan_ids: planIds,
      include_tiers: includeTiers,
      agent_count: agentCount,
      override_can_see_price: overrideCanSeePrice,
      coupon_code: couponCode,
      source,
      include_pricing_by_billing_period: includePricingByBillingPeriod,
      include_plan_combination_validation: includePlanCombinationValidation,
      core_seat_count: coreSeatCount,
    };

    if (useShiftedCycleMetricVariations) {
      query['use_shifted_billing_cycle_metric_variations'] = true;
    } else if (useBillingCycleMetricVariations) {
      query['use_billing_cycle_metric_variations'] = true;
    }

    if (pricingModel) {
      query['pricing_model_identifier'] = pricingModel;
    }

    if (usageParameterOverrides) {
      query = { ...query, ...usageParameterOverrides };
    }

    return this.store.query('price', query);
  }

  async fetchPricesCombinations(
    combinations: Array<Array<string>>,
    includeTiers: number,
    overrideCanSeePrice: boolean,
    useBillingCycleMetricVariations: boolean,
    useShiftedCycleMetricVariations: boolean,
    source: string,
    includePlanCombinationValidation: boolean,
  ) {
    let pricePromises = combinations
      .filter((combination) => combination?.length > 0)
      .map((planIds) =>
        this.getPricePromise({
          planIds,
          includeTiers,
          overrideCanSeePrice,
          useBillingCycleMetricVariations,
          useShiftedCycleMetricVariations,
          agentCount: this.appService.app.agentCount,
          source,
          includePricingByBillingPeriod: true,
          includePlanCombinationValidation,
        }),
      );
    return await Promise.all(pricePromises).then((prices) => prices.map((a) => a.toArray()).flat());
  }

  async bulkLoadPrices(
    params: Array<Record<string, string | number | boolean | undefined | Array<string>>>,
    existingPrices: any,
  ) {
    let promises = params.map((request: any) =>
      this.getPricePromise({ agentCount: this.appService.app.agentCount, ...request }),
    );
    let results = await Promise.all(promises);
    let loadedPrices = results.flatMap((result) => {
      return result.toArray();
    });
    if (!existingPrices) {
      return loadedPrices;
    } else if (typeof existingPrices === 'object') {
      return loadedPrices.concat(existingPrices);
    } else {
      return existingPrices.concat(loadedPrices);
    }
  }

  async fetchCurrentPrice(params: any = {}, planIds: string[], existingPrices: any) {
    let currentPrice = (await this.getPricePromise({ ...params, planIds })).toArray();

    if (!existingPrices) {
      return currentPrice;
    } else if (typeof existingPrices === 'object') {
      return currentPrice.concat(existingPrices);
    } else {
      return existingPrices.concat(currentPrice);
    }
  }

  async fetchCurrentPricePerUsage(params: any = {}, planIds: Array<string>) {
    return (
      await this.getPricePromise(
        { planIds, includeTiers: params.includeTiers },
        { [params.pricingMetric]: params.usageAmount },
      )
    ).toArray();
  }

  async fetchSolutionPrices(
    solutionId: string,
    couponCode: string,
    source: string,
    includePricingByBillingPeriod: boolean,
    seats = 0,
    includeTiers: number,
    usageParameterOverrides: UsageParameterOverrides | undefined = undefined,
  ) {
    let solution = findSolution(solutionId);
    let solutionPlanIds: Array<string> = plansFromSolution(parseInt(solutionId, 10));
    let pricingModel;
    if (PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(solutionId)) {
      pricingModel =
        solutionId === PRICING_5_X_EARLY_STAGE_SOLUTION_ID
          ? PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL
          : PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL;
    }
    pricingModel ??= solution.pricingModel;
    let priceQueries = this.requiredSolutionPriceQueries(
      pricingModel,
      solutionPlanIds,
      solution.recommendedAddons,
      couponCode,
      source,
      includePricingByBillingPeriod,
      seats,
      includeTiers,
    );
    let promises = priceQueries.map((query: any) =>
      this.getPricePromise(query, usageParameterOverrides),
    );
    let results = await Promise.all(promises);
    return results.flatMap((result) => result.toArray());
  }

  requiredSolutionPriceQueries(
    pricingModel: string,
    solutionPlanIds: Array<string>,
    availableAddonPlanIds: Array<string>,
    couponCode: string,
    source: string,
    includePricingByBillingPeriod: boolean,
    seats: number,
    includeTiers: number,
  ) {
    // Get every possible combination of add-ons, including the empty set
    let possibleAddonCombinations = allSubsets(availableAddonPlanIds);
    // Combine every possible add-on combination with the solution plans
    let possiblePlanIdCombinations = possibleAddonCombinations.map((addOnPlanIds: Array<string>) =>
      solutionPlanIds.concat(addOnPlanIds),
    );
    // We also need to fetch add-on prices individually so they can be listed separately
    let requiredPlanIdCombinations = possiblePlanIdCombinations.concat(
      availableAddonPlanIds.map((planId) => [planId]),
    );
    return requiredPlanIdCombinations.map((planIdCombination: Array<string>) => {
      return {
        pricingModel,
        planIds: planIdCombination,
        couponCode,
        agentCount: seats,
        source,
        includePricingByBillingPeriod,
        includeTiers,
        coreSeatCount: seats,
      };
    });
  }

  async fetchSolutionPricesWithSeats(
    solutionId: string,
    planIds: Array<string>,
    couponCode: string,
    source: string,
    includePricingByBillingPeriod: boolean,
    agentCount: number,
    usageParameterOverrides: UsageParameterOverrides | undefined = undefined,
  ) {
    let pricingModel = findSolution(solutionId).pricingModel;
    let query = {
      pricingModel,
      planIds,
      couponCode,
      agentCount,
      source,
      includePricingByBillingPeriod,
    };
    let priceForPlanAddonsAndSeats = await this.getPricePromise(query, usageParameterOverrides);
    return priceForPlanAddonsAndSeats;
  }
}

declare module '@ember/service' {
  interface Registry {
    priceFetcherService: PriceFetcherService;
    'price-fetcher-service': PriceFetcherService;
  }
}
