/* RESPONSIBLE TEAM: team-help-desk-experience */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { type InboxIdentifier } from 'embercom/objects/inbox/inboxes/inbox';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import Conversation, { ConversationState } from 'embercom/objects/inbox/conversation';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { action } from '@ember/object';
import { type ParticipantDataType, type ReplyDataType } from './inbox-api';
import type InboxApi from './inbox-api';
import type Session from './session';
import { DurationObject, getDurationType } from 'embercom/objects/inbox/duration';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import TeamSummary, { type TeamSummaryWireFormat } from 'embercom/objects/inbox/team-summary';
import Evented from '@ember/object/evented';
import { useResource } from 'ember-resources';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import type CommandKService from './command-k';
import storage from 'embercom/vendor/intercom/storage';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import type WorkflowConnectorAction from 'embercom/objects/inbox/workflow-connector-action';
import type WorkflowDetails from 'embercom/objects/inbox/workflow-details';
import type IntlService from 'embercom/services/intl';
import type TaggablePart from 'embercom/objects/inbox/taggable-part';
import { isTaggable } from 'embercom/objects/inbox/taggable-part';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';
import type Snackbar from 'embercom/services/snackbar';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { getDateFormat } from 'embercom/objects/inbox/snooze';
import moment from 'moment-timezone';
import UnassignedTeamSummary from 'embercom/objects/inbox/unassigned-team-summary';
import { type Block, type BlockList } from '@intercom/interblocks.ts';
import FaviconCounter from 'embercom/lib/favicon-counter';
import { InboxSortOption, type InboxStateOption } from 'embercom/models/data/inbox/inbox-filters';
import TitleChanged from 'embercom/objects/inbox/renderable/title-changed';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { Channel, ChannelData } from 'embercom/models/data/inbox/channels';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import AdminNote from 'embercom/objects/inbox/renderable/admin-note';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { createDeletedPart, createDeletedInitialPart } from 'embercom/objects/inbox/deleted-part';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { createRenderablePart } from 'embercom/objects/inbox/renderable-part';
import type AdminAwayService from 'embercom/services/admin-away-service';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import { type UpdatesApi } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { captureException } from 'embercom/lib/sentry';
import GeneratedConversationSummary from 'embercom/objects/inbox/renderable/conversation-summary';
import ConversationSelectionResource from 'embercom/resources/inbox2/conversations-selection';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { emptyRequiredAttributesForConversation } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { latestUpdatedParticipantsStorageKey } from 'embercom/objects/inbox/user-summary';
import type FinQuestionAnswers from 'embercom/services/fin-question-answers';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';
import ajax from 'embercom/lib/ajax';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import type Inbox2AssigneeSearch from './inbox2-assignee-search';
import type TicketStateService from './ticket-state-service';
import { isEqual } from 'underscore';
import type InboxSectionSizes from './inbox-section-sizes';
import { ResponseError } from 'embercom/lib/inbox/requests';
import View from 'embercom/objects/inbox/inboxes/view';
import { TrackedSet } from 'tracked-built-ins';
import type GreatGuidanceService from './great-guidance-service';
import type InboxSidebarService from './inbox-sidebar-service';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type LogService from 'embercom/services/log-service';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';

export const LOCALSTORAGE_CONVERSATION_VIEW_KEY = 'inbox.conversationsView';
export const LOCALSTORAGE_INBOX_LIST_VIEW_KEY = 'inbox.inboxListView';
const NO_ACCESS_ERROR = 'You do not have access to this conversation';

export const NAV_WIDTH = 44;

export const NOTIFCATION_UNDO_CONTENT_COMPONENT = 'inbox2/left-nav/notification-undo-content';
export enum SidebarViewType {
  Hidden = 'Hidden',
  InboxList = 'InboxList',
}

export enum ConversationsViewType {
  List = 'List',
  Table = 'Table',
  TableFullscreen = 'TableFullscreen',
}

export enum ComposerLocation {
  ConversationPage,
  ConversationPreviewPanel,
}

export enum InboxEvents {
  ConversationUpdated = 'ConversationUpdated',
  ConversationRead = 'ConversationRead',
  ConversationUnread = 'ConversationUnread',
  ConversationRemoved = 'ConversationRemoved',
  ConversationClosed = 'ConversationClosed',
  TicketVisibilityUpdated = 'TicketVisibilityUpdated',
  ShowRequiredAttributesPanel = 'ShowRequiredAttributesPanel',
  TicketMainPanelCollapsed = 'TicketMainPanelCollapsed',
  ReplyToEmailPart = 'ReplyToEmailPart',
  CopilotSearch = 'CopilotSearch',
}

enum BulkLinkError {
  BackofficeInvalid = 'backoffice_ticket_invalid_for_linking',
  TrackerInvalid = 'tracker_ticket_invalid_for_linking',
  AlreadyHadLinkedTracker = 'already_has_linked_tracker',
}

export class RequiredAttributesError extends Error {}

export default class InboxState extends Service.extend(Evented) {
  @service declare router: Router;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare adminAwayService: AdminAwayService;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare finQuestionAnswers: FinQuestionAnswers;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare ticketStateService: TicketStateService;
  @service declare inboxSectionSizes: InboxSectionSizes;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare logService: LogService;
  @service declare inbox2TagsSearch: Inbox2TagsSearch;
  @service declare conditionalAttributesService: ConditionalAttributesService;

  @tracked activeInbox?: Inbox;
  @dedupeTracked activeConversation?: ConversationRecord;

  selectedConversations = useResource(this, ConversationSelectionResource, () => ({
    inbox: this.activeInbox,
  }));

  @tracked activeSidebarView: SidebarViewType = this.storedInboxListView;
  @tracked activeComposerLocation: ComposerLocation = ComposerLocation.ConversationPage;

  @tracked isShowingBulkEditModal = false;

  @tracked isShowingLinkReportsToTrackerModal = false;

  @tracked isConversationListHidden = false;

  @tracked conversationsExist?: boolean;

  @tracked showCreateMacroModal = false;

  @tracked liveWorkflows: any = null;

  @tracked selectedTrackerForLinking: ConversationTableEntry | undefined;

  conversationsThatUsedSuggestions = new TrackedSet<number>();

  @globalRef('inbox-list') declare inboxListElement: HTMLElement;

  conversationAttributesById(attributes: ConversationAttributeSummary[]) {
    return attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  faviconCounter = new FaviconCounter();
  private _adminsWhoCanManageTeammates: AdminSummary[] | undefined;

  get activeConversationId(): number | undefined {
    return this.activeConversation?.id;
  }

  get activeConversationHelpCenterId(): string | undefined {
    if (
      this.activeConversation instanceof Conversation ||
      this.activeConversation instanceof ConversationSummary
    ) {
      return this.activeConversation.helpCenterId;
    }
    return undefined;
  }

  get activeConversationsView() {
    let { currentRoute } = this.router;
    let forceView = currentRoute?.queryParams?.view as ConversationsViewType | undefined;
    let storedView = storage.get(LOCALSTORAGE_CONVERSATION_VIEW_KEY);

    if (forceView && forceView in ConversationsViewType) {
      return forceView;
    } else if (storedView && storedView in ConversationsViewType) {
      return storedView;
    } else {
      return ConversationsViewType.List;
    }
  }

  hideConversationList() {
    this.isConversationListHidden = true;
  }

  showConversationList() {
    this.isConversationListHidden = false;
    this.revertRHSBToOriginalSize();
  }

  showBulkEditModal() {
    this.isShowingBulkEditModal = true;
  }

  hideBulkEditModal() {
    this.isShowingBulkEditModal = false;
  }

  get storedInboxListView() {
    return storage.get(LOCALSTORAGE_INBOX_LIST_VIEW_KEY) || SidebarViewType.InboxList;
  }

  get hasSelectedConversations() {
    return this.selectedConversations.count > 0;
  }

  get isListView() {
    return this.activeConversationsView === ConversationsViewType.List;
  }

  get isTableView() {
    return (
      this.activeConversationsView === ConversationsViewType.Table ||
      this.activeConversationsView === ConversationsViewType.TableFullscreen
    );
  }

  // start: inbox section sizes
  get resizeStorageKey() {
    return this.inboxSectionSizes.resizeStorageKey;
  }

  @action
  loadSplitInstance(...params: Parameters<InboxSectionSizes['loadSplitInstance']>) {
    this.inboxSectionSizes.loadSplitInstance(...params);
  }

  @action
  clearSplitInstance() {
    this.inboxSectionSizes.clearSplitInstance();
  }

  expandRHSBtoHalfScreen() {
    this.inboxSectionSizes.expandRHSBtoHalfScreen();
  }

  revertRHSBToOriginalSize() {
    this.inboxSectionSizes.revertRHSBToOriginalSize();
  }

  // end: inbox section sizes

  get selectedTrackerForLinkingTitle() {
    let selectedTicket = this.selectedTrackerForLinking;

    return selectedTicket?.ticketTitle || selectedTicket?.ticketType?.name || '';
  }

  linkReportsErrorTranslationKey(errors: { [key: string]: string }) {
    let returnedErrorsList = Object.keys(errors).sort();
    let isSingleError =
      returnedErrorsList.length === 1 &&
      Object.values(BulkLinkError).includes(returnedErrorsList[0] as BulkLinkError);
    let isOnlyCategoryInvalidError = isEqual(
      [BulkLinkError.BackofficeInvalid, BulkLinkError.TrackerInvalid],
      returnedErrorsList,
    );

    if (isSingleError) {
      return Object.keys(errors)[0].replace(/_/g, '-');
    } else if (isOnlyCategoryInvalidError) {
      return 'category-failed-to-be-linked';
    } else {
      return 'items-failed-to-be-linked';
    }
  }

  async getAdminsWhoCanManageTeammates(): Promise<AdminSummary[] | undefined> {
    if (!this._adminsWhoCanManageTeammates) {
      let admins: AdminSummary[] = await this.inboxApi.getAdminsWhoCanManageTeammates();
      this._adminsWhoCanManageTeammates = admins.filter(
        ({ id }) => id !== this.session.teammate.id,
      );
    }
    return this._adminsWhoCanManageTeammates;
  }

  @action
  goBackToInboxList() {
    this.trackReturnEvent();

    if (this.router.isActive('inbox.workspace.search')) {
      this.router.transitionTo('inbox.workspace.inbox', this.session.workspace.id);
    } else {
      this.activeSidebarView = SidebarViewType.InboxList;
    }
  }

  @action setActiveComposer(location: ComposerLocation) {
    this.activeComposerLocation = location;
  }

  @action
  switchInbox(inbox: Inbox | AdminSummary, customFolderId: number | undefined) {
    this.selectedConversations.clear();

    if (inbox instanceof AdminSummary) {
      inbox = new Admin(inbox.openCount ?? 0, inbox);
    }

    let queryParams = {
      view: this.activeConversationsView,
      custom_folder_id: customFolderId ? customFolderId.toString() : undefined,
    };

    this.router.transitionTo('inbox.workspace.inbox.inbox', inbox, { queryParams });
  }

  @action toggleLeftNavVisibility() {
    if (this.activeSidebarView === SidebarViewType.InboxList) {
      this.activeSidebarView = SidebarViewType.Hidden;
      this.greatGuidanceService.isFloatingWidgetExpanded = false;
    } else {
      this.activeSidebarView = SidebarViewType.InboxList;
    }

    storage.set(LOCALSTORAGE_INBOX_LIST_VIEW_KEY, this.activeSidebarView);
  }

  @action toggleCreateMacroModal() {
    this.showCreateMacroModal = !this.showCreateMacroModal;
  }

  get isInboxListHidden() {
    return this.activeSidebarView === SidebarViewType.Hidden;
  }

  get isInboxListPinned() {
    return this.activeSidebarView === SidebarViewType.InboxList;
  }

  @action switchConversationsView(
    view: ConversationsViewType,
    options: { keyboardShortcutUsed?: boolean; section?: string | undefined } = {},
  ) {
    this.router.transitionTo({ queryParams: { view } });
    this.trackLayoutSwitchedEvent(view, options);
  }

  @action clearActiveConversation() {
    if (this.activeInbox) {
      this.activeConversation = undefined;
      this.router.transitionTo('inbox.workspace.inbox.inbox', this.activeInbox);
    }
  }

  @action closeLinkReportsToTrackerModal() {
    this.isShowingLinkReportsToTrackerModal = false;
    this.selectedTrackerForLinking = undefined;
  }

  @action selectTrackerForLinkingReports(conversation?: ConversationTableEntry) {
    this.selectedTrackerForLinking = conversation;
    this.isShowingLinkReportsToTrackerModal = true;
  }

  setInbox(inbox: Inbox) {
    this.activeInbox = inbox;
    this.faviconCounter.updateFaviconCounter(inbox.count);
  }

  clearInbox() {
    this.activeInbox = undefined;
    this.faviconCounter.resetFaviconCounter();
  }

  markAsRead(conversation: Conversation) {
    conversation.isRead = true;
    this.inboxApi.markAsRead(conversation);
    this.trigger(InboxEvents.ConversationRead, conversation);
  }

  markMentionsAsRead(conversation: ConversationRecord) {
    this.inboxApi.markMentionsAsRead(conversation);
  }

  markUnread(conversation: ConversationRecord) {
    this.inboxApi.markUnread(conversation.id);
    this.trigger(InboxEvents.ConversationUnread, conversation);
  }

  replyToEmailPart(conversationPart: any, conversationId?: number) {
    this.trigger(InboxEvents.ReplyToEmailPart, conversationPart, conversationId);
  }

  @action async replyToConversation(
    conversation: Conversation,
    blocks: BlockList,
    replyData?: ReplyDataType,
    participantData?: ParticipantDataType,
    emailHistoryMetadataId?: number,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        await this.reply(
          updates,
          conversation,
          blocks,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err: unknown) {
        captureException(err, {
          fingerprint: ['inbox-2-state', 'reply-to-conversation'],
        });

        if (err instanceof ResponseError && err?.response) {
          try {
            await this.handleReplyError(err.response);
          } catch (jsonError) {
            console.error(jsonError);
          }
        }

        if (conversation.ticketType) {
          this.snackbar.notifyError(this.intl.t('inbox.errors.reply.generic'));
          updates.rollback();
        } else {
          updates.markAsFailed();
        }
      }
    });
  }

  @action async retrySendReplyOrNote({
    isNote,
    conversation,
    part,
    replyData,
    participantData,
  }: {
    conversation: Conversation;
    part: RenderablePart & { renderableData: { blocks?: Block[] } };
    replyData?: ReplyDataType;
    participantData?: ParticipantDataType;
    isNote: boolean;
  }) {
    let blocks = part.renderableData.blocks ?? [];
    if (isNote) {
      return await this.addNoteToConversation(conversation, blocks);
    }

    return await this.replyToConversation(conversation, blocks, replyData, participantData);
  }

  @action async replyAndClose(
    conversation: Conversation,
    blocks: BlockList,
    macroActions: MacroAction[],
    replyData?: ReplyDataType,
    participantData?: ParticipantDataType,
    emailHistoryMetadataId?: number,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let update = updates.add('state-change', { state: ConversationState.Closed });

      let actions = macroActions.filter((action) => action.applyable);
      let macroParts = actions.map((action) =>
        this.applyMacroAction(updates, action, conversation),
      );

      try {
        await this.reply(
          updates,
          conversation,
          blocks,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
        await this.inboxApi.applyMacroActions([conversation.id], actions, macroParts);
        let parts = await this.inboxApi.closeConversation(conversation, update.part);

        let closeCopy = conversation.ticketType
          ? this.intl.t('inbox.notifications.ticket-closed')
          : this.intl.t('inbox.notifications.conversation-closed');
        this.notifyWithUndo(closeCopy, () => this.openConversation(conversation));

        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        if (err?.errorThrown === 'Forbidden') {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.lack-permissions'));
        } else {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.generic'));
        }

        captureException(err, {
          fingerprint: ['inbox-2-state', 'reply-and-close'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async replyAndSnooze(
    conversation: Conversation,
    blocks: BlockList,
    macroActions: MacroAction[],
    duration: DurationObject,
    replyData?: ReplyDataType,
    participantData?: ParticipantDataType,
    emailHistoryMetadataId?: number,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let update = updates.add('state-change', { state: ConversationState.Snoozed, duration });

      let actions = macroActions.filter((action) => action.applyable);
      let macroParts = actions.map((action) =>
        this.applyMacroAction(updates, action, conversation),
      );

      try {
        await this.reply(
          updates,
          conversation,
          blocks,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
        await this.inboxApi.applyMacroActions([conversation.id], actions, macroParts);
        await this.inboxApi.snoozeConversation(conversation, duration, update.part);
        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        if (err?.errorThrown === 'Forbidden') {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.lack-permissions'));
        } else {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.generic'));
        }

        captureException(err, {
          fingerprint: ['inbox-2-state', 'reply-and-snooze'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async replyAndSetState(
    conversation: Conversation,
    blocks: BlockList,
    macroActions: MacroAction[],
    ticketState: TicketCustomState,
    replyData?: ReplyDataType,
    participantData?: ParticipantDataType,
    emailHistoryMetadataId?: number,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let actions = macroActions.filter((action) => action.applyable);
      let macroParts = actions.map((action) =>
        this.applyMacroAction(updates, action, conversation),
      );

      try {
        let replyParts = await this.reply(
          updates,
          conversation,
          blocks,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
        await this.inboxApi.applyMacroActions([conversation.id], actions, macroParts);

        if (replyParts && replyParts.length > 0) {
          await this.inboxApi.changeTicketState(
            conversation,
            ticketState,
            undefined,
            undefined,
            replyParts[0]?.entityId,
          );
        } else {
          await this.inboxApi.changeTicketState(conversation, ticketState);
        }
        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        if (err?.errorThrown === 'Forbidden') {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.lack-permissions'));
        } else {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.reply.generic'));
        }

        captureException(err, {
          fingerprint: ['inbox-2-state', 'reply-and-set-state'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async updateAttributesAndClose(
    conversation: ConversationRecord,
    attributes: ConversationAttributeSummary[],
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let update = updates.add('state-change', { state: ConversationState.Closed });

      try {
        await Promise.all(
          attributes.map((attribute) => this.inboxApi.updateAttribute(conversation.id, attribute)),
        );

        let parts = await this.inboxApi.closeConversation(conversation, update.part);
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.update-attributes-and-close-error'),
        );

        captureException(err, {
          fingerprint: ['inbox-2-state', 'update-attributes-and-close'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async addNoteToConversation(
    conversation: Conversation,
    blocks: BlockList,
    crossPost = false,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let part = createRenderablePart(new AdminNote(blocks, this.session.teammate));

        let update = updates.add('add-part', {
          part,
        });

        let parts = await this.inboxApi.addNoteToConversation(
          conversation,
          blocks,
          update.part,
          crossPost,
        );
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        console.error(err);
        captureException(err, {
          fingerprint: ['inbox-2-state', 'add-note-to-conversation'],
        });

        if (conversation.ticketType) {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.note.generic'));
          updates.rollback();
        } else {
          updates.markAsFailed();
        }
      }
    });
  }

  @action async addSummaryToConversation(conversation: Conversation) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('add-part', {
          part: createRenderablePart(
            GeneratedConversationSummary.loading(this.inbox2AssigneeSearch.botAdmin),
          ),
        });

        let parts = await this.inboxApi.addSummaryToConversation(conversation, update.part);
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        updates.rollback();
        let type = conversation.isTicket ? 'ticket' : 'conversation';
        this.notificationsService.notifyError(
          err.jqXHR?.status === 422
            ? this.intl.t(`inbox.notifications.summarization-failed-too-long-${type}`)
            : this.intl.t(`inbox.notifications.summarization-failed-${type}`),
        );
        if (!err.jqXHR) {
          captureException(err, {
            fingerprint: ['inbox-2-state', 'add-summary-to-conversation'],
          });
          console.error(err);
        }
      }
    });
  }

  @action async closeConversation(
    conversation: ConversationRecord,
    checkRequiredAttributes = false,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('state-change', { state: ConversationState.Closed });

        let parts = await this.inboxApi.closeConversation(
          conversation,
          update.part,
          checkRequiredAttributes,
        );
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
        this.trigger(InboxEvents.ConversationClosed, conversation);
      } catch (err) {
        updates.rollback();

        if (
          err.jqXHR?.status === 422 &&
          err.jqXHR?.responseJSON?.error === 'missing_required_attributes'
        ) {
          throw new RequiredAttributesError();
        } else {
          this.notificationsService.notifyError(this.intl.t('inbox.errors.close.generic'));
          captureException(err, {
            fingerprint: ['inbox-2-state', 'close-conversation'],
          });
          console.error(err);
        }
      }
    });
  }

  @action async closeConversationAndCheckAttributes(conversation: ConversationRecord) {
    // should be instant as we'll have fetched these when displaying a conversation
    let isRequiredAttributePanelShown = await this.maybeShowRequiredAttributesPanel(conversation);
    if (isRequiredAttributePanelShown) {
      return false;
    }

    let checkRequiredAttributes = true;
    if (conversation.isTrackerTicket || conversation.isBackOfficeTicket) {
      checkRequiredAttributes = false;
    }

    try {
      await this.closeConversation(conversation, checkRequiredAttributes);
      return true;
    } catch (err: unknown) {
      if (err instanceof RequiredAttributesError) {
        this.trigger(InboxEvents.ShowRequiredAttributesPanel, conversation);
        return false;
      } else {
        throw err;
      }
    }
  }

  async maybeShowRequiredAttributesPanel(conversation: ConversationRecord): Promise<boolean> {
    let descriptors = await this.session.workspace.fetchConversationAttributeDescriptors();

    if (this.session.workspace.canUseConditionalAttributes) {
      descriptors = this.conditionalAttributesService
        .initialize({ conversation, descriptors })
        .visibleAttributes() as ConversationAttributeDescriptor[];
    }

    if (emptyRequiredAttributesForConversation(conversation, descriptors).length) {
      this.trigger(InboxEvents.ShowRequiredAttributesPanel, conversation);
      return true;
    }
    return false;
  }

  @action async openConversation(conversation: ConversationRecord) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('state-change', { state: ConversationState.Open });

        let parts = await this.inboxApi.openConversation(conversation, update.part);
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(this.intl.t('inbox.errors.open.generic'));
        captureException(err, {
          fingerprint: ['inbox-2-state', 'open-conversation'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async snoozeConversation(conversation: ConversationRecord, duration: DurationObject) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('state-change', { state: ConversationState.Snoozed, duration });

        this.notifyWithUndo(
          this.intl.t('inbox.notifications.conversation-snoozed', {
            time: this.intl.formatTime(duration.time, getDateFormat(moment(duration.time))),
          }),
          () => this.openConversation(conversation),
        );

        if (await this.hasNoLiveWorkflows()) {
          let nudgeDismissCount = localStorage.getItem('snooze-workflow-follow-up-dismiss-count');
          if (!nudgeDismissCount || parseInt(nudgeDismissCount, 10) < 3) {
            this.snackbar.notify('', {
              type: 'help',
              contentComponent: 'inbox2/left-nav/notification-follow-up-on-snooze',
              clearable: true,
              dismissalKey: 'snooze-workflow-follow-up',
              persistent: true,
            });
          }
        }

        let parts = await this.inboxApi.snoozeConversation(conversation, duration, update.part);
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(this.intl.t('inbox.errors.snooze.generic'));
        captureException(err, {
          fingerprint: ['inbox-2-state', 'snooze-conversation'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async assignConversationToAdmin(
    conversation: ConversationRecord,
    assignee: AdminSummary,
    trackingSection?: string,
    displayNotification = true,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let previousAssignee = conversation.adminAssignee?.clone() ?? AdminSummary.unassigned;

      let update = updates.add('assign', {
        type: 'admin',
        admin: assignee,
        currentAdmin: previousAssignee,
      });

      if (!assignee.isUnassignedAssignee && displayNotification) {
        this.notifyWithUndo(
          this.intl.t('inbox.notifications.conversation-assigned-to-admin', {
            name: assignee.name,
          }),
          () =>
            this.assignConversationToAdmin(conversation, previousAssignee, trackingSection, false),
        );
      }

      try {
        let parts = await this.inboxApi.assignConversationToAdmin(
          conversation,
          update.part,
          assignee,
          this.activeInbox,
          trackingSection,
          this.activeConversationsView,
        );

        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(this.intl.t('inbox.errors.assign.generic'));
        captureException(err, {
          fingerprint: ['inbox-2-state', 'assign-conversation-to-admin'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async assignConversationToTeam(
    conversation: ConversationRecord,
    assignee: TeamSummary,
    trackingSection?: string,
    displayNotification = true,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let previousAssignee = conversation.teamAssignee?.clone() ?? UnassignedTeamSummary;

      let update = updates.add('assign', {
        type: 'team',
        team: assignee,
        currentAdmin: conversation.adminAssignee,
        currentTeam: conversation.teamAssignee,
      });

      if (!assignee.isUnassignedAssignee && displayNotification) {
        this.notifyWithUndo(
          this.intl.t('inbox.notifications.conversation-assigned-to-team', { name: assignee.name }),
          () =>
            this.assignConversationToTeam(conversation, previousAssignee, trackingSection, false),
        );
      }

      try {
        let parts = await this.inboxApi.assignConversationToTeam(
          conversation,
          update.part,
          assignee,
          this.activeInbox,
          trackingSection,
          this.activeConversationsView,
        );

        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(this.intl.t('inbox.errors.assign.generic'));
        captureException(err, {
          fingerprint: ['inbox-2-state', 'assign-conversation-to-team'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async assignConversations(
    conversations: ConversationRecord[],
    assignee: AdminSummary | TeamSummary,
  ) {
    assignee instanceof AdminSummary
      ? await this.assignConversationsToAdmin(conversations, assignee)
      : await this.assignConversationsToTeam(conversations, assignee);
  }

  private async assignConversationsToTeam(
    conversations: ConversationRecord[],
    assignee: TeamSummary,
  ) {
    let conversationIds = conversations.map((conversation) => conversation.id);

    let updates = conversations.map((conversation) =>
      this.conversationUpdates.addUpdate(conversation.id, 'assign', {
        type: 'team',
        team: assignee,
        currentAdmin: conversation.adminAssignee,
        currentTeam: conversation.teamAssignee,
      }),
    );
    this.selectedConversations.clear();

    try {
      await this.inboxApi.bulkAssignConversationsToTeam(conversationIds, assignee);
      conversations.forEach((conversation) => {
        this.trigger(InboxEvents.ConversationUpdated, conversation);
      });
    } catch (err) {
      updates.forEach((update, index) => {
        this.conversationUpdates.rollbackUpdates(conversationIds[index], [update]);
      });
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.assign-to'));
      throw err;
    }
  }

  private async assignConversationsToAdmin(
    conversations: ConversationRecord[],
    assignee: AdminSummary,
  ) {
    let conversationIds = conversations.map((conversation) => conversation.id);

    let updates = conversations.map((conversation) =>
      this.conversationUpdates.addUpdate(conversation.id, 'assign', {
        type: 'admin',
        admin: assignee,
        currentAdmin: conversation.adminAssignee,
      }),
    );
    this.selectedConversations.clear();

    try {
      await this.inboxApi.bulkAssignConversationsToAdmin(conversationIds, assignee);
      conversations.forEach((conversation) => {
        this.trigger(InboxEvents.ConversationUpdated, conversation);
      });
    } catch (err) {
      updates.forEach((update, index) => {
        this.conversationUpdates.rollbackUpdates(conversationIds[index], [update]);
      });
      this.snackbar.notifyError(this.intl.t('inbox.bulk-edit.errors.assign-to'));
      throw err;
    }
  }

  @action async changePriority(conversation: ConversationRecord, priority: boolean) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('priority-change', { priority });

        let parts = await this.inboxApi.changePriority(conversation, priority, update.part);
        update.commit(parts);

        this.trigger(InboxEvents.ConversationUpdated, conversation);
      } catch (err) {
        this.notificationsService.notifyError(this.intl.t('inbox.errors.change-priority.generic'));
        captureException(err, {
          fingerprint: ['inbox-2-state', 'change-priority'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async changeTicketState(
    conversation: ConversationRecord,
    ticketState: TicketCustomState,
    trackingSection?: string,
    adminLabel?: string,
  ) {
    try {
      let response = await this.inboxApi.changeTicketState(
        conversation,
        ticketState,
        trackingSection,
        undefined,
        undefined,
        adminLabel,
      );
      return response;
    } catch (err) {
      console.error(err);
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversations-table.errors.ticket-state'),
      );
      if (
        conversation instanceof Conversation &&
        conversation.lastPart &&
        conversation.lastPart.pending
      ) {
        conversation.removePendingPart(conversation.lastPart);
      }
      return;
    }
  }

  @action async changeTicketStateLocalUpdates(
    conversation: Conversation | ConversationSummary | ConversationTableEntry,
    ticketState: TicketCustomState,
    trackingSection?: string,
  ) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      try {
        let update = updates.add('ticket-state-change', {
          ticketState: ticketState.systemState,
          visibleToUser: conversation.visibleToUser,
          conversationId: conversation.id,
          ticketTypeId: conversation.ticketType?.id,
          ticketCustomStateId: ticketState.id,
        });

        let parts = await this.inboxApi.changeTicketState(
          conversation,
          ticketState,
          trackingSection,
          update.part,
        );

        update.commit(parts);
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.conversations-table.errors.ticket-state'),
        );
        captureException(err, {
          fingerprint: ['inbox-2-state', 'ticket-state-change'],
        });
        console.error(err);
        updates.rollback();
      }
    });
  }

  @action async updateConversationTitle(
    conversation: Conversation,
    newTitle = '',
    previousTitle = '',
  ) {
    conversation.setTitle(newTitle);
    let { part } = this.createTitleChangedPart(conversation, newTitle, previousTitle);
    try {
      await this.inboxApi.updateConversationTitle(conversation, newTitle, part);
    } catch {
      conversation.setTitle(previousTitle);
      this.notificationsService.notifyError(this.intl.t('inbox.subject-update-error'));
    }
    this.trigger(InboxEvents.ConversationUpdated, conversation);
  }

  @action async closeConversations(
    ids: number[],
    closeAndResolve = false,
    bulkParams?: {
      id: number;
      linkedCustomerReports: number[] | string;
    },
  ) {
    let conversations = ids.map((id) =>
      this.selectedConversations.conversationObjects.findBy('id', id),
    ) as ConversationSummary[];

    let updates = this.conversationUpdates.addUpdates(ids, 'state-change', {
      state: ConversationState.Closed,
    });

    this.selectedConversations.clear();

    try {
      let response = await this.inboxApi.closeConversations(ids, closeAndResolve, bulkParams);

      // If any conversations could not be closed because of missing attributes,
      // we revert them to their original state.
      if ('error' in response && response.error === 'missing_required_attributes') {
        let invalidIds = response.invalid_conversation_ids as number[];

        let invalidConversations = conversations.filter((conversation) =>
          invalidIds.includes(conversation.id),
        );

        invalidConversations.forEach((conversation) => {
          this.selectedConversations.toggle(conversation);
          this.conversationUpdates.rollbackUpdates(conversation.id, [updates[conversation.id]]);
        });

        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.partial-close', { count: invalidIds.length }),
        );

        return invalidConversations;
      }

      if (
        'error' in response &&
        response.error === 'tracker_ticket_linked_customer_reports_out_of_date'
      ) {
        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.tracker-ticket-linked-customer-reports-out-of-date'),
        );

        throw new Error('Tracker ticket linked customer reports out of date');
      }

      this.trigger(InboxEvents.ConversationUpdated, {});
      return [];
    } catch (err) {
      conversations.forEach((conversation) => {
        this.selectedConversations.toggle(conversation);
        this.conversationUpdates.rollbackUpdates(conversation.id, [updates[conversation.id]]);
      });

      this.trigger(InboxEvents.ConversationUpdated, {});
      throw err;
    }
  }

  @action async closeLinkedConversations(ids: number[]) {
    let updates = this.conversationUpdates.addUpdates(ids, 'state-change', {
      state: ConversationState.Closed,
    });

    try {
      let response = await this.inboxApi.closeConversations(ids, false);

      // If any conversations could not be closed because of missing attributes,
      // we revert them to their original state.
      if ('error' in response && response.error === 'missing_required_attributes') {
        let invalidIds = response.invalid_conversation_ids as number[];

        invalidIds.forEach((id) => {
          this.conversationUpdates.rollbackUpdates(id, [updates[id]]);
        });

        this.snackbar.notifyError(
          this.intl.t('inbox.bulk-edit.errors.partial-close', { count: invalidIds.length }),
        );
      }

      this.trigger(InboxEvents.ConversationUpdated, {});
    } catch (err) {
      ids.forEach((id) => {
        this.conversationUpdates.rollbackUpdates(id, [updates[id]]);
      });

      this.trigger(InboxEvents.ConversationUpdated, {});
      throw err;
    }
  }

  @action async openConversations(ids: number[]) {
    let conversations = ids.map((id) =>
      this.selectedConversations.conversationObjects.findBy('id', id),
    ) as ConversationSummary[];

    let updates = this.conversationUpdates.addUpdates(ids, 'state-change', {
      state: ConversationState.Open,
    });

    this.selectedConversations.clear();

    try {
      await this.inboxApi.openConversations(ids);

      this.trigger(InboxEvents.ConversationUpdated, {});
    } catch (err) {
      conversations.forEach((conversation) => {
        this.selectedConversations.toggle(conversation);
        this.conversationUpdates.rollbackUpdates(conversation.id, [updates[conversation.id]]);
      });

      this.trigger(InboxEvents.ConversationUpdated, {});
      throw err;
    }
  }

  @action async changeConversationsPriority(
    conversationIds: ConversationRecord['id'][],
    priority: boolean,
  ) {
    let conversations = conversationIds.map((id) =>
      this.selectedConversations.conversationObjects.findBy('id', id),
    );

    let updates = this.conversationUpdates.addUpdates(conversationIds, 'priority-change', {
      priority,
    });

    this.selectedConversations.clear();

    try {
      await this.inboxApi.changeConversationsPriority(conversationIds, priority);
    } catch (err) {
      conversationIds.forEach((conversationId, index) => {
        let conversation = conversations[index];
        conversation && this.selectedConversations.toggle(conversation);
        this.conversationUpdates.rollbackUpdates(conversationId, [updates[conversationId]]);
      });

      throw err;
    }
  }

  @action async applyBulkMacroActions(
    conversationIds: number[],
    actions: MacroAction[],
    trackerTicketParams?: {
      id: number;
      linkedCustomerReports: 'all';
    },
  ) {
    let response = await this.inboxApi.applyMacroActions(
      conversationIds,
      actions,
      [],
      trackerTicketParams,
    );

    this.selectedConversations.clear();
    this.trigger(InboxEvents.ConversationUpdated, {});

    this.trackBulkActionsExecuted(actions, { count: conversationIds.length });

    return response;
  }

  @action async createLinkedTicket(conversation: Conversation, data: Record<string, any> = {}) {
    let response = await this.inboxApi.createLinkedTicket(data);

    this.trigger(InboxEvents.ConversationUpdated, conversation);
    return response;
  }

  @action async linkReportsToTracker() {
    let { conversationObjects: conversations, ids: conversationIds } = this.selectedConversations;
    let trackerTicketId = this.selectedTrackerForLinking?.id;

    if (!trackerTicketId) {
      return;
    }

    this.selectedConversations.clear();
    this.isShowingLinkReportsToTrackerModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'link_reports',
      reports_count: conversations.length,
    });

    try {
      let response = await this.inboxApi.linkReportsToTracker(conversationIds, trackerTicketId);

      if ('error_codes' in response) {
        let invalidIds = response.invalid_conversation_ids as number[];

        let invalidConversations = conversations.filter((conversation) =>
          invalidIds.includes(conversation.id),
        );

        invalidConversations.forEach((conversation) => {
          this.selectedConversations.toggle(conversation);
        });

        let translationKey = this.linkReportsErrorTranslationKey(response.error_codes);
        let messagePrefix = this.intl.t(
          `inbox.bulk-edit.errors.invalid-ticket-for-linking-prefix`,
          {
            invalidCount: invalidIds.length,
            totalCount: this.selectedConversations.count,
          },
        );
        let messageSuffix = this.intl.t(`inbox.bulk-edit.errors.${translationKey}`, {
          count: invalidIds.length,
        });
        this.snackbar.notifyError(`${messagePrefix}${messageSuffix}`, {
          persistent: true,
          clearable: true,
        });

        return;
      }

      this.snackbar.notify(
        this.intl.t('inbox.bulk-edit.reports-linked', {
          count: conversationIds.length,
          trackerTitle: this.selectedTrackerForLinkingTitle,
          url: this.router.urlFor(
            'inbox.workspace.inbox.conversation.conversation',
            trackerTicketId,
          ),
          htmlSafe: true,
        }),
      );
    } catch (err) {
      conversations.forEach((conversation) => {
        this.selectedConversations.toggle(conversation);
      });

      throw err;
    }

    this.selectedTrackerForLinking = undefined;
  }

  @task({ enqueue: true }) *updateTags(
    conversation: Conversation,
    renderablePart: TaggablePart,
    tags: Tag[],
  ): TaskGenerator<void> {
    let update = this.conversationUpdates.addUpdate(conversation.id, 'update-tags', {
      part: renderablePart,
      tags,
    });

    try {
      let response = yield this.inboxApi.updateTags(conversation, renderablePart, tags);
      if (response && !(response.errors && response.errors.length)) {
        // If any new tags were added, update them with the IDs from the backend
        if (tags.some((tag) => !tag.id)) {
          let newTags: Tag[] = [];
          let updatedTags = tags.map((tag) => {
            if (!tag.id) {
              let createdTag = response.tags.find((t: Tag) => t.name === tag.name);
              if (createdTag) {
                let newTag = Tag.deserialize(createdTag);
                newTags = [...newTags, newTag];
                return newTag;
              }
            }

            return tag;
          });

          conversation.updateTagsForRenderablePart(renderablePart.entityId, updatedTags);
          this.inbox2TagsSearch.addTags(newTags);
        }
      } else {
        throw new Error('Update tags failed');
      }
    } catch {
      this.conversationUpdates.rollbackUpdates(conversation.id, [update]);
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversation-stream.tags-update-error'),
      );
    } finally {
      update.commit();
    }
  }

  @task({ restartable: true }) *triggerWorkflow(
    conversation: Conversation | ConversationTableEntry,
    workflowDetails: WorkflowDetails,
  ): TaskGenerator<void> {
    yield this.inboxApi.triggerWorkflow(conversation, workflowDetails);
  }

  @task({ restartable: true }) *triggerWorkflowConnectorAction(
    conversation: Conversation | ConversationTableEntry,
    renderablePart: TaggablePart | null,
    action: WorkflowConnectorAction,
  ): TaskGenerator<void> {
    yield this.inboxApi.triggerWorkflowConnectorAction(conversation, renderablePart, action);
  }

  @task({ restartable: true }) *triggerFinAiAgent(
    conversation: Conversation | ConversationTableEntry,
  ): TaskGenerator<void> {
    yield this.inboxApi.triggerFinAiAgent(conversation);
  }

  @task *deleteMessage(conversation: Conversation, part: RenderablePart, deletedBy: AdminSummary) {
    let deletedPart: RenderablePart | undefined;
    try {
      deletedPart = createDeletedPart(part, deletedBy);
      conversation.updatePart(part, deletedPart);
      yield this.inboxApi.deleteMessage(conversation, part);
      this.snackbar.notify(this.intl.t('inbox.notifications.message-deleted'));
    } catch (error) {
      if (deletedPart) {
        conversation.updatePart(deletedPart, part);
      }
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversation-stream.delete-message-error'),
      );
    }
  }

  @task *deleteInitialPart(
    conversation: Conversation,
    part: RenderablePart,
    deletedBy: AdminSummary,
  ) {
    let deletedPart: RenderablePart | undefined;
    try {
      deletedPart = createDeletedInitialPart(part, deletedBy);
      conversation.updatePart(part, deletedPart);
      yield this.inboxApi.deleteMessage(conversation, part);
      this.snackbar.notify(this.intl.t('inbox.notifications.message-deleted'));
    } catch (error) {
      if (deletedPart) {
        conversation.updatePart(deletedPart, part);
      }
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversation-stream.delete-message-error'),
      );
    }
  }

  async createNewConversation(
    data: NewConversationWireFormat,
    macroActions: MacroAction[],
    notify = true,
  ): Promise<{ id: number }> {
    let conversation = await this.inboxApi.createNewConversation(data);
    if (notify) {
      this.snackbar.notify(this.intl.t('inbox.notifications.conversation-created'));
    }

    let actions = macroActions.filter((action) => action.applyable);
    if (actions.length > 0) {
      await this.inboxApi.applyMacroActions([conversation.id], actions);
    }

    return conversation;
  }

  async createNewSideConversation(
    data: Record<string, unknown>,
    macroActions: MacroAction[],
  ): Promise<{ id: number }> {
    let sideConversation = await this.inboxApi.createNewSideConversation(data);

    let actions = macroActions.filter((action) => action.applyable);
    if (actions.length > 0) {
      await this.inboxApi.applyMacroActions([sideConversation.id], actions);
    }

    return sideConversation;
  }

  async applyMacroActions(conversation: Conversation, macroActions: MacroAction[]) {
    return this.conversationUpdates.do(conversation, async (updates) => {
      let actions = macroActions.filter((action) => action.applyable);
      let parts = actions.map((action) => this.applyMacroAction(updates, action, conversation));

      try {
        await this.inboxApi.applyMacroActions([conversation.id], actions, parts);
      } catch (err) {
        updates.rollback();
        captureException(err);
      }
    });
  }

  async updateConversationParticipants(
    conversation: Conversation,
    participantData: ParticipantDataType,
  ) {
    let conversationId = conversation.id;
    let notification = this.snackbar.notify(
      this.intl.t('inbox.notifications.participants-updating'),
      {
        persistent: true,
      },
    );

    try {
      storage.remove(
        latestUpdatedParticipantsStorageKey(this.session.workspace.id, conversationId),
      );

      let response = await this.inboxApi.updateConversationParticipants(
        conversationId,
        participantData,
      );

      this.snackbar.notify(this.intl.t('inbox.notifications.participants-updated'));
      this.trigger(InboxEvents.ConversationUpdated, conversation);
      return response;
    } catch (err) {
      let errors = err?.jqXHR?.responseJSON?.errors as Record<string, string>;

      if (!errors) {
        this.snackbar.notify(this.intl.t('inbox.notifications.participants-update-request-failed'));
      } else {
        Object.keys(errors).forEach((email) => {
          this.snackbar.notify(
            this.intl.t('inbox.notifications.participants-update-error', {
              email,
              errorMessage: errors[email],
            }),
            {
              persistent: true,
              clearable: true,
            },
          );
        });
      }
    } finally {
      this.snackbar.clearNotification(notification);
    }
  }

  createTemporaryConversationFromSummary(
    summary: ConversationSummary | ConversationTableEntry,
    isLoading = true,
  ): Conversation {
    return new Conversation({
      id: summary.id,
      redacted: summary.redacted,
      renderableParts: [],
      userSummary: summary.user,
      participantSummaries: 'participantSummaries' in summary ? summary.participantSummaries : [],
      title: summary.title,
      nextBreachTime: summary.nextBreachTime,
      state: ConversationState.Open,
      priority: summary.priority ?? false,
      isLoading,
      attributes: summary.attributes ?? [],
      visibleToUser: summary.visibleToUser,
      channel: new ChannelData(Channel.Unknown, Channel.Unknown),
      createdAt: summary.createdAt,
      ticketType: summary.ticketType,
      canReplyToUser: summary.canReplyToUser,
      ticketCategory: summary.ticketCategory,
      linkedConversationIds: summary.linkedConversationIds,
      linkedCustomerTicketIds: summary.linkedCustomerTicketIds,
      ticketState: summary.ticketState,
      ticketCustomStateId: summary.ticketCustomStateId,
      ticketId: summary.ticketId,
    });
  }

  getDefaultSort(
    inbox: InboxIdentifier | undefined,
    state: InboxStateOption = this.getDefaultState(inbox as Inbox),
  ) {
    let defaultSort = InboxSortOption.Newest;

    if (inbox) {
      defaultSort =
        storage.get(
          `inbox-default-sort-${this.session.workspace.id}-${inbox.id}-${inbox.type}-${state}`,
        ) ||
        storage.get(`inbox-default-sort-${this.session.workspace.id}-${inbox.id}-${inbox.type}`) ||
        defaultSort;
    }

    return defaultSort;
  }

  setDefaultSort(inbox: InboxIdentifier, sort: InboxSortOption, state: InboxStateOption) {
    storage.set(
      `inbox-default-sort-${this.session.workspace.id}-${inbox.id}-${inbox.type}-${state}`,
      sort,
    );
  }

  getDefaultState(inbox?: Inbox) {
    let defaultState = ConversationState.Open;

    if (inbox) {
      let storageState = storage.get(
        `inbox-default-state-${this.session.workspace.id}-${inbox.id}-${inbox.type}`,
      );
      if (inbox instanceof View && inbox.viewSummary.isAiAgentResolvedView) {
        defaultState = storageState || ConversationState.Closed;
      } else {
        defaultState = storageState || defaultState;
      }
    }

    return defaultState;
  }

  setDefaultState(inbox: InboxIdentifier, state: InboxStateOption) {
    storage.set(
      `inbox-default-state-${this.session.workspace.id}-${inbox.id}-${inbox.type}`,
      state,
    );
  }

  async blockUser(options: { id: string; conversation: Conversation }) {
    let blockPayload: { id: string; conversation_id?: number } = {
      id: options.id,
    };
    let isSpamConversation = options.conversation?.participantSummaries?.length === 1;
    if (isSpamConversation) {
      blockPayload.conversation_id = options.conversation.id;
    }
    if (isSpamConversation) {
      this.trigger(InboxEvents.ConversationRemoved, options.conversation);
    }
    await this.inboxApi.blockUser(blockPayload);
  }

  private trackReturnEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'returned',
      object: 'inbox',
    });
  }

  private trackLayoutSwitchedEvent(
    view: ConversationsViewType,
    options: { keyboardShortcutUsed?: boolean; section?: string | undefined } = {},
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'switched',
      object: 'layout',
      place: 'inbox',
      layout_type: view,
      shortcut_key: options?.keyboardShortcutUsed,
      section: options?.section,
    });
  }

  private trackBulkActionsExecuted(actions: MacroAction[], opts: { count: number }) {
    let types = actions.map((action) => action.type);

    this.intercomEventService.trackAnalyticsEvent({
      object: 'bulk_macros',
      action: 'executed',
      inbox_type: this.activeInbox?.type,
      layout_type: this.activeConversationsView,
      assign: types.any(
        (a) =>
          a === 'assign-conversation-to-teammate' ||
          a === 'assign-conversation-to-team' ||
          a === 'assign-conversation-to-owner',
      ),
      close: types.any((a) => a === 'close-conversation'),
      snooze: types.any((a) => a === 'snooze-conversation'),
      tag: types.any((a) => a === 'add-tag-to-conversation'),
      priority: types.any((a) => a === 'change-conversation-priority'),
      cda: types.any((a) => a === 'set-conversation-data-attribute'),
      has_text: types.any((a) => a === 'reply-to-conversation'),
      conversations_selected: opts.count,
    });
  }

  private createTitleChangedPart(
    conversation: Conversation,
    newTitle: string,
    previousTitle: string,
  ) {
    let part = conversation.addPendingPart(
      new TitleChanged(this.session.teammate, newTitle, previousTitle),
    );

    return { part };
  }

  private applyMacroAction(
    updates: UpdatesApi,
    action: MacroAction,
    conversation: Conversation,
  ): RenderablePart | undefined {
    let { data, type } = action;
    let update;

    if (type === 'close-conversation') {
      update = updates.add('state-change', { state: ConversationState.Closed });
    }

    if (type === 'snooze-conversation') {
      if (!data || !('snoozed_until' in data)) {
        throw new Error(
          `snooze-conversation action requires snoozed_until in macro data, but data was ${data}`,
        );
      }

      let duration = new DurationObject(
        getDurationType(data.snoozed_until),
        undefined,
        moment.tz.guess(true),
      );

      this.logService.log({
        log_type: 'snoozeConversation',
        app_id: this.session.workspace.id,
        conversation_id: conversation.id,
        admin_id: this.session.teammate.id,
        snoozed_until: data.snoozed_until,
        author_timezone: data.author_timezone,
        moment_cache_js_author_timezone: moment.tz.guess(),
        moment_no_cache_js_author_timezone: moment.tz.guess(true),
        browser_author_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      update = updates.add('state-change', {
        state: ConversationState.Snoozed,
        duration,
      });
    }

    if (
      [
        'assign-conversation',
        'assign-conversation-to-team',
        'assign-conversation-to-teammate',
      ].includes(action.type)
    ) {
      let data = action.data!.assignee_summary as
        | ({ type: 'team' } & TeamSummaryWireFormat)
        | ({ type: 'admin' } & AdminSummaryWireFormat);

      if (data.type === 'admin') {
        let admin = AdminSummary.deserialize(data);
        update = updates.add('assign', {
          type: 'admin',
          admin,
          currentAdmin: conversation.adminAssignee?.clone() ?? AdminSummary.unassigned,
        });
      } else {
        let team = TeamSummary.deserialize(data);
        update = updates.add('assign', {
          type: 'team',
          team,
          currentAdmin: conversation.adminAssignee?.clone() ?? AdminSummary.unassigned,
          currentTeam: conversation.teamAssignee,
        });
      }
    }

    if (action.type === 'change-conversation-priority') {
      update = updates.add('priority-change', { priority: true });
    }

    if (action.type === 'add-tag-to-conversation') {
      let data = action.data!.tag_summary as TagWireFormat;
      let tag = Tag.deserialize(data);
      let [part] = conversation.renderableParts;
      if (!isTaggable(part)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      let tags = [...((part as TaggablePart).renderableData.tags ?? []), tag].uniqBy('id');
      conversation.updateTagsForRenderablePart(part.entityId, tags);
      return;
    }

    return update?.part;
  }

  private async reply(
    updates: UpdatesApi,
    conversation: Conversation,
    blocks: BlockList,
    replyData?: ReplyDataType,
    participantData?: ParticipantDataType,
    emailHistoryMetadataId?: number,
  ) {
    this.adminAwayService.maybeDisplayModalPrompt();

    let [nonTicketBlocks, ticketBlock] = this.splitTicketAndNonTicketBlocks(blocks);

    let replyPart = undefined;
    let replyUpdate = undefined;
    let replyParts = undefined;

    if (nonTicketBlocks && nonTicketBlocks.length > 0) {
      replyPart = createRenderablePart(
        new AdminComment(
          nonTicketBlocks,
          this.session.teammate,
          [],
          undefined,
          undefined,
          conversation.channel.replyChannel,
        ),
      );
      replyUpdate = updates.add('add-part', { part: replyPart });
    }

    if (replyPart && replyUpdate) {
      replyParts = await this.inboxApi.replyToConversation(
        conversation,
        nonTicketBlocks,
        replyPart,
        replyData,
        participantData,
        emailHistoryMetadataId,
      );

      replyUpdate.commit(replyParts);
    }

    let ticketCardPart = undefined;
    let ticketCardUpdate = undefined;
    if (ticketBlock && ticketBlock.length > 0) {
      ticketCardPart = createRenderablePart(
        new AdminComment(ticketBlock, this.session.teammate, []),
      );

      ticketCardUpdate = updates.add('add-part', { part: ticketCardPart });
    }

    if (ticketCardPart && ticketCardUpdate) {
      let ticketCardParts = await this.inboxApi.replyToConversation(
        conversation,
        ticketBlock,
        ticketCardPart,
        replyData,
        participantData,
      );
      ticketCardUpdate.commit(ticketCardParts);
    }

    if (replyParts) {
      this.finQuestionAnswers.maybeRequestQuestionAnswer(conversation, blocks, replyParts);
    }
    return replyParts;
  }

  private notifyWithUndo(text: string, onUndo: () => void) {
    this.snackbar.notify(text, {
      buttonLabel: this.intl.t('inbox.notifications.undo'),
      onButtonClick: (notification) => {
        this.snackbar.clearNotification(notification);
        onUndo();
      },
      contentComponent: NOTIFCATION_UNDO_CONTENT_COMPONENT,
      clearable: true,
    });
  }

  private splitTicketAndNonTicketBlocks(blocks: BlockList): [BlockList, BlockList | undefined] {
    let ticketBlock = undefined;
    let nonTicketBlocks = blocks;

    if (nonTicketBlocks[nonTicketBlocks.length - 1].type === 'createTicketCard') {
      ticketBlock = nonTicketBlocks.slice(nonTicketBlocks.length - 1);
      nonTicketBlocks = nonTicketBlocks.slice(0, nonTicketBlocks.length - 1);
    }

    return [nonTicketBlocks, ticketBlock];
  }

  private async hasNoLiveWorkflows() {
    await this.getLiveWorkflows();
    return this.liveWorkflows?.totalCount < 1;
  }

  private async getLiveWorkflows() {
    try {
      // @ts-ignore
      let liveWorkflowsSearch: any = await this.contentSearch({
        object_types: [
          objectTypes.customBot,
          objectTypes.inboundCustomBot,
          objectTypes.buttonCustomBot,
          objectTypes.triggerableCustomBot,
        ],
        states: [states.live],
        match_behaviors: [],
        app_id: this.session.workspace.id,
        per_page: 2,
      });
      this.liveWorkflows = liveWorkflowsSearch;
    } catch (e) {
      console.error(e);
    }
  }

  private async contentSearch(searchParams: any) {
    let response: any = await ajax({
      url: '/ember/content_service/contents/search',
      type: 'GET',
      data: searchParams,
    }).catch(() => {});

    return {
      totalCount: response.total_count,
    };
  }

  private async handleReplyError(errorResponse: any) {
    let json = await errorResponse.json();

    let isNoAccessError = json?.errors?.[0]?.message === NO_ACCESS_ERROR;
    if (errorResponse.status === 403 && (json.key || isNoAccessError)) {
      let key = json.key;
      if (isNoAccessError) {
        key = 'lack-permissions';
      }

      this.snackbar.notifyError(this.intl.t(`inbox.errors.reply.${key}`));
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    inboxState: InboxState;
    'inbox-state': InboxState;
  }
}
