/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import {
  PRICING_5_0_ADVANCED_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { captureException } from 'embercom/lib/sentry';
import { Promise as EmberPromise, reject, resolve } from 'rsvp';

export const PAYWALL_CLOSED_MESSAGE = 'store closed';

let defaultParams = {
  featureName: '',
  productIds: undefined,
  appId: undefined,
  action: undefined,
  comment: undefined,
  transition: undefined,
  checkInStep: undefined,
  hideSuccessPage: undefined,
};

export default Service.extend({
  appService: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  permissionsService: service('permissions-service'),
  loadingIndicatorService: service(),
  modelDataCacheService: service('model-data-cache-service'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  store: service(),
  router: service(),
  purchaseAnalyticsService: service(),
  earlyStageApplicationRoute: 'apps.app.teams-checkout.confirm',
  earlyStageApplicationRouteParameters: computed('app.signedUpWithSolutionId', function () {
    let solutionId = this.solutionId(this.app.signedUpWithSolutionId);
    return {
      queryParams: {
        solution_id: solutionId,
      },
    };
  }),

  init() {
    this._super(...arguments);
    this.handleError = this.handleError.bind(this);
  },

  shouldUseQuoteService(planId) {
    return this.app.onPricing5 || `${planId}` === FIN_AI_COPILOT_BASE_ID;
  },

  featuresToHide() {
    let featuresToHide = new Set();

    if (!this.app.showMultiHelpCenterPaywalling) {
      featuresToHide.add('multi_help_center');
    }

    if (!this.app.canUseFinAiCopilotAddon) {
      featuresToHide.add('limited_fin_ai_copilot_access_billing_feature');
    }

    featuresToHide.add('workflows_core_billing_feature');
    featuresToHide.add('workflows_outbound_billing_feature');
    featuresToHide.add('ai_answers_billing_feature');
    featuresToHide.add('ai_assist_summarize_conversation');
    featuresToHide.add('side_conversations_billing_feature');
    featuresToHide.add('twitter_integration');
    featuresToHide.add('phone');
    featuresToHide.add('copilot_seat_type_billing_feature');
    featuresToHide.add('hipaa_support_billing_feature');

    return featuresToHide;
  },

  solutionId(signedUpWithSolutionId) {
    if (`${signedUpWithSolutionId}` === PRICING_5_X_EARLY_STAGE_SOLUTION_ID) {
      return parseInt(PRICING_5_X_EARLY_STAGE_SOLUTION_ID, 10);
    } else if (`${signedUpWithSolutionId}` === PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID) {
      return parseInt(PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID, 10);
    } else if (`${signedUpWithSolutionId}` === PRICING_5_0_ADVANCED_SOLUTION_ID) {
      return parseInt(PRICING_5_X_EARLY_STAGE_SOLUTION_ID, 10);
    }
    return parseInt(VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID, 10);
  },

  /**
   * Open the paywall to a particular state.
   * @returns {Promise}
   */
  open({ productIds, solutionId, planId, place } = defaultParams) {
    return this._run({
      productIds,
      solutionId,
      planId,
      place,
    });
  },

  /**
   * Open the paywall if the customer does not have the provided featureName through their subscription.
   * Returns immediately if the customer already has the provided featureName.
   * Returns immediately if the customer already has the provided planId.
   * The promise is resolved if the customer buys something in the store.
   * The promise is rejected if the customer closes the store without buying anything.
   * @returns {Promise}
   */
  paywall({ planId, featureName, comment, transition } = defaultParams) {
    if (this.isPaywallActive({ featureName, planId })) {
      return this.permissionsService.checkPermission('can_access_billing_settings').then(
        () => {
          return this._run({
            planId,
            featureName,
            comment,
            transition,
          });
        },
        () => {
          return reject('You do not have permission to access billing and payment details');
        },
      );
    } else {
      return resolve();
    }
  },

  redirectToPurchase(featureName) {
    if (this._shouldRedirectForEarlyStage()) {
      this._redirectForEarlyStage();
      return;
    }

    if (this.app.hasValueBasedPricing) {
      this.router.transitionTo('apps.app.billing', this.appService.app.id);
      return;
    }

    let currentPath = this.router.currentURL;
    let query = `after_signup_redirect_path=${encodeURIComponent(currentPath)}`;
    if (featureName) {
      query += this._addOnQueryParams(featureName);
    }
    if (this.app.canUseFree) {
      query += '&free=true&from_product=true';
    }

    let redirectUrl = `${ENV.APP.marketingSiteUrl}/pricing?${query}`;
    if (currentPath.includes('home')) {
      window.location.href = redirectUrl;
    } else {
      window.location.replace(redirectUrl);
    }
  },

  _addOnQueryParams(featureName) {
    let featureMap = {
      answer_workflows: '&current_tab=support_tab&add_on=answer_bot',
      bot_workflows: '&current_tab=acquire_tab&add_on=custom_bots',
      product_tours: '&current_tab=engage_tab&add_on=tours',
    };
    return featureMap[featureName];
  },

  /**
   * @returns {Void}
   * @public
   */
  handleError(e) {
    if (e && e.message === PAYWALL_CLOSED_MESSAGE) {
      return;
    }

    if (e === undefined) {
      return;
    }

    try {
      captureException(e || new Error('Unknown Paywall Store error'));
    } catch (err) {
      // Do nothing
    }
  },

  /**
   * @returns {Bool}
   * @public
   */
  isPaywallActive({ featureName, planId } = defaultParams) {
    let hasFeatureName = Boolean(featureName);
    if (!hasFeatureName && !planId) {
      return false;
    }

    if (featureName && this.app.canUseFeature(featureName)) {
      return this._isFreeFeatureLimited(featureName);
    } else if (planId) {
      let activeProductForPlan = this.app.activeProductByPlanId(planId);
      return !(activeProductForPlan && activeProductForPlan.get('activePlan.id') === planId);
    }
    return true;
  },

  _isFreeFeatureLimited(featureName) {
    if (!this.app.get('canUseFree')) {
      return false;
    }
    let feature = this.app.get('features').findBy('id', featureName);
    return feature && feature.get('limited');
  },

  _planIdsFromProducts(productIds) {
    let products = this.app.products;
    return productIds.map((productId) => {
      let product = products.findBy('id', productId);
      return product.get('plans.firstObject.id');
    });
  },

  _planIdsWithFeature(featureName) {
    let products = this.app.products;
    if (!this.app.hasActiveSubscription) {
      products = products.filter(function (product) {
        return !product.get('addon');
      });
    }

    return products
      .map((product) => {
        let planWithFeature = product.plans.find((plan) => {
          let featureKeys = plan.features.mapBy('key');
          return featureKeys.includes(featureName);
        });

        if (!planWithFeature) {
          return;
        }
        return planWithFeature.id;
      })
      .compact();
  },

  _isFeatureAnAddon(featureName) {
    let products = this.app.products;
    let isAddon = false;
    products.forEach((product) => {
      product.plans.find((plan) => {
        let featureKeys = plan.features.mapBy('key');
        if (featureKeys.includes(featureName) && product.addon) {
          isAddon = true;
        }
      });
    });

    return isAddon;
  },

  /**
   * @returns {Promise}
   * @private
   */
  _run({ featureName, productIds = [], planId, solutionId, place = 'paywall' } = defaultParams) {
    let planIds = [];
    if (featureName) {
      planIds = this._planIdsWithFeature(featureName);
    } else if (productIds.length) {
      planIds = this._planIdsFromProducts(productIds);
    } else if (planId) {
      planIds = [planId];
    }

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'paywall',
      context: 'paywall',
      place,
      feature: featureName,
      planIds,
      productIds,
      solutionId,
    });

    if (this._shouldRedirectForEarlyStage()) {
      this.redirectToPurchase();
      return resolve();
    }
    if (this.app.hasActiveSubscription || planIds.length === 1) {
      return new EmberPromise((resolve, reject) => {
        this.modalService.openModal(
          'paywalls/render-paywall',
          { featureKey: featureName, launchPaywall: true },
          {},
          {},
          { onClose: reject },
        );
      });
    }

    this.router.transitionTo('apps.app.billing.details', this.appService.app.id, {
      queryParams: { featureName, planIds, solutionId },
    });
    return resolve();
  },

  /**
   * @returns {String}
   * @private
   */
  _location() {
    return getOwner(this)
      .lookup('controller:application')
      .get('currentPath')
      .replace('apps.app.', '')
      .replace('.index', '')
      .replace(/\./g, '/');
  },

  _shouldRedirectForEarlyStage() {
    return this.app.validEarlyStageApplicant && !this.app.hasActiveSubscription;
  },
  _redirectForEarlyStage() {
    return this.router.transitionTo(
      this.earlyStageApplicationRoute,
      this.appService.app.id,
      this.earlyStageApplicationRouteParameters,
    );
  },
});
