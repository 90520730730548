/* RESPONSIBLE TEAM: team-proactive-support */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { isValidUrlWithProtocol } from 'embercom/lib/url-validator';
import { actionOptions } from 'embercom/models/banners/banner';

const BannerActionUrlValid = BaseValidator.extend({
  intl: service(),

  validate(value, options, model) {
    let localizedBannerContent = model.localizedBannerContents.firstObject;
    if (
      model.action !== actionOptions.open_a_url &&
      model.action !== actionOptions.open_url_button
    ) {
      return true;
    }

    let actionHref = localizedBannerContent.actionHref;

    if (isBlank(actionHref)) {
      return this.intl.t('outbound.banners.validations.enter-a-url');
    }

    // Skip the URL validation if it contains a CDA since we can't reliably validate.
    // Avoids cases like https://github.com/intercom/intercom/issues/138897
    if (actionHref.includes('{{') && actionHref.includes('}}')) {
      return true;
    }

    if (!isValidUrlWithProtocol(actionHref)) {
      return this.intl.t('outbound.banners.validations.enter-a-valid-url');
    }

    return true;
  },
});

BannerActionUrlValid.reopenClass({
  getDependentsFor() {
    return ['model.action', 'model.localizedBannerContents.firstObject.actionHref'];
  },
});

export default BannerActionUrlValid;
