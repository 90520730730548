/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import { isEmpty, isNone } from '@ember/utils';
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import Metrics from 'embercom/models/metrics';

export default Service.extend({
  realTimeEventService: service('realTimeEventService'),
  store: service(),

  init() {
    this._super(...arguments);
    this.start();
  },

  start() {
    this.realTimeEventService.subscribeTopics([`user-general`]);
    this.realTimeEventService.on('UserDetails', this, 'updateUserData');
    this.realTimeEventService.on('UserMerged', this, 'updateParticipant');
    this.realTimeEventService.on('CompanyDetails', this, 'updateCompany');
  },

  willDestroy() {
    this.realTimeEventService.off('UserDetails', this, 'updateUserData');
    this.realTimeEventService.off('UserMerged', this, 'updateParticipant');
    this.realTimeEventService.off('CompanyDetails', this, 'updateCompany');
    this.realTimeEventService.unsubscribeTopics([`user-general`]);
  },

  app: computed(function () {
    return getOwner(this).lookup('route:apps/app').get('app');
  }),

  async updateUserData(userData) {
    if (!this.app.canUseUserUpdaterListeners) {
      return;
    }

    let user = await this.store.findRecord('user', userData.id);
    if (user) {
      user.displayAsDidUpdate = true;
    }
  },

  updateParticipant(userData) {
    if (!this.app.canUseUserUpdaterListeners) {
      return;
    }

    this.updateParticipantConversations(userData);
  },

  updateParticipantConversations(userData) {
    let allConversations = getOwner(this)
      .lookup('controller:apps/app/inbox/inbox/conversations')
      .get('query.content');
    if (allConversations) {
      let conversations = allConversations.filterBy('main_participant.id', userData.leadId);
      conversations.forEach((conversation) => {
        conversation.syncedPartList.sync();
        conversation.set('lead_shares_email_with_user', false);
      });
    }
  },

  async updateCompany(companyData) {
    if (!this.app.canUseUserUpdaterListeners) {
      return;
    }

    return await this.store.findRecord('company', companyData.id);
  },

  updateCompanyData(companyData) {
    let company = this.store.peekRecord('company', companyData.id);
    if (!company) {
      this.store.createRecord('company', companyData);
      return this.updateCompanyData(companyData);
    }
    this.updateData(company, companyData);
    return company;
  },

  updateData(model, data) {
    let userDetailsDidUpdate = false;
    let displayAsDidUpdate = false;

    Object.keys(data).forEach((attribute) => {
      let newValue = data[attribute];
      let currentValue = model.get(attribute);
      if (this._shouldUpdateAttribute(attribute, newValue, currentValue)) {
        try {
          if (attribute === 'companies') {
            let hadNoCompanies = isEmpty(currentValue);
            newValue.forEach((companyData) => {
              let company = this.updateCompanyData(companyData);
              if (hadNoCompanies) {
                currentValue.addObject(company);
              }
            });
          } else {
            model.set(attribute, newValue);
          }
          userDetailsDidUpdate = true;
          displayAsDidUpdate = displayAsDidUpdate || attribute === 'display_as';
        } catch (e) {
          Metrics.capture({ increment: ['user_updater_service_error'] });
        }
      }
    });

    if (userDetailsDidUpdate) {
      model.set('_lastEdit', new Date());
    }

    model.setProperties({ userDetailsDidUpdate, displayAsDidUpdate });
  },

  _shouldUpdateAttribute(attribute, newValue, currentValue) {
    if (attribute === 'eventName') {
      return false;
    } else if (isNone(newValue) && isNone(currentValue)) {
      return false;
    } else {
      return !_.isEqual(newValue, currentValue);
    }
  },
});
