/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { isPresent } from '@ember/utils';
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { readOnly } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import NexusIntercom from 'embercom/vendor/intercom/nexus';
import Metrics from 'embercom/models/metrics';
import ENV from 'embercom/config/environment';
import Admin from 'embercom/models/admin';
import { captureException } from 'embercom/lib/sentry';
import NexusInitFailure from 'embercom/components/notifications/nexus-init-failure';

const EVENTS = [
  'AdminAwayReassignModeChange',
  'AdminIsTyping',
  'AdminIsTypingANote',
  'ArticleStatsUpdate',
  'ArticleSyncError',
  'CampaignMessagePreview',
  'CompanyDetails',
  'ContentStatsUpdate',
  'ConversationAttributesUpdated',
  'ConversationPartUpdated',
  'ConversationReadByAdmin',
  'ConversationSeen',
  'ConversationTopicPropagatedStatusChanged',
  'CountersUpdated',
  'CsvImportUserCreateProgress',
  'CsvImportUserTagProgress',
  'ExternalReplySendStateUpdated',
  'ForwardMailSetupInstructions',
  'ForwardMailVerified',
  'ImportStarted',
  'ImportCompleted',
  'MixpanelImportUserCreateProgress',
  'MacroExecutionError',
  'MailchimpImportUserCreateProgress',
  'MentionCreated',
  'MessageStatsUpdate',
  'MessengerCardUpdated',
  'NewMinimumVersion',
  'PositiveRatingAdded',
  'ReceivedForwardEmailConfirmationCode',
  'ReceivedForwardEmailConfirmationLink',
  'SavedReplyCreated',
  'SavedReplyDeleted',
  'SavedReplyUpdated',
  'SeriesActivationStatus',
  'SeriesDeactivationStatus',
  'TeammateLinkDomainPoliciesUpdated',
  'ThreadAssigned',
  'ThreadClosed',
  'ThreadCreated',
  'ThreadIngested',
  'ThreadReopened',
  'ThreadSnoozed',
  'ThreadUnsnoozed',
  'ThreadUpdated',
  'UnsubscribeIntegrationVerified',
  'UserBlocked',
  'UserDetails',
  'UserIsTyping',
  'UserMerged',
  'CustomDomainProcessed',
  'BulkDeactivationStatus',
  'BulkArchiveStatus',
  'TaggingStatusUpdated',
  'ContentImportRunStatus',
  'FileSourceRunStatus',
  'MessengerFirstPing',
  'AiContentIngestionJobStatusChanged',
  'FirstCustomDataPing',
  'WorkflowSetLive',
  'OutboundMessageSetLive',
  'ImportServiceImportJobStatusChanged',
  'ImportServiceImportSourceWithContentDeleted',
  'ImportServiceImport',
  'KnowledgeBaseBulkActionProgressUpdate',
  'ArticleSyncSettingsDeleted',
  'ZendeskImportStatusChanged',
  'MarkStepAsCompleted',
  'FinPlaygroundQuestionResponse',
  'HistoricalConversationImportProgress',
  'InboundScannedLinksUpdated',
];

const NEXUS_INIT_ERROR_KEY = 'nexus-init-failure';

export default Service.extend(Evented, {
  notificationsService: service('notificationsService'),
  store: service(),
  currentAdminId: readOnly('app.currentAdmin.id'),

  inboxEvents: [
    'ThreadAssigned',
    'ThreadReopened',
    'ThreadCreated',
    'ThreadClosed',
    'ThreadUpdated',
    'ThreadSnoozed',
    'ThreadUnsnoozed',
    'ConversationAttributesUpdated',
    'ConversationPartUpdated',
    'TeammateLinkDomainPoliciesUpdated',
    'InboundScannedLinksUpdated',
  ],
  init() {
    this._super(...arguments);
    let nexus = NexusIntercom.create();
    this.set('nexus', nexus);

    $(document).on('click', 'a.js__realtime-service-reloader', bind(this, this.reloadWindow));
  },

  reloadWindow() {
    window.location.reload();
  },

  initForApp(app) {
    this.set('app', app);
    this.nexus
      .initForApp(app.get('id'), {
        longPollingEnabled: false,
      })
      .catch((e) => {
        captureException(e, {
          fingerprint: ['real-time-event-service', 'initForApp'],
          extra: {
            app_id: app.get('id'),
          },
        });
        Metrics.capture({ increment: ['realtime-service.initForAppFailed'] });
        this.notificationsService.removeNotification(NEXUS_INIT_ERROR_KEY);
        this.notificationsService.notifyErrorWithModelAndComponent(
          {},
          NexusInitFailure,
          ENV.APP.notificationNoTimeout,
          NEXUS_INIT_ERROR_KEY,
        );
      });
    this._setUpEventListeners();
    this.subscribeTopics([`admin/${this.currentAdminId}`]);
  },

  unsubscribe() {
    if (this.isDestroying) {
      return;
    }
    this.unsubscribeTopics([`admin/${this.currentAdminId}`]);
    this.nexus.unsubscribe();
    this.set('app', null);
  },

  subscribeTopics(topics) {
    this.nexus.subscribeTopics(topics);
  },

  unsubscribeTopics(topics) {
    this.nexus.unsubscribeTopics(topics);
  },

  sendAdminIsTypingEvent(admin, conversation) {
    let eventData = this._adminIsTypingEventData(admin, conversation);
    this.nexus.throttleSendUserEvent(
      conversation.get('main_participant.id'),
      'AdminIsTyping',
      eventData,
    );
  },

  sendAdminIsTypingANoteEvent(admin, conversation) {
    let eventData = this._adminIsTypingEventData(admin, conversation);
    this.nexus.throttleSendEvent('AdminIsTypingANote', eventData);
  },

  sendUserContentSeenByAdminEvent(conversation) {
    let eventData = this._userContentSeenData(conversation);
    this.nexus.sendUserEvent(
      conversation.get('main_participant.id'),
      'UserContentSeenByAdmin',
      eventData,
    );
  },

  _setUpEventListeners() {
    let nexus = this.nexus;
    EVENTS.forEach((eventName) => {
      let handler = this[`_handle${eventName}Event`] || this._handleGeneric;
      nexus.addListener(eventName, bind(this, handler));
    });
  },

  _handleGeneric(data) {
    this.trigger(data.eventName, this._flattenEventData(data));
  },

  _handleConversationSeenEvent(data) {
    let conversation = this._findConversation(data.eventData.conversationId);
    if (conversation) {
      conversation.updateConversationReadAt();
    }
  },

  _handleUserIsTypingEvent(data) {
    try {
      let conversation = this._findConversation(data.eventData.conversationId);
      if (conversation) {
        conversation.updateUserIsTyping();
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['real-time-event-service', '_handleUserIsTypingEvent'],
        extra: {
          data,
        },
      });
    }
  },

  _handleConversationReadByAdminEvent(data) {
    let conversation = this._findConversation(data.eventData.conversationId);
    if (isPresent(conversation)) {
      conversation.set('admin_has_read', true);
    }
  },

  _handleAdminIsTypingEvent(data) {
    this._typingEvent(data, 'comment');
  },

  _handleAdminIsTypingANoteEvent(data) {
    this._typingEvent(data, 'note');
  },

  _handleExternalReplySendStateUpdatedEvent(data) {
    let conversation = this._findConversation(data.eventData.conversationId);
    if (isPresent(conversation)) {
      conversation.handleExternalReplySendStateRealtimeEvent(data.eventData);
    }
  },

  _typingEvent(data, action) {
    let conversation = this._findConversation(data.eventData.conversationId);
    if (conversation) {
      let typingAdmin = Admin.peekAndMaybeLoad(this.store, data.eventData.adminId);
      let currentAdmin = this.get('app.currentAdmin');

      if (typingAdmin !== currentAdmin) {
        conversation.updateAdminIsTyping(typingAdmin, action);
      }
    }
  },

  _findConversation(conversationId) {
    return this.store.peekRecord('conversation', conversationId);
  },

  _adminIsTypingEventData(admin, conversation) {
    return {
      conversationId: conversation.get('id'),
      adminName: admin.get('first_name'),
      hasDefaultAvatar: admin.get('has_default_avatar'),
      adminAvatar: admin.get('avatar').image_urls.square_128,
      adminId: admin.get('id'),
    };
  },

  _userContentSeenData(conversation) {
    return {
      conversationId: conversation.get('id'),
    };
  },

  _flattenEventData(data) {
    return Object.assign(data.eventData, { eventName: data.eventName });
  },
});
