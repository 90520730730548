/* RESPONSIBLE TEAM: team-actions */

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { ZendeskObjectTypes } from 'embercom/components/settings/data-import/migrate-from-zendesk';
import { CrmLogStatus } from 'embercom/components/settings/data-import/migration-history';
import { PAGE_SIZE } from 'embercom/components/settings/data-import/migration-history';

export default class CrmLogsService extends Service {
  @service declare session: Session;

  async loadCrmLogs(
    integrationCode: string,
    page: number,
    status: CrmLogStatus,
    sourceObjectTypes: string[],
  ): Promise<any> {
    let dataImportHistory = await taskFor(this.fetchCrmLogs).perform(
      integrationCode,
      page,
      status,
      sourceObjectTypes,
    );
    return dataImportHistory;
  }

  async checkFailedCrmLogsExists(integrationCode: string): Promise<boolean> {
    let response = await taskFor(this.fetchCrmLogs).perform(
      integrationCode,
      1,
      CrmLogStatus.Failed,
      Object.values(ZendeskObjectTypes),
    );
    return response.total_count > 0;
  }

  @task({ enqueue: true })
  *fetchCrmLogs(
    integrationCode: string,
    page: number,
    status: CrmLogStatus,
    objectTypes: string[],
  ): TaskGenerator<any> {
    let response = yield get(`/ember/migrate_from_crm_integrations/data_import_history`, {
      app_id: this.session.workspace.id,
      integration_code: integrationCode,
      direction: 'crm_to_intercom',
      per_page: PAGE_SIZE,
      page,
      status,
      source_object_types: objectTypes.join(','),
    });

    return response;
  }
}

declare module '@ember/service' {
  interface Registry {
    dataCrmLogsService: CrmLogsService;
    'data/crm-logs-service': CrmLogsService;
  }
}
