/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { resolve, Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import LRUCache from 'embercom/lib/lru-cache';
import User from 'embercom/models/user';

const cacheSize = 100;

export default Service.extend({
  _cache: null,
  _pendingFetches: null,
  store: service(),

  init() {
    this._super();
    this.set('_pendingFetches', {});
    this.set('_cache', new LRUCache(cacheSize));
    let store = this.store;
    this._cache.shift = function () {
      let entry = LRUCache.prototype.shift.call(this);
      store.unloadRecord(entry.value);
      return entry;
    };
  },

  willDestroy() {
    this.clear();
    this._cache;
    this.set('_pendingFetches', {});
  },

  getUser(id) {
    return this._getUser(id);
  },

  getUserAndRefreshCache(id) {
    return this._getUser(id, true);
  },

  _getUser(id, refreshCache = false) {
    if (!id) {
      throw new Error(`Invalid user id ${id}`);
    }
    let cachedUser = this._getCachedUser(id);
    if (!cachedUser) {
      return this._fetchAndCache(id);
    }
    if (refreshCache) {
      this._fetchAndCache(id);
    }
    // check
    return resolve(cachedUser);
  },

  _getCachedUser(id) {
    let cachedUser = this._cache.get(id);
    if (cachedUser && !cachedUser.isDestroyed && !cachedUser.isDestroying) {
      return cachedUser;
    }
  },

  prefetch(id) {
    if (!id) {
      throw new Error(`Invalid user id ${id}`);
    }
    let cachedUser = this._getCachedUser(id);
    if (!cachedUser) {
      this._fetchAndCache(id);
    }
  },

  stuff(records) {
    records.forEach((record) => {
      this._updateCacheWithUserOrLead(record);
    });
  },

  _isUserOrLead(record) {
    return record && (record.typeLabel === 'User' || record.typeLabel === 'Lead');
  },

  _updateCacheWithUserOrLead(record) {
    if (this._isUserOrLead(record)) {
      this._updateCache(record);
    }
  },

  _updateCache(record) {
    let userId = record.get('id');
    // we don't need any special casing for records already in the cache
    // because Ember data will take care of this for us.
    this._cache.set(userId, record);
  },

  prefetchForConversation(conversation) {
    let main_participant = conversation.get('main_participant.id');
    if (main_participant) {
      this.prefetch(main_participant);
    }
  },

  clear() {
    this._cache.removeAll();
  },

  _fetchAndCache(id) {
    if (this._pendingFetches[id]) {
      return this._pendingFetches[id];
    }

    this._pendingFetches[id] = new EmberPromise((resolve, reject) => {
      User.getMaybeMergedUser(id)
        .then((user) => {
          delete this._pendingFetches[id];
          if (user) {
            this._updateCacheWithUserOrLead(user);
          } else {
            this._cache.remove(id);
          }
          resolve(user);
        })
        .catch((error) => {
          delete this._pendingFetches[id];
          reject(error);
        });
    });

    return this._pendingFetches[id];
  },
});
