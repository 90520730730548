/* RESPONSIBLE TEAM: team-data-foundations */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  idpSsoTargetUrl: {
    descriptionKey: 'validations.saml-account.url-description',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.saml-account.url-cant-be-blank',
      }),
      validator('format', {
        type: 'url',
        messageKey: 'validations.saml-account.valid-url',
      }),
    ],
  },
  idpCert: {
    descriptionKey: 'validations.saml-account.cert-description',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.saml-account.cert-cant-be-blank',
      }),
      validator('format', {
        regex: /^-----BEGIN CERTIFICATE-----[A-z0-9+=\/\r\n ]+-----END CERTIFICATE-----$/,
        messageKey: 'validations.saml-account.valid-cert',
      }),
    ],
  },
  appSsoDomains: {
    descriptionKey: 'validations.saml-account.domains-description',
    validators: [
      validator('length', {
        min: 1,
        messageKey: 'validations.saml-account.domain-valid',
      }),
      validator('has-many'),
    ],
  },
});
