/* RESPONSIBLE TEAM: team-data-foundations */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { isArray } from '@ember/array';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  collectionKey: 'users',

  attrs: {
    companies: {
      serialize: 'records',
      deserialize: 'records',
    },
    notes: { key: 'note_ids' },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
  },

  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
    // single user responses look like multi-user responses, e.g. { users: [{ ... }] }
    // I'm making this also work with normal looking single-user responses so that we can
    // change this easily on the back end. It also makes testing easier (because the factories look
    // more like you'd expect them to)
    if (this.collectionKey && isArray(payload[this.collectionKey])) {
      payload = this.extractArrayPayload(primaryModelClass.modelName, payload).firstObject;
    } else if (requestType === 'createRecord' && payload.user) {
      // the user creation modal expects a different response type
      // once this ships we should make the responses from this endpoint more consistent
      payload = payload.user;
    }
    return this._super(store, primaryModelClass, payload, id, requestType);
  },
});
