/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type ConversationExtractionSettings from 'embercom/models/content-service/conversation-extraction-settings';
import type KnowledgeHubService from './knowledge-hub-service';
import { type HistoricalConversationImportStatus } from 'embercom/models/content-service/conversation-extraction-settings';
import type IntlService from 'ember-intl/services/intl';

export const FEEDBACK_SURVEY_ID = 43267949;
export const IMPORT_PROGRESS_EVENT_ID = 'HistoricalConversationImportProgress';
export const IMPORT_PROGRESS_TOPIC = 'historical-conversation-import-progress';
const NOTIFICATION_TIME = 10000;

export interface ImportProgressEvent {
  status: HistoricalConversationImportStatus;
  admin_id: number;
}

export default class ConversationExtractionService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare realTimeEventService: {
    on: Function;
    off: Function;
    subscribeTopics: Function;
    unsubscribeTopics: Function;
  };
  @service declare notificationsService: {
    notifyResponseError: Function;
    notifyConfirmationWithButton: Function;
    notifyConfirmation: Function;
  };
  @service declare intl: IntlService;

  @tracked conversationExtractionSettings?: ConversationExtractionSettings;

  async fetchConversationExtractionSettings(): Promise<void> {
    try {
      let records = await this.store.findAll('content-service/conversation-extraction-settings');
      this.conversationExtractionSettings = records.firstObject;
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  openFeedbackSurvey() {
    window.Intercom('startSurvey', FEEDBACK_SURVEY_ID);
  }

  async goToContent(openInNewTab = true) {
    let folderId = this.conversationExtractionSettings?.folderID;
    if (!folderId) {
      return;
    }
    await this.knowledgeHubService.goToFolder(folderId, {}, openInNewTab);
  }

  subscribeToImportProgressEvents() {
    this.realTimeEventService.subscribeTopics([IMPORT_PROGRESS_TOPIC]);
    this.realTimeEventService.on(IMPORT_PROGRESS_EVENT_ID, this, 'handleImportProgressEvent');
  }

  unsubscribeFromImportProgressEvents() {
    this.realTimeEventService.unsubscribeTopics([IMPORT_PROGRESS_TOPIC]);
    this.realTimeEventService.off(IMPORT_PROGRESS_EVENT_ID, this, 'handleImportProgressEvent');
  }

  async handleImportProgressEvent(event: ImportProgressEvent) {
    if (event.status === 'finished') {
      await this.handeFinishedEvent(event);
    }
    // for everyone else, fetch the latest settings to update the status
    await this.fetchConversationExtractionSettings();
  }

  async handeFinishedEvent(event: ImportProgressEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id === event.admin_id.toString()) {
      // only notify the admin who initiated the import
      this.notifyAdminForFinishedEvent(event.status);
    }
  }

  notifyAdminForFinishedEvent(status: HistoricalConversationImportStatus) {
    let notificationMessage = this.intl.t(
      `knowledge-hub.conversational.nexus-events.historical-conversation-import-progress.${status}.message`,
    );
    let buttonMessage = this.intl.t(
      `knowledge-hub.conversational.nexus-events.historical-conversation-import-progress.${status}.button`,
    );

    this.notificationsService.notifyConfirmationWithButton(
      notificationMessage,
      {
        label: buttonMessage,
        action: this.goToContent.bind(this),
      },
      NOTIFICATION_TIME,
    );
  }

  async disableFeature(onFeatureDisabled: Function) {
    if (!this.conversationExtractionSettings) {
      return;
    }

    await this.knowledgeHubService.ensurePermissions();

    try {
      this.conversationExtractionSettings.enabled = false;
      await this.conversationExtractionSettings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.success',
        ),
      );
      onFeatureDisabled();
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.failure',
        ),
      });
    }
  }

  maybeRollbackConversationExtractionSettings() {
    if (this.conversationExtractionSettings?.hasDirtyAttributes) {
      this.conversationExtractionSettings?.rollbackAttributes();
    }
  }
}
