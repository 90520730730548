/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import type HelpCenterSite from 'embercom/models/help-center-site';
import { isEmpty } from 'underscore';

export function normalizeZendeskUrl(url: string) {
  url = url?.trim();

  if (isEmpty(url)) {
    return;
  }

  if (url.match(/^http:\/\//)) {
    url = url.substring(7);
  }

  if (url.match(/^https/) === null) {
    url = `https://${url}`;
  }

  let HELP_CENTER_REGEX = /\/hc/;
  // This conditional will strip out the help center centric part of the URL
  if (url.match(HELP_CENTER_REGEX)) {
    let index = url.match(HELP_CENTER_REGEX)?.index;
    url = url.substring(0, index);
  }

  return url;
}

export function isZendeskUrlInvalid(url: string): boolean {
  let normalizedUrl = normalizeZendeskUrl(url);

  if (!normalizedUrl) {
    return false;
  }

  try {
    let parsedUrl = new URL(normalizedUrl);
    // Hostname should only contain alphanumeric characters, dots, and hyphens
    return !parsedUrl.hostname.match(/^[a-zA-Z0-9.-]+\.zendesk\.com$/);
  } catch (e) {
    return true;
  }
}

export function generateZendeskOAuthURL(
  url: string,
  oauthStateId: string,
  helpCenter: HelpCenterSite,
  mode: 'import' | 'sync' = 'import',
): string {
  let stateJson = JSON.stringify({ oauth_state_id: oauthStateId, mode });

  let redirectUri = helpCenter.zendeskImporterRedirectUri;
  let clientId = helpCenter.zendeskImporterClientId;

  let queryParams = encodeURI(
    `response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&scope=hc:read&state=${stateJson}`,
  );

  return `${url}/oauth/authorizations/new?${queryParams}`;
}
