/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  type Action,
  ParentAction,
  SimpleAction,
  ChildAction,
} from 'embercom/objects/inbox/command-k/action';
import { getOwner } from '@ember/application';
import AssignToAction from 'embercom/objects/inbox/command-k/assign-to';
import SnoozeAction from 'embercom/objects/inbox/command-k/snooze';
import UseMacroAction from 'embercom/objects/inbox/command-k/use-macro';
import TriggerWorkflowAction from 'embercom/objects/inbox/command-k/trigger-workflow';
import {
  TriggerWorkflowConnectorAction,
  TriggerWorkflowConnectorActionPart,
} from 'embercom/objects/inbox/command-k/trigger-workflow-connector-action';
import SwitchLanguageAction from 'embercom/objects/inbox/command-k/switch-language';
import EmojiAction from 'embercom/objects/inbox/command-k/emoji';
import GifsAction from 'embercom/objects/inbox/command-k/gifs';
import JumpToAction from 'embercom/objects/inbox/command-k/jump-to';
import { assert } from '@ember/debug';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isEmpty } from '@ember/utils';
import BulkEditAction from 'embercom/objects/inbox/command-k/bulk-edit';
import type InboxState from 'embercom/services/inbox-state';
import ChangeStateAction from 'embercom/objects/inbox/command-k/change-state';
import InsertArticleAction from 'embercom/objects/inbox/command-k/insert-article';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type Session from './session';
import storage from 'embercom/vendor/intercom/storage';
import type InboxVersion from './inbox-version';
import JumpToAppDetailsAction from 'embercom/objects/inbox/command-k/jump-to-app-details';
import {
  TagCompany,
  TagConversationPartAction,
  TagFirstUserConversationPartAction,
  TagLastUserConversationPartAction,
  TagLead,
  TagUser,
} from 'embercom/objects/inbox/command-k/tag-item';
import type QuickSearchService from './quick-search';
import InsertAttribute from 'embercom/objects/inbox/command-k/insert-attribute';
import InsertTicketCard from 'embercom/objects/inbox/command-k/insert-ticket-card';
import CreateLinkedTicket from 'embercom/objects/inbox/command-k/create-linked-ticket';
import ConvertToTicket from 'embercom/objects/inbox/command-k/convert-to-ticket';
import MergeInto from 'embercom/objects/inbox/command-k/merge-into';
import AIAssist from 'embercom/objects/inbox/command-k/ai-assist';
import type IntlService from 'embercom/services/intl';
import SetLightThemeAction from 'embercom/objects/inbox/command-k/set-light-theme';
import SetDarkThemeAction from 'embercom/objects/inbox/command-k/set-dark-theme';
import SetSystemThemeAction from 'embercom/objects/inbox/command-k/set-system-theme';
import InsertWhatsappTemplateAction from 'embercom/objects/inbox/command-k/insert-whatsapp-template';
import type Paywall from 'embercom/components/paywall';
import type MessengerApps from './messenger-apps';
import UpdateTicketStateAction from 'embercom/objects/inbox/command-k/update-ticket-state';

// @ts-ignore
import { cached } from 'tracked-toolbox';
import { type EmberKeyboardEvent } from 'embercom/lib/inbox2/types';
import SearchTicketAction from 'embercom/objects/inbox/command-k/search-ticket';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import ComposeNew from 'embercom/objects/inbox/command-k/compose-new';
import CreateTaskTicket from 'embercom/objects/inbox/command-k/create-task-ticket';
import ConvertToCustomerTicket from 'embercom/objects/inbox/command-k/convert-to-customer-ticket';
import ResolveAndCloseTicketStateAction from 'embercom/objects/inbox/command-k/resolve-and-close-ticket-state';

export type CommandKMetadata = Record<string, any>;

export const SECOND_LEVEL_PAGE_SIZE = 10;

export enum DisplayContext {
  Global = 'global',
  CreateMacro = 'create-macro-modal',
  BulkEdit = 'bulk-edit-modal',
  MessageActions = 'conversation-part-message-actions',
  SideConversation = 'side-conversation',
  SideConversationMessageActions = 'side-conversation-part-message-actions',
  mergeInto = 'merge-into',
}

export interface ActionRegistration {
  actionID: string;
  onSelect: (
    selectedValue?: any,
    context?: any,
    metadata?: CommandKMetadata,
    trackingData?: any,
  ) => void;
  onCancel?: () => void;
  onClose?: () => void;
  context?: any;
  priority?: number;
  trackingData?: object;
  trackingDataFromSelectValue?: (value: any) => object;
  activatingComponent?: `${DisplayContext}`;
  paywall?: Paywall;
}

export default class CommandKService extends Service {
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inboxVersion: InboxVersion;
  @service declare quickSearch: QuickSearchService;
  @service declare messengerApps: MessengerApps;

  @tracked activeActionsMap: Map<string, Array<ActionRegistration>> = new Map();

  // Whether or not the command+k modal should be visible
  @tracked isVisible = false;

  // The current action we have selected in the modal
  @tracked current: ParentAction | null = null;

  // (Optional) Element that the command-k modal is pinned to
  @tracked pinnedToElement?: HTMLElement;

  // (Optional) Styles for the command-k container
  @tracked containerStyle = '';

  // Current context in which the Command K is being used / shown
  @tracked currentActivationContext: DisplayContext = DisplayContext.Global;

  // If the current action was triggered by a shortcut, we store the data here
  // so that we can use it later for tracking when an action is selected.
  @tracked currentActivationShortcut?: string;

  // We store the source of the activation here so that we can use it later for tracking
  @tracked currentActivationSource?: string;

  // if we opened command K on a specific action registration
  // then we store that here so that we can run onCancel/onClose
  // if the we close without performing it
  entrypoint?: ActionRegistration;

  // (optional) active paywall for a feature inside cmd-k
  @tracked activePaywallForFeature?: string;

  // All the authorised action ids that will be rendered on the screen for the collaborator view
  authorisedForCollaborator = [
    'compose-note',
    'emoji',
    'upload-attachment',
    'gifs',
    'upload-image',
    'toggle-dark-mode',
    'switch-language',
    'keyboard-shortcut',
    'copy-conversation-part-link',
  ];

  onHide?: () => void;

  private _registeredActions?: Array<SimpleAction | ParentAction>;

  // escape hatch for testing purposes
  set registeredActions(actions: Array<SimpleAction | ParentAction>) {
    this._registeredActions = actions;
  }

  // This is the list of all possible actions we can use
  // only the active ones will be displayed in the modal
  get registeredActions(): Array<SimpleAction | ParentAction> {
    let owner = getOwner(this);
    if (
      this._registeredActions &&
      this.messengerAppsActions.every((action) => this._registeredActions?.includes(action)) &&
      (!this.productToursExists || this.productToursRegistered)
    ) {
      return this._registeredActions;
    }

    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    this._registeredActions = [
      new SimpleAction({
        owner,
        id: 'navigate-to',
        icon: 'arrow',
        hidden: !this.session.workspace.isFeatureEnabled('team-product-guidance-global-search'),
      }),
      new SimpleAction({
        owner,
        id: 'compose-reply',
        icon: 'chat-filled',
        shortcutId: HotkeyID.ComposeReply,
      }),
      new SimpleAction({
        owner,
        id: 'compose-note',
        icon: 'note',
        shortcutId: HotkeyID.ComposeNote,
      }),
      new UseMacroAction({ owner }),
      new SimpleAction({
        owner,
        id: 'ask-fin',
        icon: new NamedIcon('ai'),
        shortcutId: HotkeyID.OpenCopilot,
      }),
      new AIAssist({ owner }),
      new SimpleAction({
        owner,
        id: 'open-knowledge-base',
        icon: {
          name: 'campaign',
          set: 'standard',
          type: 'icon',
        },
        shortcutId: HotkeyID.OpenKnowledgeBasePanel,
      }),
      new TriggerWorkflowAction({ owner }),
      new TriggerWorkflowConnectorAction({ owner }),
      ...this.messengerAppsActions,
      new SimpleAction({
        owner,
        id: 'goto-app-store',
        icon: 'settings',
      }),
      new SimpleAction({
        owner,
        id: 'search-conversations-and-tickets',
        icon: 'search',
        shortcutId: HotkeyID.Search,
      }),
      new InsertWhatsappTemplateAction({ owner }),
      new InsertWhatsappTemplateAction({ owner }),
      new EmojiAction({ owner }),
      new InsertTicketCard({ owner }),
      new CreateLinkedTicket({ owner }),
      new MergeInto({ owner, shortcutId: HotkeyID.MergeInto }),
      new ConvertToTicket({ owner, shortcutId: HotkeyID.ConvertToTicket }),
      new ConvertToCustomerTicket({ owner, shortcutId: HotkeyID.ConvertToTicket }),
      new SimpleAction({
        owner,
        id: 'create-side-conversation',
        icon: new NamedIcon('reference'),
      }),
      new CreateTaskTicket({ owner }),
      new SimpleAction({
        owner,
        id: 'close',
        icon: 'close-conversation',
        shortcutId: HotkeyID.Close,
      }),
      new SnoozeAction({ owner }),
      new SimpleAction({
        owner,
        id: 'upload-attachment',
        icon: 'attachment',
        shortcutId: HotkeyID.AddAttachment,
      }),
      new GifsAction({ owner }),
      new SimpleAction({
        owner,
        id: 'upload-image',
        icon: 'picture',
        shortcutId: HotkeyID.InsertImage,
      }),
      ...(this.productToursAppAction ? [this.productToursAppAction] : []),
      new AssignToAction({ owner }),
      new SimpleAction({
        owner,
        id: 'unmark-priority',
        icon: 'star-outlined',
        shortcutId: HotkeyID.ChangePriority,
      }),
      new SimpleAction({
        owner,
        id: 'mark-priority',
        icon: 'star',
        shortcutId: HotkeyID.ChangePriority,
      }),
      new SimpleAction({
        owner,
        id: 'bulk-unmark-priority',
        icon: 'star-outlined',
        shortcutId: HotkeyID.ChangePriority,
      }),
      new SimpleAction({
        owner,
        id: 'bulk-mark-priority',
        icon: 'star',
        shortcutId: HotkeyID.ChangePriority,
      }),
      new JumpToAction({ owner }),
      new SetLightThemeAction({ owner, id: 'set-light-theme' }),
      new SetDarkThemeAction({ owner, id: 'set-dark-theme' }),
      new SetSystemThemeAction({ owner, id: 'set-system-theme' }),
      new SwitchLanguageAction({ owner }),
      new BulkEditAction({ owner, id: 'bulk-edit', icon: 'edit' }),
      new SearchTicketAction({ owner }),
      new SimpleAction({
        owner,
        id: 'new-conversation',
        icon: 'compose',
        shortcutId: HotkeyID.NewConversation,
      }),
      new ChangeStateAction({ owner }),
      new ComposeNew({
        owner,
      }),
      new SimpleAction({
        owner,
        id: 'switch-to-table-view',
        icon: 'columns',
        shortcutId: HotkeyID.SwitchView,
      }),
      new SimpleAction({
        owner,
        id: 'switch-to-split-view',
        icon: 'split',
        shortcutId: HotkeyID.SwitchView,
      }),
      new InsertArticleAction({ owner }),
      new SimpleAction({
        owner,
        id: 'open',
        icon: 'reopen-conversation',
        shortcutId: HotkeyID.Reopen,
      }),
      new JumpToAppDetailsAction({ owner }),
      new TagFirstUserConversationPartAction({ owner }),
      new TagLastUserConversationPartAction({ owner }),
      new TagConversationPartAction({ owner }),
      new TagUser({ owner }),
      new TagLead({ owner }),
      new TagCompany({ owner }),
      new SimpleAction({ owner, id: 'manage-tags', icon: 'settings' }),
      new SimpleAction({ owner, id: 'manage-macros', icon: 'settings' }),
      new SimpleAction({ owner, id: 'create-macro', icon: 'add-macro' }),
      new SimpleAction({ owner, id: 'manage-workflow-connector-actions', icon: 'settings' }),
      new SimpleAction({ owner, id: 'reply-to-message', icon: new NamedIcon('reply') }),
      new SimpleAction({ owner, id: 'create-macro-from-part', icon: 'add-macro' }),
      new SimpleAction({ owner, id: 'forward-message', icon: new NamedIcon('forward') }),
      new InsertAttribute({ owner }),
      new SimpleAction({
        owner,
        id: 'keyboard-shortcuts',
        icon: 'keyboard',
        shortcutId: HotkeyID.KeyboardShortcuts,
      }),
      new SimpleAction({
        owner,
        id: 'create-salesforce-task',
        icon: new NamedIcon('salesforce'),
      }),
      new SimpleAction({
        owner,
        id: 'create-github-issue',
        icon: new NamedIcon('github'),
      }),
      new SimpleAction({
        owner,
        id: 'copy-conversation-part-link',
        icon: 'link',
      }),
      new SimpleAction({
        owner,
        id: 'delete-message',
        icon: 'delete',
      }),
      new SimpleAction({
        owner,
        id: 'manage-participants',
        icon: 'multiple-people',
      }),
      new TriggerWorkflowConnectorActionPart({ owner }),
      new SimpleAction({
        owner,
        id: 'unsupported-insert-ticket-card',
        icon: 'ticket',
      }),
      new SimpleAction({
        owner,
        id: 'achievements',
        icon: 'goals',
      }),
      new SimpleAction({
        owner,
        id: 'pull-conversation',
        icon: 'assignment',
      }),
      new SimpleAction({
        owner,
        id: 'inbox-diagnostics',
        icon: new NamedIcon('bar-charts'),
        secret: true,
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        label: 'Diagnostics',
      }),
      new UpdateTicketStateAction({ owner }),
      new ResolveAndCloseTicketStateAction({
        owner,
      }),
      new SimpleAction({
        owner,
        id: 'phone-call',
        icon: 'phone',
      }),
      new SimpleAction({
        owner,
        id: 'let-fin-respond',
        icon: 'fin',
      }),
      new SimpleAction({
        owner,
        id: 'manage-assignment-rules',
        icon: 'settings',
        hidden: !this.session.workspace.isFeatureEnabled('team-product-guidance-helpdesk-setup'),
      }),
    ].filter((action) =>
      this.session.showLightInbox ? this.authorisedForCollaborator.includes(action.id) : true,
    );

    return this._registeredActions;
  }

  @cached
  get messengerAppsActions() {
    if (this.session.showLightInbox) {
      return [];
    }

    let owner = getOwner(this);

    return [
      ...this.messengerApps.inserterApps.map((app) => {
        return new SimpleAction({
          owner,
          id: app.commandkId,
          icon: (app.iconUrl && { url: app.iconUrl, type: 'image' }) || undefined,
          label: this.intl.t('inbox.command-k.actions.use-app', {
            appName: app.name,
          }),
        });
      }),
    ];
  }

  @cached
  get productToursAppAction(): SimpleAction | undefined {
    if (this.session.showLightInbox) {
      return;
    }

    let owner = getOwner(this);

    let toursApp = this.messengerApps.toursApp;
    if (toursApp) {
      return new SimpleAction({
        owner,
        id: toursApp.commandkId,
        icon: new NamedIcon('product-tours-filled'),
        label: this.intl.t('inbox.command-k.actions.app-intercom-tours'),
      });
    }
    return;
  }

  get productToursExists(): boolean {
    return this.messengerApps.isToursLoaded && this.productToursAppAction !== undefined;
  }

  get productToursRegistered(): boolean {
    return this._registeredActions?.includes(this.productToursAppAction!) ?? false;
  }

  get activeActions(): Array<SimpleAction | ParentAction> {
    let activeActionIDs = Array.from(this.activeActionsMap.keys());

    let actions: Array<SimpleAction | ParentAction> = [];

    this.registeredActions.forEach((action) => {
      if (activeActionIDs.includes(action.id) || this.entrypoint?.actionID === action.id) {
        let registration = this.registrationForAction(action);
        if (!action.hidden && registration?.activatingComponent === this.currentActivationContext) {
          actions.push(action);
        }
      }
    });

    return actions;
  }

  registrationForAction(action: SimpleAction | ParentAction): ActionRegistration | undefined {
    if (this.entrypoint?.actionID === action.id) {
      return this.entrypoint;
    } else {
      return this.highestPriorityRegistrationForActionInTheContext(action.id);
    }
  }

  get isPinned() {
    return !isEmpty(this.pinnedToElement);
  }

  get trackingLocation() {
    let location = 'conversation_list';
    if (this.inboxState.activeConversationId) {
      location = 'composer';
    }
    if (this.inboxState.hasSelectedConversations) {
      location = 'bulk_action';
    }

    return location;
  }

  activate(registration: ActionRegistration) {
    let registrations: Array<ActionRegistration> =
      this.activeActionsMap.get(registration.actionID) || [];

    registrations.push(registration);
    registrations.sort((a, b) => (b.priority || 0) - (a.priority || 0));

    this.activeActionsMap.set(registration.actionID, registrations);
    this.activeActionsMap = this.activeActionsMap;
  }

  deactivate(registration: ActionRegistration) {
    let registrations: Array<ActionRegistration> =
      this.activeActionsMap.get(registration.actionID) || [];
    registrations = registrations.without(registration);

    if (registrations.length) {
      this.activeActionsMap.set(registration.actionID, registrations);
    } else {
      this.activeActionsMap.delete(registration.actionID);
    }
    this.activeActionsMap = this.activeActionsMap;
  }

  @action setActivationContext(context: DisplayContext) {
    if (!this.isVisible) {
      this.currentActivationContext = context;
    }
  }

  @action resetActivationContext() {
    // We call this explicitly here (instead of setActivationContext) so that we can reset the context when we close the modal
    this.currentActivationContext = DisplayContext.Global;
  }

  @action toggleVisibility(e: KeyboardEvent) {
    e.preventDefault();
    this.isVisible ? this.hide() : this.show();

    if (this.isVisible) {
      this.trackAnalyticsEvent({
        action: 'shown',
        section: 'composer',
        object: 'command_k',
        conversation_id: this.inboxState.activeConversationId,
      });
    }
  }

  @action hide(e?: Event, kev?: EmberKeyboardEvent) {
    e?.stopPropagation();
    kev?.stopPropagation();
    kev?.stopImmediatePropagation();

    this.isVisible = false;
    this.currentActivationShortcut = undefined;
    this.currentActivationSource = undefined;

    if (this.entrypoint?.onCancel) {
      this.entrypoint.onCancel();
    }

    if (this.entrypoint?.onClose) {
      this.entrypoint.onClose();
    }

    this.entrypoint = undefined;
    this.deselectAction();

    this.onHide && this.onHide();
    this.onHide = undefined;
  }

  @action handleShortcutFor(actionID: string, { shortcutUsed }: { shortcutUsed: string }) {
    this.deselectAction();
    this.currentActivationShortcut = shortcutUsed;

    let found = this.activeActions.find((action) => action.id === actionID);

    // Shortcuts (n, r) can trigger actions that need to be applied immediately
    // without making the cmd-k modal visible.
    if (found && !(found instanceof ParentAction)) {
      return this.triggerAction(found, { shortcutUsed });
    }

    // If the shortcut isn't applied immediately, show the cmd-k modal.
    this.showModal();

    if (found && found instanceof ParentAction) {
      this.selectAction(found, { shortcutUsed });
    }
  }

  @action show(onHide?: () => void) {
    this.calculateStyle();
    this.deselectAction();
    this.showModal();

    if (onHide && typeof onHide === 'function') {
      this.onHide = onHide;
    }
  }

  @action pinTo(element: HTMLElement) {
    this.pinnedToElement = element;
    this.calculateStyle();
  }

  @action unpin() {
    this.pinnedToElement = undefined;
    this.containerStyle = '';
  }

  @action trackAnalyticsEvent(meta: object) {
    let defaults = {
      section: `command_k_${this.current ? this.current.id : 'home'}`,
      object: this.current?.id,
      conversation_id: this.inboxState.activeConversationId,
      searched_from: this.trackingLocation,
      pane: this.current ? this.current.id : 'home',
    };

    let analyticsData = {
      ...defaults,
      ...meta,
    } as object;

    this.intercomEventService.trackAnalyticsEvent(analyticsData);
  }

  @action trackSearch({
    query = '',
    object = 'command_k',
    number_of_results = undefined,
    action = 'searched',
  }: {
    query?: string;
    object?: string;
    number_of_results?: number | undefined;
    action?: string;
  } = {}) {
    if (query.length) {
      this.trackAnalyticsEvent({
        action,
        object,
        query_keywords: query,
        number_of_results,
        invoked_key: this.currentActivationShortcut,
        shortcut_key: Boolean(this.currentActivationShortcut),
        source: this.currentActivationSource,
      });
    }
  }

  private calculateStyle() {
    if (!this.pinnedToElement) {
      return;
    }
    let dimensions = this.pinnedToElement.getBoundingClientRect();
    let offsetContainerPadding = (window.innerHeight * 30) / 100; // padding-top on container is 30vh

    let top = `${dimensions.top + dimensions.height + 8 - offsetContainerPadding}px`;
    let left = `${dimensions.left}px`;

    this.containerStyle = sanitizeHtml(`
      top: ${top};
      bottom: unset;
      left: ${left};
    `);
  }

  @action registerAndShow(
    registration: ActionRegistration,
    opts: { source?: string; shortcutUsed?: string } = {},
  ) {
    this.deselectAction();
    this.entrypoint = registration;
    this.currentActivationShortcut = opts.shortcutUsed;
    this.currentActivationSource = opts.source;

    this.showModal();

    let action = this.registeredActions.find((a) => a.id === registration.actionID);
    if (action && action instanceof ParentAction) {
      this.selectAction(action, opts);
    }
  }

  @action findAndShow(actionID: string, onFinish: () => void, opts: { source?: string } = {}) {
    let action = this.activeActions.find((a) => a.id === actionID);
    if (!action) {
      throw new Error(
        `Action must be registered before you can invoke, ${actionID} could not be found`,
      );
    }

    if (action instanceof ParentAction) {
      this.show(onFinish);
      this.selectAction(action, { source: opts.source });
    } else if (action instanceof SimpleAction) {
      this.triggerAction(action);
      onFinish();
    }
  }

  @action triggerAction(selected: Action, metadata: CommandKMetadata = {}, trackingData?: object) {
    if (selected.isPaywalled) {
      selected.paywall!.openUpgradeModal();
      this.hide();
    } else if (selected instanceof ParentAction) {
      this.selectAction(selected, metadata);
    } else {
      let hideOnSelect: boolean;
      if (selected instanceof ChildAction) {
        if (selected.beforeOnSelect) {
          selected.beforeOnSelect(selected);
        }

        if (trackingData && selected.trackingData) {
          trackingData = { ...selected.trackingData, ...trackingData };
        } else {
          trackingData = trackingData || selected.trackingData;
        }

        this.triggerActionHandler(selected.parent.id, selected.value, metadata, trackingData);

        if (this.inboxVersion.isInbox2 && selected.parent.cacheLatest === true) {
          this.persistLastAction(selected.parent.id, selected.id);
        }

        // Sometimes the entrypoint might have changed because the action has opened another pane, so we should keep it open
        hideOnSelect =
          selected.parent.hideOnSelect &&
          (!this.entrypoint?.hasOwnProperty('actionID') ||
            selected.parent.id === this.entrypoint?.actionID);

        // onCancel is called if the cmd-k modal is closed without any
        // selection. since there has been a selection now, we can clear
        // onCancel to make sure it isn't called.
        if (
          selected.parent.id === this.entrypoint?.actionID &&
          this.entrypoint.hasOwnProperty('onCancel')
        ) {
          this.entrypoint.onCancel = undefined;
        }
      } else {
        this.triggerActionHandler(selected.id, undefined, metadata);

        // Sometimes the entrypoint might have changed because the action has opened another pane, so we should keep it open
        hideOnSelect =
          selected.hideOnSelect &&
          (!this.entrypoint?.hasOwnProperty('actionID') ||
            selected.id === this.entrypoint?.actionID);
      }

      if (hideOnSelect) {
        this.hide();
      }
    }
  }

  @action selectAction(
    selected: ParentAction,
    {
      source = undefined,
      shortcutUsed = undefined,
    }: { source?: string; shortcutUsed?: string } = {},
  ) {
    this.current = selected;
    this.entrypoint = this.registrationForAction(selected);

    this.trackAnalyticsEvent({
      action: 'shown',
      object: 'command_k',
      shortcut_key: Boolean(shortcutUsed),
      invoked_action: selected.id,
      invoked_key: shortcutUsed,
      source,
    });
  }

  @action deselectAction() {
    this.current = null;
    this.currentActivationShortcut = undefined;
    this.currentActivationSource = undefined;
  }

  // registrations are sorted with highest priority first
  // we filter by current context and return the action with highest priority for that context
  private highestPriorityRegistrationForActionInTheContext(
    actionID: string,
  ): ActionRegistration | undefined {
    let registrations = this.activeActionsMap.get(actionID) || [];
    return registrations.filter((r) => r.activatingComponent === this.currentActivationContext)[0];
  }

  private triggerActionHandler(
    actionID: string,
    value: any,
    metadata: CommandKMetadata,
    trackingData?: any,
  ) {
    let registration =
      this.entrypoint ?? this.highestPriorityRegistrationForActionInTheContext(actionID);
    if (registration) {
      // If there's an entrypoint, that's the only action we care about, so we
      // do not need to check for duplicates.
      if (!this.entrypoint) {
        this.ensureNoDuplicateRegistrations(registration);
      }

      registration.onSelect(value, registration.context, metadata, trackingData);
      this.maybeTrackAction(trackingData, registration, value);
    }
  }

  private maybeTrackAction(actionTrackingData: any, registration: ActionRegistration, value: any) {
    if (!actionTrackingData && !registration.trackingData) {
      return;
    }
    let data: { [key: string]: any } = {
      section: 'command_k',
      action: 'selected',
      shortcut_key: Boolean(this.currentActivationShortcut),
      invoked_key: this.currentActivationShortcut,
      executed_from: this.trackingLocation,
      conversation_id: this.inboxState.activeConversationId,
      ...(actionTrackingData || {}),
      ...(registration.trackingData || {}),
    };

    // Allows us to add tracking data based on the `value` arg passed
    // into the onSelect param
    if (registration.trackingDataFromSelectValue && value) {
      data = {
        ...data,
        ...registration.trackingDataFromSelectValue(value),
      };
    }

    this.trackAnalyticsEvent(data);
  }

  // we used to validate on activation, but component destruction is async and there's no guarantee
  // that the previous action has been deactivated yet: https://github.com/emberjs/ember.js/issues/18873#issuecomment-703790382
  // instead we ensure there's no duplicates when triggering.
  private ensureNoDuplicateRegistrations(registration: ActionRegistration): void {
    let registrations = this.activeActionsMap.get(registration.actionID) || [];
    let existing = registrations.find(
      (r) =>
        (r.priority || 0) === (registration.priority || 0) &&
        r !== registration &&
        r.activatingComponent === registration.activatingComponent,
    );

    assert(
      `Duplicate command K action registration for ${registration.actionID} with priority ${registration.priority}`,
      !existing,
    );
  }

  private storageKey(id: string) {
    return `command-k-latest-${id}-${this.session.workspace.id}`;
  }

  private persistLastAction(parentId: string, childId: string) {
    storage.set(this.storageKey(parentId), childId);
  }

  fetchLastAction(id: string): string | null {
    return storage.get(this.storageKey(id));
  }

  private showModal() {
    this.isVisible = true;
    this.resetActionsState();
  }

  private resetActionsState() {
    this.activeActions.forEach((action) => action.resetState());
  }
}

declare module '@ember/service' {
  interface Registry {
    commandKService: CommandKService;
    'command-k': CommandKService;
  }
}
