import Handlebars from 'handlebars';
      const _template = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class='font-bold'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"percentageValue") : depth0), depth0))
    + "\n      <span class='w-4 text-sm inline-block'>\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"emoji") : depth0), depth0))
    + "\n      </span>\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"currentValue") : depth0), depth0))
    + "\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class='font-bold'>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"currentValue") : depth0), depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"signalHasIncreased") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":95,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"signalHasImproved") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span\n            class='reporting__summary__change o__small-arrow o__increased o__improved'\n            data-test-csf-comparison-tooltip-arrow\n          >\n            <span class='reporting__summary__change-icon'>\n              <svg\n                class='interface-icon o__standard o__standard__small-up-arrow'\n                width='16'\n                height='16'\n                xmlns='http://www.w3.org/2000/svg'\n              >\n                <path\n                  d='M4.302 9.02a.6.6 0 00.464.98h6.468a.6.6 0 00.464-.98L8.774 5.446a1 1 0 00-1.548 0L4.302 9.02z'\n                ></path>\n              </svg>\n            </span>\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"deltaText") : depth0), depth0))
    + "\n          </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span\n            class='reporting__summary__change o__small-arrow o__increased o__worsened'\n            data-test-csf-comparison-tooltip-arrow\n          >\n            <span class='reporting__summary__change-icon'>\n              <svg\n                class='interface-icon o__standard o__standard__small-up-arrow'\n                width='16'\n                height='16'\n                xmlns='http://www.w3.org/2000/svg'\n              >\n                <path\n                  d='M4.302 9.02a.6.6 0 00.464.98h6.468a.6.6 0 00.464-.98L8.774 5.446a1 1 0 00-1.548 0L4.302 9.02z'\n                ></path>\n              </svg>\n            </span>\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"deltaText") : depth0), depth0))
    + "\n          </span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"signalHasImproved") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":94,"column":15}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span\n            class='reporting__summary__change o__small-arrow o__decreased o__improved'\n            data-test-csf-comparison-tooltip-arrow\n          >\n            <span class='reporting__summary__change-icon'>\n              <svg\n                class='interface-icon o__standard o__standard__small-down-arrow'\n                width='16'\n                height='16'\n                xmlns='http://www.w3.org/2000/svg'\n              >\n                <path\n                  d='M4.302 6.98A.6.6 0 014.766 6h6.468a.6.6 0 01.464.98l-2.924 3.574a1 1 0 01-1.548 0L4.302 6.98z'\n                ></path>\n              </svg>\n            </span>\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"deltaText") : depth0), depth0))
    + "\n          </span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span\n            class='reporting__summary__change o__small-arrow o__decreased o__worsened'\n            data-test-csf-comparison-tooltip-arrow\n          >\n            <span class='reporting__summary__change-icon'>\n              <svg\n                class='interface-icon o__standard o__standard__small-down-arrow'\n                width='16'\n                height='16'\n                xmlns='http://www.w3.org/2000/svg'\n              >\n                <path\n                  d='M4.302 6.98A.6.6 0 014.766 6h6.468a.6.6 0 01.464.98l-2.924 3.574a1 1 0 01-1.548 0L4.302 6.98z'\n                ></path>\n              </svg>\n            </span>\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"deltaText") : depth0), depth0))
    + "\n          </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "      <span class='reporting__summary-comparison__not-changed text-muted'>\n        -\n      </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-test-csf-comparison-tooltip class='reporting__highcharts-tooltip'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emoji") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + "  "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "<br />\n  <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"delta") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":100,"column":11}}})) != null ? stack1 : "")
    + "    <span class='text-muted'><span class='font-bold'>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"previousValue") : depth0), depth0))
    + "</span>\n      "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"rangeText") : depth0), depth0))
    + "</span>\n  </div>\n</div>";
},"useData":true});
      export default _template;